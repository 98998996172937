import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NotificationRequestModel} from '../models/notification-request.model';
import {NotificationResponseModel} from '../models/notification-response.model';
import {Observable, audit} from 'rxjs';
import {environment} from '../../environments/environment';
import * as moment from 'moment';
import {NotificationHistoryRequestModel} from '../models/notification-history-request.model';
import {DatePipe} from '@angular/common';
import {AuditContactModel} from '../audit-request/audit-contact/audit-contact.model';
import { AuditDetail } from '../models/audit-detail-model';
import { AuditInformation } from '../audit-request/audit-information/audit-information.model';
import { AuditRoleResponsibility } from '../audit-request/audit-role/audit-role.model';
import { TIMELINE_TITLE } from '../enums/timeline-title-enum';
import { TimeLine } from '../models/time-line.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private httpClient: HttpClient,
              private datePipe: DatePipe) { }

  /**
   * Takes the notification request and token from the session to send a post request
   * to the Notification API.
   *
   * @param notificationRequest - notification request object to send email notifications
   */
  sendNotification(notificationRequest: NotificationRequestModel): Observable<NotificationResponseModel> {
    const idToken = sessionStorage.getItem('id_token');
    const headers = new HttpHeaders({'Content-Type': 'application/json', authorization: 'Bearer ' + idToken});
    return this.httpClient.post<NotificationResponseModel>(`${environment.backendUrl}/notification/send`, notificationRequest,{headers});
  }

  /**
   * Takes a notification history request to send a post request for the audit_notification_history table.
   *
   * @param notificationHistoryRequest - notification history request object to save in our table
   */
  saveNotificationHistory(notificationHistoryRequest: NotificationHistoryRequestModel): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.httpClient.post<any>(
      `${environment.backendUrl}/notification/addHistory`, notificationHistoryRequest, {headers});
  }

  /**
   * Creates a notification request object for the Notification API.
   *
   * @param headerText - string for header text (email subject)
   * @param emailBody - HTML text on what we are sending in the email
   * @param addresses - Array of strings of users we send this email to
   */
  createNotificationRequest(headerText: string, emailBody: string, addresses: string[]): NotificationRequestModel {
    const request: NotificationRequestModel = {
      header: headerText,
      body: emailBody,
      emailAddresses: addresses,
      source: 'AMS',
      notificationCreationTs: new Date(),
      relatedObject: {
        resource: '',
        resourceType: ''
      }
    };
    return request;
  }

  /**
   * Function to create the email body for audit analyst assignment notification.
   *
   * @param auditDetails - audit detail from dashboardService.getAuditDetailsByRecordId
   * @param auditContacts - audit contacts detail from contactService.getAuditContactDetail
   * @param auditTimelines - audit timelines detail from timelineService.getAll
   */
  createEmailBodyForAuditAnalystAssignment(auditDetails: any, auditContacts: any, auditTimelines: any): string {
    const auditInfo = auditDetails.auditInformation;
    let emailBody = '<h1 style=\"text-align: center; line-height: 36.4px;\">An Audit has been assigned to you in Audit Management</h1>';
    emailBody += 'Dear ' + auditInfo.assignedMemberName + ',<br><br>';
    emailBody += this.createEmailBodyParagraph('assignAnalyst');

    const dashboardLink = 'href = \"' + environment.dashboardUrl + '\"';
    // TODO use if we figure out redirects
    // const dashboardLink = 'href = \"' + environment.dashboardUrl + '#/internal/audit-review/' + auditDetails.recordId + '\"';
    emailBody += '<strong>Audit #:</strong> <a ' + dashboardLink + '>' + auditDetails.recordId + '</a><br><br>';
    const primaryAuditContact = auditContacts.primaryAuditContact.filter(contact => contact.isPrimaryContact === true)[0];
    const primaryContactName = primaryAuditContact.firstName + ' ' + primaryAuditContact.lastName;
    const kickoffDate = auditTimelines[0].notApplicable ? 'N/A' : auditTimelines[0].date;
    const clientCoalitionName = (auditInfo.clientName !== null && auditInfo.clientName.length > 0) ?
      auditInfo.clientName : auditInfo.originalClientName;
    emailBody += this.setEmailBodyGeneralInfo(auditInfo, primaryContactName, clientCoalitionName, kickoffDate);

    emailBody += this.getSignInButton();
    return emailBody;
  }

  /**
   * Function to create the email body for audit analyst when audit is resubmitted notification.
   *
   * @param auditForm - full audit form from the audit request
   * @param auditDetails - audit detail from dashboardService.getAuditDetailsByRecordId
   * @param auditNotes - audit notes detail from notesManagementService.getDetailNotes
   */
  createEmailBodyForResubmittedAudit(auditForm: any, auditDetails: any, auditNotes: any): string {
    const auditInfo = auditDetails.auditInformation;
    let emailBody = '<h1 style=\"text-align: center; line-height: 36.4px;\">Returned Audit has been resubmitted</h1>';
    emailBody += 'Dear ' + auditInfo.assignedMemberName + ',<br><br>';
    emailBody += this.createEmailBodyParagraph('resubmittedAudit');
    const dashboardLink = 'href = \"' + environment.dashboardUrl + '\"';
    // TODO use if we figure out redirects
    // const dashboardLink = 'href = \"' + environment.dashboardUrl + '#/internal/audit-review/' + auditDetails.recordId + '\"';
    emailBody += '<strong>Audit #:</strong> <a ' + dashboardLink + '>' + auditDetails.recordId + '</a><br><br>';
    emailBody += '<strong>Submitted Date:</strong> ' + moment(new Date(), 'America/Toronto').format('MM/DD/YYYY') + '<br><br>';

    const primaryAuditContact = auditForm.auditContactForm.primaryAuditContact.filter(contact => contact.isPrimaryContact === true)[0];
    const primaryContactName = primaryAuditContact.firstName + ' ' + primaryAuditContact.lastName;
    const kickoffDate = auditForm.auditTimelineForm.tableRows[0].notApplicable ?
      'N/A' :
      this.datePipe.transform(auditForm.auditTimelineForm.tableRows[0].date, 'MM/dd/YYYY');
    const clientCoalitionName = (auditInfo.clientName !== null && auditInfo.clientName.length > 0) ?
      auditInfo.clientName : auditInfo.originalClientName;
    emailBody += this.setEmailBodyGeneralInfo(auditInfo, primaryContactName, clientCoalitionName, kickoffDate);

    const returnNotes = auditNotes.filter(note => note.keyAction === 'Return');
    emailBody += '<strong>Returned Audit Notes:</strong> ' + returnNotes.at(returnNotes.length - 1).notes + '<br><br>';
    emailBody += this.getSignInButton();
    return emailBody;
  }

  /**
   * Function to create the email body for notification when audit is cancelled.
   *
   * @param auditForm - full audit form from the audit request
   * @param auditDetails - audit detail from dashboardService.getAuditDetailsByRecordId
   * @param notes - cancellation notes added by user
   */
  createEmailBodyForCancelledAudit(auditForm: any, auditDetails: any, notes: string): string {
    const auditInfo = auditDetails.auditInformation;
    let emailBody = '<h1 style=\"text-align: center; line-height: 36.4px;\">' +
      'CVS Audit ' + auditDetails.recordId + ' has been cancelled in the Audit Management System</h1>';
    emailBody += this.createEmailBodyParagraph('cancelledAudit');
    const dashboardLink = 'href = \"' + environment.dashboardUrl + '\"';
    // TODO use if we figure out redirects
    // const dashboardLink = 'href = \"' + environment.dashboardUrl + '#/internal/audit-review/' + auditDetails.recordId + '\"';
    emailBody += '<strong>Audit #:</strong> <a ' + dashboardLink + '>' + auditDetails.recordId + '</a><br><br>';

    const primaryAuditContact = auditForm.auditContactForm.primaryAuditContact.filter(contact => contact.isPrimaryContact === true)[0];
    const primaryContactName = primaryAuditContact.firstName + ' ' + primaryAuditContact.lastName;
    const kickoffDate = auditForm.auditTimelineForm.tableRows[0].notApplicable ?
      'N/A' :
      this.datePipe.transform(auditForm.auditTimelineForm.tableRows[0].date, 'MM/dd/YYYY');
    const clientCoalitionName = (auditInfo.clientName !== null && auditInfo.clientName.length > 0) ?
      auditInfo.clientName : auditInfo.originalClientName;
    emailBody += this.setEmailBodyGeneralInfo(auditInfo, primaryContactName, clientCoalitionName, kickoffDate);

    emailBody += '<strong>Reason for Cancellation:</strong> ' + notes + '<br><br>';
    emailBody += (auditInfo.assignedAnalystEmail !== null ?
      '<strong>CVS Audit Analyst:</strong> ' + auditInfo.assignedAnalystEmail + '<br><br>' : '');
    emailBody += this.getSignInButton();
    return emailBody;
  }

  /**
   * Function to create the email body for notification when audit is returned.
   *
   * @param auditForm - full audit form from the audit request
   * @param auditDetails - audit detail from dashboardService.getAuditDetailsByRecordId
   * @param notes - returned notes added by user
   */
  createEmailBodyForReturnAudit(auditForm: any, auditDetails: any, notes: string): string {
    const auditInfo = auditDetails.auditInformation;
    let emailBody = '<h1 style=\"text-align: center; line-height: 36.4px;\">' +
      'CVS Audit ' + auditDetails.recordId + ' has been returned in the CVS Audit Management System</h1>';
    emailBody += this.createEmailBodyParagraph('returnedAudit');
    const dashboardLink = 'href = \"' + environment.dashboardUrl + '\"';
    // TODO use if we figure out redirects
    // const dashboardLink = 'href = \"' + environment.dashboardUrl + '#/internal/audit-review/' + auditDetails.recordId + '\"';
    emailBody += '<strong>Audit #:</strong> <a ' + dashboardLink + '>' + auditDetails.recordId + '</a><br><br>';

    const primaryAuditContact = auditForm.auditContactForm.primaryAuditContact.filter(contact => contact.isPrimaryContact === true)[0];
    const primaryContactName = primaryAuditContact.firstName + ' ' + primaryAuditContact.lastName;
    const kickoffDate = auditForm.auditTimelineForm.tableRows[0].notApplicable ?
      'N/A' :
      this.datePipe.transform(auditForm.auditTimelineForm.tableRows[0].date, 'MM/dd/YYYY');
    const clientCoalitionName = auditInfo.originalClientName;
    emailBody += this.setEmailBodyGeneralInfo(auditInfo, primaryContactName, clientCoalitionName, kickoffDate);

    emailBody += '<strong>Reason for Return:</strong> ' + notes + '<br><br>';
    emailBody += (auditInfo.assignedAnalystEmail !== null ?
      '<strong>CVS Audit Analyst:</strong> ' + auditInfo.assignedAnalystEmail + '<br><br>' : '');
    emailBody += this.getSignInButton();
    return emailBody;
  }

  /**
   * Function to create the email body for notification when audit is approved.
   *
   * @param auditForm - full audit form from the audit request
   * @param auditDetails - audit detail from dashboardService.getAuditDetailsByRecordId
   * @param approveNotes - approved notes added by user
   */
  createEmailBodyForApprovedAudit(auditForm: any, auditDetails: any, approveNotes: string): string {
    const auditInfo = auditDetails.auditInformation;
    let emailBody = '<h1 style=\"text-align: center; line-height: 36.4px;\">' +
      'CVS Audit ' + auditDetails.recordId + ' has been approved in the Audit Management System</h1>';
    emailBody += this.createEmailBodyParagraph('approvedAudit', auditDetails.recordId);
    emailBody += (this.needsNdaVerification(
      auditForm.startUpProcessForm.firmsVerificationNDA,
      auditDetails.auditInformation.auditInitiationType === 'Firm')?
      'File sharing will be delayed until NDAs across all parties to this audit are fully executed.<br><br>'
      : '');
    const dashboardLink = 'href = \"' + environment.dashboardUrl + '\"';
    // TODO use if we figure out redirects
    // const dashboardLink = 'href = \"' + environment.dashboardUrl + '#/internal/audit-review/' + auditDetails.recordId + '\"';
    emailBody += '<strong>Audit #:</strong> <a ' + dashboardLink + '>' + auditDetails.recordId + '</a><br><br>';

    const primaryAuditContact = auditForm.auditContactForm.primaryAuditContact.filter(contact => contact.isPrimaryContact === true)[0];
    const primaryContactName = primaryAuditContact.firstName + ' ' + primaryAuditContact.lastName;
    const kickoffDate = auditForm.auditTimelineForm.tableRows[0].notApplicable ?
      'N/A' :
      this.datePipe.transform(auditForm.auditTimelineForm.tableRows[0].date, 'MM/dd/YYYY');
    const clientCoalitionName = auditInfo.originalClientName;
    emailBody += this.setEmailBodyGeneralInfo(auditInfo, primaryContactName, clientCoalitionName, kickoffDate);

    emailBody += (approveNotes.length > 0 ?
      '<strong>Approval Notes:</strong> ' + approveNotes + '<br><br>' : '');
    emailBody += (auditInfo.assignedAnalystEmail !== null ?
      '<strong>CVS Audit Analyst:</strong> ' + auditInfo.assignedAnalystEmail + '<br><br>' : '');
    emailBody += this.getSignInButton();
    return emailBody;
  }

  createEmailBodyForSubmitAudit(auditDetails: any, auditRoles: AuditRoleResponsibility, timelineList: TimeLine[]){
    const auditInfo = auditDetails?.auditInformation;
    let emailBody = '<h1 style=\"text-align: center; line-height: 36.4px;\">' +
      'CVS Audit ' + auditDetails.recordId + ' has been submitted in the Audit Management System</h1>';
    emailBody += this.createEmailBodyParagraph('submittedAudit', auditDetails.recordId);
    const dashboardLink = 'href = \"' + environment.dashboardUrl + '\"';
    emailBody += '<strong>Audit #:</strong> <a ' + dashboardLink + '>' + auditDetails.recordId + '</a><br><br>';
    emailBody += '<strong>Audit Request Client/Coalition Name: </strong>' +
      auditInfo?.originalClientName + '<br><br>';
    emailBody += '<strong>Scope Start Date:</strong> ' + auditDetails?.auditInformation?.auditScopeStartDate  + ' ';
    emailBody += '<strong>Scope Stop Date:</strong> ' + auditDetails?.auditInformation?.auditScopeEndDate + '<br><br>';
    emailBody += '<strong>Line of Business 1: </strong>' + auditInfo?.auditLobOneName + '<br><br>';
    if(auditInfo?.auditLobTwoNames){
      emailBody += '<strong>Line of Business 2: </strong>' + auditInfo?.auditLobTwoNames?.join(', ') + '<br><br>';
    }
    emailBody += '<strong>Audit Types:</strong> Claims - ' + auditInfo?.auditOfferings?.join(', ') + '<br><br>';
    emailBody += '<strong>Requester Email:</strong> ' + auditInfo.createdById + '<br><br>';
    emailBody += '<strong>Primary Contact Name: </strong>' +
      auditInfo.assignedMemberAuditor + '<br><br>';
    emailBody += auditInfo?.auditInitiationType === 'Firm' ?
      '<strong>Primary Audit Firm:</strong> ' + auditInfo?.firmName + '<br><br>' : '';
    emailBody += '<strong>Client/Firm Contacts: </strong>' +
      ((auditInfo?.auditInitiationType === 'Client') ?
        auditInfo?.auditClientContactList : auditInfo?.auditFirmContactList).join(', ') +
      '<br><br>';
    if(auditRoles?.primaryAuditRole?.infoRequestName?.length > 0) {
      emailBody += '<strong>Audit Information Requested: </strong>' + auditRoles?.primaryAuditRole?.infoRequestName.join(', ') + '<br><br>';
    }
    if(auditInfo?.subcontractorOneContactList?.length > 0) {
      emailBody += '<hr>';
      emailBody += '<strong>Subcontracting Firm: </strong>' + auditRoles?.subcontractorOneAuditRole?.firmName + '<br><br>';
      emailBody += '<strong>' + auditRoles?.subcontractorOneAuditRole?.firmName +' Contacts: </strong>' +
        auditInfo?.subcontractorOneContactList?.join(', ') + '<br><br>';
        if(auditRoles?.subcontractorOneAuditRole?.infoRequestName?.length > 0){
          emailBody += '<strong>Audit Information Requested: </strong>' +
            auditRoles?.subcontractorOneAuditRole?.infoRequestName?.join(', ') + '<br><br>';
        }
    }
    if(auditInfo?.subcontractorTwoContactList?.length > 0) {
      emailBody += '<hr>';
      emailBody += '<strong>Subcontracting Firm: </strong>' + auditRoles?.subcontractorTwoAuditRole?.firmName + '<br><br>';
      emailBody += '<strong>' + auditRoles?.subcontractorTwoAuditRole?.firmName +' Contacts: </strong>' +
      auditInfo?.subcontractorTwoContactList?.join(', ') + '<br><br>';
      if(auditRoles?.subcontractorTwoAuditRole?.infoRequestName?.length > 0){
        emailBody += '<strong>Audit Information Requested: </strong>' +
          auditRoles?.subcontractorTwoAuditRole?.infoRequestName?.join(', ') + '<br><br>';
      }
    }

    const isPricing = auditInfo?.auditOfferings?.includes('Pricing');
    emailBody += this.createEmailTemplateForTimeLine(timelineList, isPricing);
    emailBody += this.getSignInButton();
    return emailBody;
  }

  /**
   * Function to easily add projected timeline to the email body.
   *
   * @param timelineList - Saved timeline list from projected timeline
   * @param isPricing - Audit type includes Pricing
   */
  createEmailTemplateForTimeLine(timelineList: TimeLine[], isPricing: boolean): string {
    let emailBody = '<hr><br>';
    emailBody += '<strong>Projected Timeline</strong><br><br>';
    emailBody += this.createEmailBodyParagraph('projectedTimeline');

    emailBody += '<strong>External Kickoff Date: </strong>' +
      this.getProjectedTimelineDate(timelineList, TIMELINE_TITLE.EXTERNAL_KICK_OFF_DATE) + '<br><br>';
    emailBody += '<strong>Initial Deliverable Due Date: </strong>' +
      this.getProjectedTimelineDate(timelineList, TIMELINE_TITLE.INITIAL_DELIVERABLE_DUE_DATE) + '<br><br>';
    if(isPricing) {
      emailBody += '<strong>GER Reporting Due Date: </strong>' +
        this.getProjectedTimelineDate(timelineList, TIMELINE_TITLE.GER_REPORTING_DUE_DATE) + '<br><br>';
    }
    emailBody += '<strong>Follow Up Questions Due Date: </strong>' +
      this.getProjectedTimelineDate(timelineList, TIMELINE_TITLE.FU_QUESTIONS_DUE_DATE) + '<br><br>';
    emailBody += '<strong>Follow Up Questions Response Due Date: </strong>' +
      this.getProjectedTimelineDate(timelineList, TIMELINE_TITLE.FU_QUESTIONS_RESPONSE_DUE_DATE) + '<br><br>';
    emailBody += '<strong>Onsite Start Date: </strong>' +
      this.getProjectedTimelineDate(timelineList, TIMELINE_TITLE.ONSITE_START_DATE) + '<br><br>';
    emailBody += '<strong>Sample Received Due Date: </strong>' +
      this.getProjectedTimelineDate(timelineList, TIMELINE_TITLE.SAMPLE_RECEIVED_DUE_DATE) + '<br><br>';
    emailBody += '<strong>Sample Response Due Date: </strong>' +
      this.getProjectedTimelineDate(timelineList, TIMELINE_TITLE.SAMPLE_RESPONSE_DUE_DATE) + '<br><br>';
    emailBody += '<strong>All Sample Follow Up Resolved Due Date: </strong>' +
      this.getProjectedTimelineDate(timelineList, TIMELINE_TITLE.ALL_SAMPLE_FU_RESOLVED_DUE_DATE) + '<br><br>';
    emailBody += '<strong>Draft Report Received Due Date: </strong>' +
      this.getProjectedTimelineDate(timelineList, TIMELINE_TITLE.DRAFT_REPORT_RECEIVED_DUE_DATE) + '<br><br>';
    emailBody += '<strong>Draft Report Response Due Date: </strong>' +
      this.getProjectedTimelineDate(timelineList, TIMELINE_TITLE.DRAFT_REPORT_RESPONSE_DUE_DATE) + '<br><br>';
    emailBody += '<strong>Final Report Received Due Date: </strong>' +
      this.getProjectedTimelineDate(timelineList, TIMELINE_TITLE.FINAL_REPORT_RECEIVED_DUE_DATE) + '<br><br>';
    emailBody += '<strong>Expected Audit Completion Date: </strong>' +
      this.getProjectedTimelineDate(timelineList, TIMELINE_TITLE.EXPECTED_AUDIT_COMPLETION_DATE) + '<br><br>';

    return emailBody;
  }

  getProjectedTimelineDate(timelineList: TimeLine[], timelineTitle: string) {
    const date = timelineList.find(list => list.title === timelineTitle)?.date;
    return date ? date : 'N/A';
  }


  /**
   * Function to read through NDA Details to determine if audit needs NDA Verification.
   * Note: Checkbox can be null if it is a new save on startup, then into an approve audit.
   *
   * @param ndaDetails - startUpProcessForm.firmsVerificationNda form (Start-up Process page)
   * @param isFirm - boolean flag to determine if the audit is created by a firm or client
   */
  needsNdaVerification(ndaDetails: any, isFirm: boolean): boolean {
    if (isFirm) {
      if (ndaDetails['Firm Auditor'] === '2' && !ndaDetails['Firm Auditor checkbox']) {
        return true;
      }
    } else {
      if (ndaDetails['Client Auditor'] === '2' && !ndaDetails['Client Auditor checkbox']) {
        return true;
      }
    }
    if (ndaDetails['Subcontractor 1'] !== undefined) {
      if (ndaDetails['Subcontractor 1'] === '2' && !ndaDetails['Subcontractor 1 checkbox']) {
        return true;
      }
    }
    if (ndaDetails['Subcontractor 2'] !== undefined) {
      if (ndaDetails['Subcontractor 2'] === '2' && !ndaDetails['Subcontractor 2 checkbox']) {
        return true;
      }
    }
    return false;
  }

  /**
   * Function to easily add a necessary email body paragraph.
   *
   * @param type - string used to differentiate the paragraph used
   */
  createEmailBodyParagraph(type: string, auditNumber?: string): string {
    switch(type) {
      case 'assignAnalyst':
        return 'An Audit has been assigned to you in Audit Management.  ' +
          'Please use the provided link to access the corresponding audit.<br><br>';
        break;
      case 'resubmittedAudit':
        return 'An audit that has been returned and resubmitted. Please ' +
          'use the provided link to access the corresponding audit.<br><br>';
        break;
      case 'cancelledAudit':
        return 'An Audit associated to you has been cancelled in the CVS Audit Management System.  ' +
          'Please use the provided link to access the corresponding audit for additional details.<br><br>';
        break;
      case 'returnedAudit' :
        return 'An Audit you requested or you are identified as a primary contact has been returned in the Audit Management System. ' +
        'Please use the provided link to access the corresponding audit and make the necessary corrections.<br><br>';
        break;
      case 'approvedAudit':
        return 'Audit ' + auditNumber + ' has been approved within the CVS Audit Management System. Our Client ' +
          'Audit Team will begin to gather your initial deliverables. You will be notified via email when ' +
          'files are made available to you within the Audit Management System. Use the "more menu" represented ' +
          'as three dots in the dashboard, to navigate to the actual timeline dates or document management where ' +
          'all of the files will be shared with CVS.<br><br>';
        break;
      case 'fileUploadedByAuditor':
        return 'A document has been uploaded for an Audit assigned to you in Audit Management. ' +
          'Please use the provided link to access the corresponding audit.<br><br>';
        break;
      case 'submittedAudit':
        return 'Audit ' + auditNumber + ' has been submitted in the CVS Audit Management System. ' +
        'A Client Audit Team member will be assigned to your audit shortly.'
        +'</br></br> A summary of the Audit Request you submitted is included below.  </br></br>';
        break;
      case 'projectedTimeline':
        return 'Dates are subject to change based on the complexity of the scope letter, ' +
          'receipt of documentation, and deliverables related to the audit. Dates could be ' +
          'adjusted during the kickoff discussion with the CVS team. <br><br>';
        break;
      default:
        return '';
    }
  }

  /**
   * Function to easily add commonly needed information to the email body.
   *
   * @param auditInfo - Object containing a majority of the audit info
   * @param primaryContact - String of primary contact's full name (first + last)
   * @param clientCoalitionName - String of client or coalition name from audit request
   * @param kickoffDate - String of Timeline External Kickoff Date (MM/DD/YYYY)
   */
  setEmailBodyGeneralInfo(auditInfo: any, primaryContact: string, clientCoalitionName: string, kickoffDate: string): string {
    let emailBody = '';
    emailBody += '<strong>Requester Email:</strong> ' + auditInfo.createdById + '<br><br>';
    emailBody += '<strong>Primary Contact Name:</strong> ' + primaryContact + '<br><br>';
    emailBody += auditInfo.auditInitiationType === 'Firm' ?
      '<strong>Primary Audit Firm:</strong> ' + auditInfo.auditFirmName + '<br><br>' : '';
    emailBody += '<strong>Audit Request Client/Coalition Name:</strong> ' + clientCoalitionName + '<br><br>';
    emailBody += '<strong>Scope Start Date:</strong> ' + auditInfo.auditScopeStartDate  + ' ';
    emailBody += '<strong>Scope Stop Date:</strong> ' + auditInfo.auditScopeEndDate + '<br><br>';
    emailBody += '<strong>Projected Kickoff Date:</strong> ' + kickoffDate + '<br><br>';
    emailBody += '<strong>Audit Types:</strong> Claims - ' + auditInfo.auditOfferings?.join(', ') + '<br><br>';
    return emailBody;
  }

  /**
   * Function to return a PDS-styled Sign-In button that brings user to AMS
   */
  getSignInButton(): string {
    const pdsButtonStyle = 'style=\"height: 2.8571428571rem; cursor: pointer; display: inline-flex; ' +
      'flex-shrink: 0; font-family: inherit; font-size: 1rem!important; font-weight: 700; background: none; ' +
      'letter-spacing: normal; min-width: 0; line-height: 2.1428571429rem; padding: 0rem 1rem; text-align: center; ' +
      'text-decoration: none; margin: 0; align-items: center; justify-content: center; ' +
      'border: solid .1428571429rem transparent; border-radius: 1.4285714286rem !important; ' +
      'border-left: .1428571429rem solid #004D99; border-right: .1428571429rem solid #004D99; ' +
      'border-top: solid .1428571429rem transparent; border-bottom: solid .1428571429rem transparent; ' +
      'border-top-width: .1428571429rem; border-right-width: .1428571429rem; border-bottom-width: .1428571429rem; ' +
      'border-left-width: .1428571429rem; white-space: nowrap; min-height: 24px; box-shadow: none; ' +
      'background-color: #004d99; border-bottom-color: none; color: #fff;\"';
    const dashboardLink = 'href = \"' + environment.dashboardUrl + '\"';
    // TODO use if we figure out redirects
    // const dashboardLink = 'href = \"' + environment.dashboardUrl + '#/internal/audit-review/' + auditDetails.recordId + '\"';
    return '<a ' + pdsButtonStyle + ' ' + dashboardLink + '>Sign In</a>';
  }

  /**
   * Gathers all emails associated to the audit (Requester of the audit, All contacts on the audit,
   * primary firm email address (general email)). We use auditContactDetails and the string values of
   * the audit requester and firm email to return a full list of emails that need to be notified.
   *
   * @param auditContactDetails - Object containing the audit contact info
   * @param createdById - Email string of audit requester (CANNOT BE NULL)
   */
  getAllEmailContacts(auditContactDetails: any, createdById: string): string[] {
    const contactDetails = auditContactDetails;
    const emailAddresses = [];
    emailAddresses.push(createdById);
    contactDetails.primaryAuditContact.forEach(contact => {
      if(emailAddresses.indexOf(contact.contactEmail) === -1) {
        emailAddresses.push(contact.contactEmail);
      }
    });
    contactDetails.auditSubcontractorOne.forEach(contact => {
      if(emailAddresses.indexOf(contact.contactEmail) === -1) {
        emailAddresses.push(contact.contactEmail);
      }
    });
    contactDetails.auditSubcontractorTwo.forEach(contact => {
      if(emailAddresses.indexOf(contact.contactEmail) === -1) {
        emailAddresses.push(contact.contactEmail);
      }
    });
    if(contactDetails.generalMail !== null && emailAddresses.indexOf(contactDetails.generalMail) === -1) {
      emailAddresses.push(contactDetails.generalMail);
    }
    return emailAddresses;
  }

  /**
   * Function to create the email body for audit analyst assignment notification.
   *
   * @param auditDetails - audit detail from dashboardService.getAuditDetailsByRecordId
   * @param auditContacts - audit contacts detail from contactService.getAuditContactDetail
   * @param auditTimelines - audit timelines detail from timelineService.getAll
   * @param fileDetails - uploaded file details
   */
  createEmailBodyForFileUploadedByAuditor(auditDetails: any, auditContacts: any, auditTimelines: any, fileDetails: any): string {
    const auditInfo = auditDetails.auditInformation;
    let emailBody = '<h1 style=\"text-align: center; line-height: 36.4px;\">A Document has been uploaded in Audit Management</h1>';
    emailBody += this.createEmailBodyParagraph('fileUploadedByAuditor');

    const primaryAuditContact = auditContacts.primaryAuditContact
                ?.filter((contact: AuditContactModel) => contact?.isPrimaryContact === true)
                ?.map((contact: AuditContactModel) => contact?.firstName + ' ' + contact?.lastName);
    const primaryContactName = primaryAuditContact?.length ? primaryAuditContact[0] : null;
    const kickoffDate = auditTimelines[0].notApplicable ? 'N/A' : auditTimelines[0].date;
    emailBody += '<strong>Audit #: </strong><a href="'+ environment.dashboardUrl + '">' + auditDetails?.recordId + '</a><br><br>';
    const clientCoalitionName = (auditInfo.clientName !== null && auditInfo.clientName.length > 0) ?
      auditInfo.clientName : auditInfo.originalClientName;
    emailBody += this.setEmailBodyGeneralInfo(auditInfo, primaryContactName, clientCoalitionName, kickoffDate);
    emailBody += '<hr><br><strong>' + fileDetails?.header + '</strong><p>' + fileDetails?.fileName + '</p><br><hr><br>';

    emailBody += this.getSignInButton();
    return emailBody;
  }
}

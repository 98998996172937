import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import {
  CVSFileUploadTableModule,
  PDSSpinnerModule,
  TooltipModule
} from 'angular-component-library';
import { BaseComponent } from './components/base/base.component';
import {BannerService} from './services/banner.service';
import {InputRestrictionDirective} from './directive/input-restriction.directive';
import {SpecialCharacterDirective} from './directive/speciaIs-alpha-numeric.directive';
import { UploadDocsComponent } from './components/upload-docs/upload-docs.component';
import { DateFormatPipe } from './components/upload-docs/date-format.pipe';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {
  DocumentManagementTableComponent
} from '../document-management/document-management-table/document-management-table.component';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import { NotesManagementComponent } from './components/notes-management/notes-management.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
  declarations: [
    BaseComponent,
    InputRestrictionDirective,
    SpecialCharacterDirective,
    UploadDocsComponent,
    DocumentManagementTableComponent,
    NotesManagementComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatSortModule,
    CVSFileUploadTableModule,
    DateFormatPipe,
    MatMenuModule,
    TooltipModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    PDSSpinnerModule,
    MatChipsModule,
    MatDialogModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatRadioModule
  ],
    exports: [
        BaseComponent,
        UploadDocsComponent,
        DocumentManagementTableComponent,
        NotesManagementComponent
    ],
  providers: [BannerService]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent
} from '@angular/material/paginator';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import {
  CVSConfirmationDialogContentComponent,
  CVSPopoverButtonService,
  CVSPopoverParams,
  CVSPopoverRef,
  DateRange,
  Direction
} from 'angular-component-library';
import { Constants } from '../../../constants/constants';
import { FilterObjModel } from '../../../models/filter-obj.model';
import { ExternalAuditDetailResponseModel } from '../../../models/external-audit-detail-response.model';
import { DashboardService } from '../../../services/dashboard.service';
import { EnvConfigService } from '../../../services/config/env-config.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {ExternalDashboardModel} from '../../../models/external-dashboard.model';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {NotesManagementComponent} from '../../../shared/components/notes-management/notes-management.component';
import {NotesCategory, NotesType} from '../../../shared/components/notes-management/notes-management.constants';
import {NotesManagementModel} from '../../../shared/components/notes-management/notes-management.model';
import { AuditRequestUtil } from 'src/app/utilities/audit-request.util';

@Component({
  selector: 'app-external-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss']
})
export class ExternalDashboardListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('filterTablePaginator') paginator!: MatPaginator;
  @ViewChild('auditTypeDropdown') auditTypeDropdown!: MatCheckbox;
  @ViewChild('auditStatusDropdown') auditStatusDropdown!: MatCheckbox;
  @ViewChild('popoverDateRange') popoverDateRangeVC: any;
  @ViewChild('notificationDateField')
  notificationDateInputFieldRef!: ElementRef;
  @ViewChild('createdDateField') createdDateInputFieldRef!: ElementRef;
  @ViewChild('auditScopeStartDateField')
  auditScopeStartDateInputFieldRef!: ElementRef;
  @ViewChild('auditScopeEndDateField')
  auditScopeEndDateInputFieldRef!: ElementRef;
  @ViewChild('contactModalTemplate') contactModalTemplate: TemplateRef<any>;
  @ViewChildren('menuBtn') menuButtons!: QueryList<MatMenuItem>;
  @ViewChildren(MatMenuTrigger) triggers!: QueryList<MatMenuTrigger>;
  protected readonly Constants = Constants;

  @Input() tabStatus!: string;
  @Output() amsRecordId: EventEmitter<string> = new EventEmitter<string>();
  @Output() clientDetail: EventEmitter<string> = new EventEmitter<string>();
  @Output() tabNotesCount: EventEmitter<any> = new EventEmitter<any>();

  externalAuditDisplayedColumns: string[] = [
    'action',
    'auditStatus',
    'amsAuditRecordId',
    'auditType',
    'originalClientName',
    'notificationDate',
    'assignedMemberAuditor',
    'auditSubContractor1',
    'auditSubContractor2',
    'assignedMemberCvs',
    'auditScopeStartDate',
    'auditScopeEndDate',
    'clientAuditLob1',
    'clientAuditLob2',
    'createdDate'
  ];
  approvedAuditStatus: string[] = [
    'In Progress',
    'On Hold',
    'Pending Client Approval',
    'Closed'
  ];
  filterForm: FormGroup = this.fb.group({});
  dataSource: any;
  accounts: any[] = [];
  subscription$: Subscription[] = [];
  loading = false;
  /** Using for pagination**/
  hidePaginator = true;
  pageNumber = 0;
  pageSize = 10;
  resultLength = 0;
  sortBy = '';
  /** Default sort direction descending order**/
  sortDirection = 'DESC';
  /** dropdown values**/
  stateOptions: any = {
    auditType: Constants.TYPE_OF_AUDIT,
    auditStatus: []
  };

  userEmail: string;

  /** Using for Date-picker**/
  _popoverRef!: CVSPopoverRef;

  /** Using for Contact Modal Display**/
  contactModalInfo = { name: '', email: '' };
  modalMatDialogRef: MatDialogRef<any>;

  direction!: Direction;
  defaultDateRange = ({
    startDate: null,
    endDate: null
  } as unknown) as DateRange;

  showAuditStatusNotes = false;
  filterObj!: any;

  /** Display table columns generic for active and closed**/
  externalDashboardColumns: any[] = [
    { key: 'action',
      value: 'Action',
      type: 'NONE',
      text: 'No'
    },
    {
      key: 'auditStatus',
      value: 'Status of Audit',
      type: 'DROP_DOWN',
      text: 'Status'
    },
    {
      key: 'amsAuditRecordId',
      value: 'Audit Record ID',
      type: 'INPUT',
      text: 'Hyperlink'
    },
    {
      key: 'auditType',
      value: 'Audit Type',
      type: 'DROP_DOWN',
      text: 'Normal'
    },
    {
      key: 'originalClientName',
      value: 'Client Name',
      type: 'INPUT',
      text: 'Normal'
    },
    {
      key: 'notificationDate',
      value: 'Notification Date',
      type: 'DATE',
      text: 'Normal'
    },
    {
      key: 'assignedMemberAuditor',
      value: 'Assigned Member Auditor',
      type: 'INPUT',
      text: 'Normal'
    },
    {
      key: 'auditSubContractor1',
      value: 'Audit Subcontractor1',
      type: 'INPUT',
      text: 'Normal'
    },
    {
      key: 'auditSubContractor2',
      value: 'Audit Subcontractor2',
      type: 'INPUT',
      text: 'Normal'
    },
    {
      key: 'assignedMemberCvs',
      value: 'Assigned Member CVS',
      type: 'INPUT',
      text: 'Modal'
    },
    {
      key: 'auditScopeStartDate',
      value: 'Audit Scope Start Date',
      type: 'DATE',
      text: 'Normal'
    },
    {
      key: 'auditScopeEndDate',
      value: 'Audit Scope End Date',
      type: 'DATE',
      text: 'Normal'
    },
    {
      key: 'clientAuditLob1',
      value: 'Line of Business 1',
      type: 'INPUT',
      text: 'Normal'
    },
    {
      key: 'clientAuditLob2',
      value: 'Line of Business 2',
      type: 'INPUT',
      text: 'Normal'
    },
    {
      key: 'createdDate',
      value: 'Created Date',
      type: 'DATE',
      text: 'Normal'
    }
  ];

  /** Checking filter is active or not**/
  get isFilterAction() {
    let isActive = false;
    this.externalDashboardColumns.forEach((column: any) => {
      const isValue: boolean =
        column.type === Constants.INPUT_DROPDOWN &&
        this.filterForm.controls[column.key].value
          ? !!this.filterForm.controls[column.key].value.length
          : !!this.filterForm.controls[column.key].value;
      isActive = isActive || isValue;
    });
    return isActive;
  }
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private externalDashboardService: DashboardService,
    private datePipe: DatePipe,
    public popover: CVSPopoverButtonService,
    public matPaginationService: MatPaginatorIntl,
    private envConfigService: EnvConfigService,
    private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private liveAnnouncer: LiveAnnouncer
  ) {
    this.matPaginationService.itemsPerPageLabel = 'Rows per page';
    this.envConfigService.consumeEnvData(this.activatedRoute);
    // todo switch to use logged in user email
    this.userEmail = sessionStorage.getItem('email');
  }

  ngOnInit(): void {
    /** Initiate table datasource value**/
    this.dataSource = new MatTableDataSource<any>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getClientDetail();
    this.getStatusOfAudit();
    this.getSortBy();
    this.addFilterForm();
    this.getAuditSummaryList();
  }
  ngOnDestroy(): void {
    this.subscription$.forEach(subscription => subscription.unsubscribe());
  }

  getClientDetail() {
    if(this.tabStatus === Constants.ACTIVE_TAB) {
      this.clientDetail.emit();
    }
  }

  /** Add filter form to dashboard-column dynamically**/
  addFilterForm() {
    this.externalDashboardColumns.forEach((column: any) => {
      this.filterForm.addControl(
        column.key,
        new FormControl(column.type === Constants.INPUT_DROPDOWN ? [] : '')
      );
    });
  }

  getAuditStatus(status: string) {
    if (status === Constants.STATUS_IN_PROGRESS) {
      return Constants.IN_PROGRESS;
    }
    return status;
  }

  /** Sorting based on Active and Draft Tab**/
  getSortBy() {
    if (this.tabStatus === Constants.DRAFT_TAB) {
      this.sortBy = Constants.CREATED_DATE;
    } else if (this.tabStatus === Constants.ACTIVE_TAB) {
      this.sortBy = Constants.NOTIFICATION_DATE;
    }
  }

  /** Apply filter for Input and Dropdown Values**/
  stopPropagation(event: Event) {
    this.pageNumber = 0;
    this.paginator.firstPage();
    event.preventDefault();
    event.stopPropagation();
    this.getAuditSummaryList();
  }

  clearCurrentFilter(formControlName: string) {
    this.filterForm.controls[formControlName].setValue('');
    document.getElementById(formControlName)?.focus();
    this.getSortBy();
    this.getAuditSummaryList();
  }

  /** Filter by drop-down field**/
  toggleAllSelection(column: any) {
    const element =
      column.key === Constants.AUDIT_TYPE
        ? this.auditTypeDropdown.checked
        : this.auditStatusDropdown.checked;
    if (element) {
      this.filterForm.controls[column.key].patchValue([]);
    } else {
      this.filterForm.controls[column.key].patchValue([
        ...this.stateOptions[column.key].map((item: any) => item.id)
      ]);
    }
    column.key === Constants.AUDIT_TYPE
      ? this.auditTypeDropdown.toggle()
      : this.auditStatusDropdown.toggle();
    this.getAuditSummaryList();
  }

  /** Checking dropdown fields all are selected or not**/
  isIndeterminate(formControlName: string): boolean {
    return (
      this.filterForm.controls[formControlName].value.length > 0 &&
      this.filterForm.controls[formControlName].value.length <
        this.stateOptions[formControlName].length
    );
  }

  /** Toggle for Select All checkbox**/
  toggleOption(column: any) {
    const isChecked =
      column.key === Constants.AUDIT_TYPE
        ? this.auditTypeDropdown.checked
        : this.auditStatusDropdown.checked;
    const indeterminate =
      column.key === Constants.AUDIT_TYPE
        ? this.auditTypeDropdown.indeterminate
        : this.auditStatusDropdown.indeterminate;

    if (
      isChecked &&
      this.filterForm.controls[column.key].value.length <
        this.stateOptions[column.key].length
    ) {
      column.key === Constants.AUDIT_TYPE
        ? this.auditTypeDropdown.toggle()
        : this.auditStatusDropdown.toggle();
    }
    if (
      !isChecked &&
      this.filterForm.controls[column.key].value.length ===
        this.stateOptions[column.key].length
    ) {
      column.key === Constants.AUDIT_TYPE
        ? this.auditTypeDropdown.toggle()
        : this.auditStatusDropdown.toggle();
    }
    if (
      indeterminate &&
      this.filterForm.controls[column.key].value.length === 0
    ) {
      if (column.key === Constants.AUDIT_TYPE) {
        this.auditTypeDropdown.indeterminate = false;
      } else {
        this.auditStatusDropdown.indeterminate = false;
      }
    }
    this.getAuditSummaryList();
  }

  /** Start date-picker changes**/
  applyStartDateChange(event: any) {
    this.defaultDateRange.startDate = event.target.value;
  }

  /* End date-picker changes*/
  applyEndDateChange(event: any) {
    this.defaultDateRange.endDate = event.target.value;
  }

  /** Open and close date popover from ACL**/
  showDateRangePopover(event: any, columnKey: any) {
    event.stopPropagation();
    this.resetDateRange(columnKey);
    this.showPopoverFromClick(this.popoverDateRangeVC, columnKey, false);
  }

  getDateFieldElement(columnKey: any) {
    switch (columnKey) {
      case Constants.NOTIFICATION_DATE: {
        return this.notificationDateInputFieldRef;
      }
      case Constants.CREATED_DATE: {
        return this.createdDateInputFieldRef;
      }
      case Constants.AUDIT_SCOPE_START_DATE: {
        return this.auditScopeStartDateInputFieldRef;
      }
      default: {
        return this.auditScopeEndDateInputFieldRef;
      }
    }
  }

  resetDateRange(columnKey: string) {
    if (this.filterForm.get(columnKey)?.value) {
      const splitDateRange = this.filterForm.get(columnKey)?.value.split(' - ');
      if (splitDateRange.length === 2) {
        this.defaultDateRange = ({
          startDate: new Date(splitDateRange[0]),
          endDate: new Date(splitDateRange[1])
        } as unknown) as DateRange;
      }
    }
  }

  showPopoverFromClick = (
    popover: any,
    columnKey: any,
    disableClose = false
  ) => {
    this._popoverRef = this.popover.open(
      {
        origin: this.getDateFieldElement(columnKey),
        content: popover,
        direction: this.direction,
        data: {
          hideX: true
        }
      } as CVSPopoverParams<any>,
      disableClose
    );

    this._popoverRef.afterClosed$.subscribe(() => {
      this.writeToDsFilter(columnKey);
    });
  };

  /** Update notificationDate value in form If selected startDate and endDate in date-picker**/
  writeToDsFilter(columnKey: any) {
    /** Column Key matching with NotificationDate, createdDate, auditScopeStartDate, AuditScopeEndDate**/
    if (this.defaultDateRange.startDate && this.defaultDateRange.endDate) {
      this.filterForm.controls[columnKey].patchValue(
        this.datePipe.transform(
          this.defaultDateRange.startDate,
          Constants.DATE_FORMAT
        ) +
          ' - ' +
          this.datePipe.transform(
            this.defaultDateRange.endDate,
            Constants.DATE_FORMAT
          )
      );
    } else {
      this.filterForm.controls[columnKey].patchValue('');
    }
    this.defaultDateRange = ({
      startDate: null,
      endDate: null
    } as unknown) as DateRange;
    this.getAuditSummaryList();
  }

  /** Setting status of audit list for Active and Closed tab**/
  getStatusOfAudit() {
    if (this.tabStatus === Constants.ACTIVE_TAB) {
      this.stateOptions.auditStatus = Constants.ACTIVE_STATUS;
    } else if (this.tabStatus === Constants.DRAFT_TAB) {
      this.stateOptions.auditStatus = Constants.DRAFT_STATUS;
    }
  }

  /** Get Audit summary list for Active and Draft Tab**/
  getAuditSummaryList = () => {
    /** Set filter object for API call**/
    this.filterObj = {
      filter: this.trimFilterInputs(this.filterForm.getRawValue()),
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
      offset: this.pageNumber,
      pageSize: this.pageSize
    };

    this.getDashboardSummaryList();
  };


  private trimFilterInputs(filterObj: any) {
    for (const [key, value] of Object.entries(filterObj)) {
      if (typeof value === 'string') {
        filterObj[key] = value.trim();
      }
    }
    return filterObj;
  }

  /** Get Audit List for Draft Tab FROM API**/
  getDraftAuditSummaryListFromAPI(filterObj: FilterObjModel) {
    this.subscription$.push(
      this.externalDashboardService
        .getAuditSummaryDraftList(filterObj)
        .subscribe((data: ExternalAuditDetailResponseModel) => {
          if (data?.response?.content?.length) {
            this.hidePaginator = false;
            this.loading = false;
            this.accounts = this.mapAccounts(data?.response?.content);
            this.showAuditStatusNotes = data?.response?.content?.filter( (f: ExternalDashboardModel) => f?.notesExist)?.length > 0;
            this.dataSource.data = this.accounts;
            this.resultLength = data?.response?.totalElements;
            this.tabNotesCount.emit({
              activeNotesCount: data?.tabCountResponse?.activeNotesCount,
              draftNotesCount: data?.tabCountResponse?.draftNotesCount
            });
          } else {
            this.dataSource.data = [];
            this.resultLength = 0;
            this.hidePaginator = true;
            this.loading = false;
            this.sortBy = '';
          }
        })
    );
  }

  /** Get Audit List for Active Tab FROM API**/
  getActiveAuditSummaryListFromAPI(filterObj: FilterObjModel) {
    this.subscription$.push(
      this.externalDashboardService
        .getAuditSummaryActiveList(filterObj)
        .subscribe((data: ExternalAuditDetailResponseModel) => {
          if (data?.response?.content?.length) {
            this.hidePaginator = false;
            this.loading = false;
            this.accounts = this.mapAccounts(data?.response?.content);
            this.showAuditStatusNotes = data?.response?.content?.filter( (f: ExternalDashboardModel) => f?.notesExist)?.length > 0;
            this.dataSource.data = this.accounts;
            this.resultLength = data?.response?.totalElements;
            this.tabNotesCount.emit({
              activeNotesCount: data?.tabCountResponse?.activeNotesCount,
              draftNotesCount: data?.tabCountResponse?.draftNotesCount
            });
          } else {
            this.dataSource.data = [];
            this.resultLength = 0;
            this.hidePaginator = true;
            this.loading = false;
          }
        })
    );
  }

  mapAccounts(accounts: any) {
    return accounts.map((account: ExternalDashboardModel) => {
      if(null != account.createdDate) {
        if (!account.createdDate.endsWith(Constants.CREATED_DATE_TIME_ZONE_SUFFIX)) {
          account.createdDate = AuditRequestUtil.convertCtTimeZone(account.createdDate,
            Constants.CREATED_DATE_TIME_ZONE_SUFFIX);
        }
      }
      if(null != account.notificationDate) {
        if (!account.notificationDate.endsWith(Constants.NOTIFICATION_DATE_TIME_ZONE_SUFFIX)) {
          account.notificationDate = AuditRequestUtil.convertCtTimeZone(account.notificationDate,
            Constants.NOTIFICATION_DATE_TIME_ZONE_SUFFIX);
        }
      }
      return {
        ...account,
          isApproved: this.isAuditApproved(account.auditStatus),
        isAuditor: this.isAuditor(
          account.assignedMemberEmail,
          account.createdById
        )
      };
    });
  }

  /** Server side Pagination**/
  onPageChange(pe: PageEvent) {
    this.pageNumber = pe.pageIndex;
    this.pageSize = pe.pageSize;
    this.getAuditSummaryList();
  }

  /** Sorting based on Server side data**/
  sortData(event: Sort) {
    this.announceSort(event);
    if (event.active === Constants.AMS_AUDIT_RECORD_ID) {
      this.sortBy = Constants.AMS_AUDIT_RECORD_ID;
    } else if (event.active === Constants.AUDIT_STATUS) {
      this.sortBy = Constants.AUDIT_STATUS;
    } else if (event.active === Constants.AUDIT_TYPE) {
      this.sortBy = Constants.AUDIT_TYPE;
    } else if (event.active === Constants.CLIENT_NAME) {
      this.sortBy = Constants.CLIENT_NAME;
    } else if (event.active === Constants.NOTIFICATION_DATE) {
      this.sortBy = Constants.NOTIFICATION_DATE;
    } else if (event.active === Constants.ASSIGNED_MEMBER_AUDITOR) {
      this.sortBy = Constants.ASSIGNED_MEMBER_AUDITOR;
    } else if (event.active === Constants.AUDIT_SUB_CONTRACTOR_1) {
      this.sortBy = Constants.AUDIT_SUB_CONTRACTOR_1;
    } else if (event.active === Constants.AUDIT_SUB_CONTRACTOR_2) {
      this.sortBy = Constants.AUDIT_SUB_CONTRACTOR_2;
    } else if (event.active === Constants.ASSIGNED_MEMBER_CVS) {
      this.sortBy = Constants.ASSIGNED_MEMBER_CVS;
    } else if (event.active === Constants.AUDIT_SCOPE_START_DATE) {
      this.sortBy = Constants.AUDIT_SCOPE_START_DATE;
    } else if (event.active === Constants.AUDIT_SCOPE_END_DATE) {
      this.sortBy = Constants.AUDIT_SCOPE_END_DATE;
    } else if (event.active === Constants.CLIENT_AUDIT_LOB_1) {
      this.sortBy = Constants.CLIENT_AUDIT_LOB_1;
    } else if (event.active === Constants.CLIENT_AUDIT_LOB_2) {
      this.sortBy = Constants.CLIENT_AUDIT_LOB_2;
    } else {
      this.sortBy = event.direction ? event.active : Constants.CREATED_DATE;
    }
    this.sortDirection = event.direction
      ? event.direction.toUpperCase()
      : 'DESC';
    this.getAuditSummaryList();
  }

  menuFocus(position: any) {
    setTimeout(() => {
      this.menuButtons?.get(position)?.focus();
    });
  }

  menuHandler(position: any) {
    this.triggers?.get(position)?.openMenu();
  }

  isAuditApproved(auditStatus: string) {
    return this.approvedAuditStatus.includes(auditStatus);
  }

  isAuditor(assignedMemberEmail: string, createdByEmail: string) {
    return (
      this.userEmail === assignedMemberEmail ||
      this.userEmail === createdByEmail
    );
  }

  viewAuditRequest(recordId: string) {
    this.router.navigate(['/audit-request-view',recordId]).then();
  }

  manageDocuments(audit: any) {
    this.router.navigate(['/document-management', audit.amsAuditRecordId, audit.auditId]).then();
  }

  openActualTimeline(audit: any) {
    this.router.navigate(['/audit-timeline-form', audit.amsAuditRecordId, audit.auditId]).then();
  }

  editAuditRequest(auditRecordID: string) {
    this.router.navigate(['/audit-request', auditRecordID]).then();
  }

  deleteAuditRequest(auditId: number) {
    this.subscription$.push(
      this.externalDashboardService.deleteAuditRequest(auditId)
      .subscribe((response: any) => {
        if (response) {
          this.getAuditSummaryList();
        }
      }));
  }

  openModalDialog(audit: any) {
    this.contactModalInfo.name = audit.assignedMemberCvs;
    this.contactModalInfo.email = audit.assignedMemberEmail;

    this.modalMatDialogRef = this.matDialog.open(
      CVSConfirmationDialogContentComponent,
      {
        disableClose: true,
        data: {
          headline: this.contactModalInfo.name,
          templatePortalContent: this.contactModalTemplate,
          noCloseX: false
        }
      }
    );
  }

  private announceSort(event: Sort) {
    const sortedColumn = this.externalDashboardColumns.find(col => col.key === event.active);

    if(event.direction) {
      const direction = event.direction === 'asc' ? 'ascending' : 'descending';
      this.liveAnnouncer.announce('Sorted by ' +  sortedColumn.value + ', ' + direction).then();
    } else {
      this.liveAnnouncer.announce('Removed sort on ' + sortedColumn.value).then();
    }
  }

  viewStatusNotesDialog(auditId: any) {
    const notesModelData: NotesManagementModel = {
      auditId,
      notesType: NotesType.AUDIT_STATUS,
      category: NotesCategory.AUDIT_STATUS_EXTERNAL,
      modalType: 'view'
    };
    this.matDialog.open(NotesManagementComponent,
      {
        disableClose: true,
        data: notesModelData
    }).afterClosed().subscribe((result: any) => {
      if(result) {
        this.getDashboardSummaryList();
      }
    });
  }

  getDashboardSummaryList() {
    this.loading = true;
    this.hidePaginator = true;
    this.dataSource.data = [];
    this.dataSource = new MatTableDataSource<any>(this.dataSource.data);
    if (this.tabStatus === Constants.DRAFT_TAB) {
      this.getDraftAuditSummaryListFromAPI(this.filterObj);
    } else if (this.tabStatus === Constants.ACTIVE_TAB) {
      this.getActiveAuditSummaryListFromAPI(this.filterObj);
    }
  }
}

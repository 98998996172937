export const AuditTypes: any = {
  claimAuditTypes: [
    {
      name: 'Pricing',
      id: 'pricing',
      included: true
    },
    {
      name: 'Rebate Guarantee',
      id: 'rebateGuarantee',
      included: true
    },
    {
      name: 'Security',
      id: 'security',
      included: true
    },
    {
      name: 'Actual Acquisition Cost',
      id: 'actualAcquisitionCost',
      included: true
    },
    {
      name: 'Pre-implementation',
      id: 'preImplementation',
      included: true
    },
    {
      name: 'Benefits',
      id: 'benefits',
      included: true
    },
    {
      name: 'Operational',
      id: 'operational',
      included: true
    },
    {
      name: 'Accumulator',
      id: 'accumulator',
      included: true
    },
    {
      name: 'Performance Guarantee',
      id: 'performanceGuarantee',
      included: true
    },
    {
      name: 'Post-Implementation',
      id: 'postImplementation',
      included: true
    }
  ],
  otherInfoRequests: [
    {
      name: 'Invoices',
      id: 'invoices',
      included: true
    },
  ]
};

export const INFO_REQUEST: any = Object.freeze({
  HEALTHCARE_REFORM_GPT_LIST: 'Healthcare Reform GPI List',
  MAIL_AND_SPECIALTY_PHARMACY_LIST: 'Mail & Specialty Pharmacy List',
  PREVENTATIVE_DRUG_LIST: 'Preventative Drug List',
  SOC_1_REPORT: 'SOC 1 Report',
  LIMITED_DISTRIBUTION_LIST: 'Limited Distribution List'
});

export const REUSABLE_FILE: string[] = [
    INFO_REQUEST.HEALTHCARE_REFORM_GPT_LIST,
    INFO_REQUEST.MAIL_AND_SPECIALTY_PHARMACY_LIST,
    INFO_REQUEST.PREVENTATIVE_DRUG_LIST,
    INFO_REQUEST.SOC_1_REPORT,
    INFO_REQUEST.LIMITED_DISTRIBUTION_LIST
];

export const formValidationMessages = Object.freeze({
  effectiveStartDate: {
    matDatepickerParse: 'Effective Date should be in the MM/DD/YYYY format',
    matDatepickerMax: 'Effective Date should be in the MM/DD/YYYY format',
    required: 'Effective Date is required!',
    dateLessThan: 'Effective Date should be less than End Date',
  },
  effectiveEndDate: {
    matDatepickerParse: 'End Date should be in the MM/DD/YYYY format',
    matDatepickerMax: 'End Date should be in the MM/DD/YYYY format',
    required: 'End Date is required!',
  },
  asOfDate: {
    matDatepickerParse: 'As of Date should be in the MM/DD/YYYY format',
    matDatepickerMax: 'As of Date should be in the MM/DD/YYYY format',
    required: 'As of Date is required!',
  }
});

export enum LDDSubCategory {
  SPECIALTY_ONLY = 'Specialty Only LDD',
  NON_SPECIALTY_INC = 'Specialty & Non-Specialty LDD'
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {TimeLine} from '../../../models/time-line.model';
import {TimeLineService} from '../time-line.service';
import {MatTableDataSource} from '@angular/material/table';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {BannerService} from '../../../shared/services/banner.service';
import {AuditRequest} from '../../audit-request.model';
import {
  CVSBannerComponentData,
  CVSBannerType
} from 'angular-component-library';
import * as AUDIT_TIMELINE from '../time-line.constants';
import {Router} from '@angular/router';
import {addBusinessDays, differenceInBusinessDays, differenceInCalendarDays} from 'date-fns';
import {isHoliday} from 'date-fns-holiday-us';
import {AuditSubmitModel} from '../AuditSubmitModel';
import {UserService} from 'src/app/services/user.service';
import {Constants} from 'src/app/constants/constants';
import * as AUDIT_INFO from '../../audit-information/audit-information.constants';
import {MatDialog} from '@angular/material/dialog';
import {forkJoin, Observable} from 'rxjs';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {AuditRequestUtil} from '../../../utilities/audit-request.util';
import {AuditContactDetailModel} from '../../audit-contact/audit-contact.model';
import {NotesManagementComponent} from '../../../shared/components/notes-management/notes-management.component';
import {DatePipe} from '@angular/common';
import {NotesCategory, NotesType} from '../../../shared/components/notes-management/notes-management.constants';
import {NotesManagementModel} from '../../../shared/components/notes-management/notes-management.model';
import {NotificationService} from '../../../services/notification.service';
import {NotesManagementService} from '../../../shared/components/notes-management/notes-management.service';
import {DashboardService} from '../../../services/dashboard.service';
import {NotificationHistoryRequestModel} from '../../../models/notification-history-request.model';
import {AuditRoleResponsibility} from '../../audit-role/audit-role.model';

enum TIMELINES {
  KICK_OFF_DATE = 'Timeline External kick Off Date',
  // DELIVERABLE_DUE_DATE = 'Timeline Initial Deliverable Due Date',
  // REPORTING_DUE_DATE = 'Timeline GER Reporting Due Date',
  QUESTIONS_DUE_DATE = 'Timeline Follow Up Questions Due Date',
  // QUESTIONS_RESPONSE_DATE = 'Timeline Follow Up Questions Response Due Date',
  START_DATE = 'Timeline Onsite Start Date',
  RECEIVED_DUE_DATE = 'Timeline Sample Received Due Date',
  // RESPONSE_DUE_DATE = 'Timeline Sample Response Due Date',
  RESOLVED_DUE_DATE = 'Timeline All Sample Follow Up Resolved Due Date',
  DRAFT_RECEIVED_DUE_DATE = 'Timeline Draft Report Received Due Date',
  // DRAFT_RESPONSE_DUE_DATE = 'Timeline Draft Report Response Due Date',
  FINAL_RECEIVED_DUE_DATE = 'Timeline Final Report Received Due Date',
  // FINAL_RESPONSE_DUE_DATE = 'Timeline Final Report Response Due Date',
  COMPLETION_DATE = 'Timeline Expected Audit Completion Date'
}

@Component({
  selector: 'app-request-time-line',
  templateUrl: './request-time-line.component.html',
  styleUrls: ['./request-time-line.component.scss']
})
export class RequestTimeLineComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() auditTimelineForm!: FormGroup;
  @Input() showBanner!: boolean;
  @Input() getTimelines!: boolean;
  @Input() auditDetail!: AuditRequest;
  @Input() isAuditTypePricing = false;
  @Input() amsRecId: string;
  @Input() auditRequestMode!: string;
  @Input() auditContactDetail!: AuditContactDetailModel;
  @Input() auditRoleResponsibility: AuditRoleResponsibility;
  @Output() savedAuditTimelineForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() completedAuditTimelineForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() timelineWarning = new EventEmitter<boolean>();
  @Output()
  dateInput: EventEmitter<MatDatepickerInputEvent<any>>;
  @Output() clientAuditCaseUpdate = new EventEmitter<any>();

  @Input() auditForm!: FormGroup;
  @ViewChild('overallSaveTemplate') overallSaveTemplate: TemplateRef<any>;
  @ViewChild('submitApiTemplate') submitApiTemplate: TemplateRef<any>;
  @ViewChild('approveAuditModal') approveAuditModalRef!: TemplateRef<any>;
  protected readonly AUDIT_TIMELINE = AUDIT_TIMELINE;
  protected readonly Constants = Constants;
  protected readonly AUDIT_INFO = AUDIT_INFO;
  timeLineListSaved: TimeLine[] = [];
  timeLineDataSource!: MatTableDataSource<any>;
  enabledIndexes = [];
  isFormSetupComplete = false;
  savedArray: any[] = [];
  bannerData!: CVSBannerComponentData;
  submitResponse: AuditSubmitModel;
  disableApproveSubmit = true;
  wasSaveSuccessful = false;
  externalUser!: boolean;
  confirmationDialog: any;
  timelineDateInvalid = false;
  gerTimeLime!: TimeLine;
  isAnalystOrAdmin = false;
  deleteNotesExtUser!: boolean;
  externalAdmin!: boolean;
  isClientAuditCase: boolean;
  clientOrCoalitionLabelName!: string;
  originalClientName!: string;
  clientName!: string;
  showLoader = false;
  viewerEmailList: string[] = [];
  disableForCancelAuditor: boolean;
  disableForReturnAuditor: boolean;
  menuColumns=['projectedTimeline', 'notApplicable', 'generalTimeline', 'responsibility', 'notes'];
  enableNotes = true;
  auditDetails$!: Observable<any>;
  auditNotes$!: Observable<any>;

  constructor(
    private fb: FormBuilder,
    private timeLineService: TimeLineService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    public bs: BannerService,
    private cdr: ChangeDetectorRef,
    public userService: UserService,
    private matDialog: MatDialog,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private dashboardService: DashboardService,
    private notesManagementService: NotesManagementService
  ) {
    super(bs);
    this.auditTimelineForm = this.fb.group({
      tableRows: this.fb.array([]),
    });
  }

  get tableRows() {
    return this.auditTimelineForm.controls['tableRows'] as FormArray;
  }

  ngOnInit(): void {
    this.externalUser = this.userService.isExternalUser();
    this.externalAdmin = this.userService.isExternalAdmin();
    this.deleteNotesExtUser = !this.userService.isExternalAdmin() && !this.userService.isAuditAnalyst() && !this.userService.isAuditAdmin();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isClientAuditCase = !!this.auditDetail?.clientAuditRecordId;
    this.disableForCancelAuditor = AuditRequestUtil.getStatusOfAuditForCancelAuditor(this.auditDetail?.status);
    this.disableForReturnAuditor = AuditRequestUtil.getStatusOfAuditForReturnAuditor(this.auditDetail?.status);
    if((this.isFormSetupComplete === false &&
      this.auditDetail !== null &&
      changes.isAuditTypePricing?.currentValue !== undefined) || changes.getTimelines?.currentValue === true) {
      this.initTimelines();
    }

    if (changes.auditTimelineForm?.currentValue) {
      this.auditTimelineForm = changes.auditTimelineForm.currentValue;
    }

    if (changes.isAuditTypePricing?.currentValue !== undefined) {
        if (!changes.isAuditTypePricing?.currentValue) {
          this.tableRows.at(2)?.patchValue(this.gerTimeLime, { emitEvent: false });
        }
        this.patchFormOnPricingChange();
      }
  }

  initTimelines(): void {
    if(this.auditDetail !== undefined) {
      this.timeLineService.getAll(this.auditDetail?.auditId).subscribe({
        next: (timelines) => {
          this.timeLineListSaved = timelines;
          this.changeTimelineDurations(this.timeLineListSaved);
          if(this.isTimelineNew(this.timeLineListSaved)) {
            this.initializeTimeLineDates(this.timeLineListSaved);
          }
          this.createTimeLineFormControls(this.timeLineListSaved);
          this.timeLineDataSource = new MatTableDataSource(this.tableRows.controls);
          this.cdr.detectChanges();
          this.isFormSetupComplete = true;
          this.tableRows.value.forEach((item: any, index: number) => {
            const getFormControl = (idx = index) => this.tableRows.at(idx);
            this.updateMinDate(getFormControl(), this.calculateMinDate(index));
          });
        },
        error: () => {
          this.isFormSetupComplete = true;
        }
      });
    }
  }

  /**
   * Checks the audit information form to determine which durations to use for timelines.
   *
   * @param timelines - timeline form array
   */
  changeTimelineDurations(timelines: TimeLine[]): TimeLine[] {
    const timelineDurations = this.timeLineService.getTimelineDurations(this.auditForm?.value.auditInfoForm);
    timelines.forEach((timeline, i) => {
      timeline.duration = timelineDurations[i];
    });
    return timelines;
  }

  handleValueChange(dateInputIndex?, checkboxClicked?) {
    if (this.isFormSetupComplete) {
      this.disableApproveSubmit = true;
      this.timelineWarning.emit(true);
    }
    for (let index = dateInputIndex; index < this.tableRows.value.length; index++) {
      const timeLine: TimeLine = this.tableRows.value[index];
      const getFormControl = (idx = index) => this.tableRows.at(idx);
      this.setDurationOnNAchange(timeLine, getFormControl, index);
      this.computeDateLogic(index, timeLine, getFormControl, dateInputIndex, checkboxClicked);
    }
    this.cdr.detectChanges();
  }

  onKeyDown(event: any) {
    if (event.key === 'Enter') {
      this.enableNotes = false;
    }
  }

  private setDurationOnNAchange(item: any, getFormControl: (idx?: any) => AbstractControl<any, any>, index: any) {
    if (item.notApplicable) {
      getFormControl().get('date')?.disable({ emitEvent: false });
      this.updateMinDate(getFormControl(index + 1), getFormControl().value.minDate);
    } else {
      getFormControl().get('date')?.enable({ emitEvent: false });
    }
  }

  private patchFormOnPricingChange() {
    this.tableRows?.value.forEach((timeLine: TimeLine, index: number, timeLines: TimeLine[]) => {
      const auditPricing = this.isAuditTypePricing && index === 2;

      this.tableRows.at(index).patchValue(
        {
          date: auditPricing ? undefined : timeLine.date,
          notApplicable: timeLine.notApplicable,
          duration: auditPricing ? 0 : timeLine.duration,
          minDate: index === 2 && this.isAuditTypePricing ?
            moment(timeLines[1].date).add(1, 'days').toDate() : this.calculateMinDate(index),
        },
        { emitEvent: false }
      );
    });
  }

  private updateDate(getFormControl: any, date: Date) {
    getFormControl?.patchValue(
      { date },
      { emitEvent: false }
    );
  }

  private updateMinDate(getFormControl: any, minDate: Date) {
    getFormControl?.patchValue(
      { minDate },
      { emitEvent: false }
    );
  }

  private isTimelineNew(timelines: TimeLine[]): boolean {
    let notInitialCall = true;

    if (timelines[0].date !== null && timelines[0].date !== '') {
      const savedDate = moment(new Date(timelines[0].date as string));
      if (savedDate.diff(moment(), 'days') < 13 && this.auditDetail?.status === 'Open') {
        timelines.forEach((timeline) => {
          timeline.date = '';
          timeline.notApplicable = false;
        });
      } else {
        notInitialCall = false;
        timelines.forEach((timeline) => {
          if (timeline?.date && timeline.date !== '') {
            timeline.date = new Date(timeline.date as string);
          }
        });
      }
    }

    timelines.forEach((timeline, index) => {
      for (const key in TIMELINES) {
        if (TIMELINES[key] === timeline.title) {
          this.enabledIndexes.push(index);
        }
      }
    });
    return notInitialCall;
  }

  private computeDateLogic(
    index: number,
    item: any,
    getFormControl: (idx?: number) => AbstractControl<any, any>,
    dateInputIndex: number,
    checkboxClicked: boolean
  ) {
    if (index > 0) {
      this.updateMinDate(getFormControl(), this.calculateMinDate(index));
    }
    if (item.duration !== 0 && getFormControl(index - 1).value.date !== '') {
      if (getFormControl(index - 1)?.value?.date) {
        this.updateDate(
          getFormControl(),
          this.addBusinessDays(moment(getFormControl(index - 1)?.value.date), item.duration).toDate()
        );
      } else {
        this.updateDate(
          getFormControl(),
          this.addBusinessDays(moment(getFormControl(index - 1)?.value.minDate), item.duration).toDate()
        );
      }
    }
  }

  private createTimeLineFormControls(timelines: TimeLine[]) {
    this.auditTimelineForm.controls['tableRows'] = this.fb.array([]);
    timelines.forEach((timeLine, index, timeLines) => {
      const auditPricing = this.isAuditTypePricing && index === 2;
      if (index === 2) {
        this.gerTimeLime = timeLine;
      }

      const timeLineForm = {
        auditId: new FormControl({ value: timeLine.auditId, disabled: false }),
        title: new FormControl({ value: timeLine.title, disabled: false }),
        date: timeLine.notApplicable ?
          new FormControl({ value: undefined, disabled: false }) :
          new FormControl({ value: timeLine.date === '' ?
              '' : moment(timeLine.date).toDate(), disabled: false }, [Validators.required]),
        notApplicable: new FormControl({
          value: auditPricing ? true : timeLine.notApplicable,
          disabled: !this.enabledIndexes.includes(index) || this.auditRequestMode === 'read'
        }),
        duration: new FormControl({ value: auditPricing || timeLine.notApplicable ? 0 : timeLine.duration, disabled: false }),
        responsibility: new FormControl({ value: timeLine.responsibility, disabled: false }),
        notesExists: new FormControl ({value:timeLine.notesExists , disabled: false}),
        timelineDetailId: new FormControl({ value: timeLine.timelineDetailId, disabled: false }),
        timelineId: new FormControl({ value: timeLine.timelineId, disabled: false }),
        minDate: new FormControl({ value: this.getMinDateForForm(index, timeLines), disabled: false }),
        dependentTimelineId: new FormControl({ value: timeLine.dependentTimelineId, disabled: false }),
      } as unknown as FormGroup;
        this.tableRows.push(this.fb.group(timeLineForm), {emitEvent: false});
    });

    this.auditTimelineForm.updateValueAndValidity();
    if(this.auditTimelineForm.valid || this.auditRequestMode === 'read') {
      this.completedAuditTimelineForm.emit(5);
      this.timelineWarning.emit(false);
    }
  }

  private getMinDateForForm(index: number, timeLines: TimeLine[]) {
    if (index === 2 && this.isAuditTypePricing) {
      return moment(timeLines[1].date).add(1, 'days').toDate();
    } else {
      return this.calculateMinDate(index);
    }
  }

  private calculateMinDate(index: number) {
    if (index > 0) {
      let tempDate;
      const minDaysToAdd =
        (this.isAuditTypePricing && index === 2) ||
        this.tableRows.at(index)?.get('notApplicable').value ||
        (!this.tableRows.at(index)?.get('notApplicable').value && this.tableRows.at(index)?.get('duration').value) ? 0 : 1;
      const depIndex = this.tableRows.at(index)?.get('dependentTimelineId')?.value-1;
      if (this.tableRows.at(depIndex)?.get('date').value instanceof Date) {
        tempDate = addBusinessDays(new Date(this.tableRows.at(depIndex)?.get('date').value), minDaysToAdd);
      } else {
        tempDate = addBusinessDays(new Date(this.tableRows.at(depIndex)?.get('minDate').value), minDaysToAdd);
      }
      return this.tableRows.at(index)?.get('notApplicable').value ?
        tempDate : addBusinessDays(tempDate, this.tableRows.at(index)?.get('duration').value);
    } else {
      return this.addBusinessDays(moment(), 10).toDate();
    }
  }

  private initializeTimeLineDates(timelines: TimeLine[]) {
      timelines.forEach((timeline, index) => {
        const currentDate = moment();
        if (index === 0) {
          timeline.date = this.addBusinessDays(currentDate, 10).toDate();
        } else if (index < 3) {
          timeline.date = this.addBusinessDays(moment(timelines[index - 1].date), timeline.duration).toDate();
        }
      });
  }

  saveAuditTimeLines() {
    let hasError = false;
    this.auditTimelineForm.updateValueAndValidity();
    if (!this.auditTimelineForm.valid) {
      this.auditTimelineForm.markAllAsTouched();
    } else {
      for (let i = 0; i < this.tableRows.length; i++) {
        const tl = this.auditTimelineForm.get(['tableRows', i]) as FormGroup;
        if ((tl.controls['date'].value === null || tl.controls['date'].value === '' || tl.controls['date'].value === undefined) &&
          (!tl.controls['notApplicable'].disabled && tl.controls['notApplicable'].value === false)) {
          tl.controls['date'].setErrors({ invalid: true });
          tl.controls['date'].markAsTouched();
          hasError = true;
        }
      }
      if (!hasError) {
        let timelineForm: Array<TimeLine> = this.tableRows.value;
        if(timelineForm[0].notApplicable) {
          timelineForm[0].date = this.addBusinessDays(moment(), 10).toDate();
        }
        if (this.isAuditTypePricing) {
          timelineForm[2].date = '';
          timelineForm[2].notApplicable = true;
        }
        timelineForm = timelineForm.map( (timeline: TimeLine) => {
          timeline.date = timeline?.date ? this.datePipe.transform((timeline?.date as Date), 'MM/dd/yyyy') : '';
          timeline.actualDate = timeline?.actualDate ? this.datePipe.transform((timeline?.actualDate as Date), 'MM/dd/yyyy') : '';
          timeline.revisedDate = timeline?.revisedDate ? this.datePipe.transform((timeline?.revisedDate as Date), 'MM/dd/yyyy') : '';
          return timeline;
        });
        this.timeLineService.saveUpdateAuditTimelineRequest(timelineForm, 'PROJECTED_TIMELINE')
          .subscribe({
            next: (timelineResponse: TimeLine[]) => {
              this.timeLineListSaved = timelineResponse;
              this.updateTimelineForm(timelineResponse);
              this.timelineWarning.emit(false);
              this.savedAuditTimelineForm.emit();
              this.disableApproveSubmit = false;
              this.wasSaveSuccessful = true;
              this.showSuccessNotification(
                'Your Audit Timelines were successfully saved!',
                'Successful Save',
                '#audit-timeline-alert-div',
                15000);
            },
            error: (error) => {
              this.showErrorNotification(
                'Please try again',
                'Unable to establish server connection',
                '#audit-timeline-alert-div',
                20000);
            }
          });
      }
    }
  }

  updateTimelineForm(timelineList: TimeLine[]) {
    this.tableRows?.controls?.forEach( (timeline: any) => {
      const filteredTimeline: TimeLine[] = timelineList?.filter( data => data.timelineId === timeline.get('timelineId')?.value);
      timeline?.get('notesExists')?.setValue(filteredTimeline?.length ? filteredTimeline[0].notesExists : false);
    });
  }

  addBusinessDays(startDate: moment.Moment, businessDaysToAdd: number): moment.Moment {
    let currentDate = startDate.clone();
    let addedBusinessDays = 0;

    while (addedBusinessDays < businessDaysToAdd) {
      currentDate = currentDate.add(1, 'day');
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        addedBusinessDays++;
      }
    }
    return currentDate;
  }

  getBusinessDayDiff(startDate: Date, endDate: Date): number {
    return differenceInBusinessDays(endDate, startDate);
  }

  getBusinessDaysWithHolidays(startDate: Date, endDate: Date): number {
    const totalDays = differenceInCalendarDays(startDate, endDate) + 1;
    const weekendDays = Math.floor(totalDays / 7) * 2;
    const holidays = Array.from({ length: totalDays }, (_, i) => new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000))
      .filter(day => isHoliday(day));
    return totalDays - weekendDays - holidays.length;
  }

  cancelAuditTimeLines() {
    this.bs.close();
    const timeLinesEdited: Array<TimeLine> = this.auditTimelineForm.value.tableRows;
    this.auditTimelineForm.controls['tableRows'] = this.fb.array([]);
    this.createTimeLineFormControls(this.timeLineListSaved);
    timeLinesEdited.forEach((timeLine, index) => {
      this.tableRows.at(index).get('notesExists').setValue(timeLine.notesExists, {emitEvent: false});
    });
    this.timeLineDataSource = new MatTableDataSource(this.tableRows.controls);
    this.tableRows.value.forEach((item: any, index: number) => {
      const getFormControl = (idx = index) => this.tableRows.at(idx);
      this.updateMinDate(getFormControl(), this.calculateMinDate(index));
    });
    this.timelineWarning.emit(false);
    if (this.wasSaveSuccessful) {
      this.disableApproveSubmit = false;
    }
  }

  get documentRows() {
    return this.auditForm.get('auditDocumentForm').get('documentRows') as FormArray;
  }

  notesModal(element: any, action: string): void {
    if (!this.enableNotes) {
      this.enableNotes = true;
      return;
    }
    const dialogData: NotesManagementModel = {
      auditId: element.value.auditId,
      notesType: NotesType.AUDIT_TIMELINE,
      category: NotesCategory.PROJECTED_TIMELINE,
      title: element?.value?.title,
      timelineDetails: element.value,
      isActualTimeline: false,
      modalType: action,
      isNotesExists: element.value.notesExists,
    };
    this.matDialog.open(NotesManagementComponent,
      {
        disableClose: true,
        data: dialogData
      }).afterClosed().subscribe((result: any) => {
      if(result?.isNotesExist){
        element.value.notesExists = true;
        this.tableRows.controls?.forEach( control => {
          if(control?.get('timelineId')?.value === result?.data?.timelineId) {
            control?.get('timelineDetailId')?.setValue(result?.data?.timelineDetailId);
            control?.get('notesExists')?.setValue(true);
          }
        });
        this.cdr.detectChanges();
      }
    });
  }

  validateAuditRequest() {
    this.auditForm.get('auditTimelineForm').updateValueAndValidity();
    if (this.auditForm.invalid) {
      this.bannerData = {
        bannerType: CVSBannerType.Error,
        outletId: AUDIT_TIMELINE.banner.alertDiv,
        template: this.overallSaveTemplate,
        viewContainerRef: this.viewContainerRef,
        removedAfterMilliseconds: 0
      };
      this.showCustomNotification(this.bannerData);
    } else {
      this.timeLineService.auditFormSubmit(this.auditDetail.recordId).subscribe(response => {
        if (response?.status?.length) {
          sessionStorage.setItem('submitSuccess', 'true');
          sessionStorage.setItem('recordId', this.auditDetail.recordId);
          this.auditDetails$ = this.dashboardService.getAuditDetailsByRecordId(this.amsRecId);
          if (this.auditForm.value.auditInfoForm.assignedAnalystEmail !== null) {
            this.sendResubmitNotification();
          } else {
          //  Send Submit Notification
            this.sendSubmitNotification();
          }
          this.router.navigate(['navigate-to-external']).then();
        } else {
          this.submitResponse = response;
          this.bannerData = {
            bannerType: CVSBannerType.Error,
            outletId: AUDIT_TIMELINE.banner.alertDiv,
            template: this.submitApiTemplate,
            viewContainerRef: this.viewContainerRef,
            removedAfterMilliseconds: 0
          };
          this.showCustomNotification(this.bannerData);
        }
      });
   }
  }
  get firmsVerificationNDAForm() {
    return (this.auditForm?.get('startUpProcessForm').get('firmsVerificationNDA') as FormGroup);
  }

  checkDateValidity(){
    const tableRows = this.auditForm?.get('auditTimelineForm').get('tableRows') as FormArray;
    for (let i = 0; i < tableRows.length; i++) {
      const tl = this.auditForm?.get('auditTimelineForm').get(['tableRows', i]) as FormGroup;
      if (this.timelineDate(tl)) {
        this.timelineDateInvalid = true;
      }
    }
  }

  timelineDate(tl: FormGroup) {
    return (tl.controls['date'].value === null || tl.controls['date'].value === '')
      && (!tl.controls['notApplicable'].disabled && tl.controls['notApplicable'].value === false);
  }


  // TODO: use in matDatepickerFilter directive on input field
  weekendDateFilter = (d: Date): boolean => {
    if(d) {
      const day = d.getDay();
      return day !== 0 && day !== 6;
    }
  };

  /**
   * Function to determine if the date entered is before the minDate on the timeline.
   *
   * @param formObj - form object of row
   */
  isDateBeforeMinDate(formObj) {
    if(moment(formObj?.date).diff(formObj?.minDate, 'days') < 0) {
      return true;
    }
    return false;
  }

  /**
   * Send email notification when Resubmit audit
   */
  sendResubmitNotification() {
    this.auditNotes$ = this.notesManagementService.getDetailNotes(this.auditDetail?.auditId);
    forkJoin([this.auditDetails$, this.auditNotes$])
      .subscribe((results) => {
          const headerText = 'Returned Audit has been resubmitted';
          const emailBody = this.notificationService
            .createEmailBodyForResubmittedAudit(this.auditForm.value, results[0], results[1]);
          const notificationRequest = this.notificationService.createNotificationRequest(
            headerText,
            emailBody,
            [this.auditForm.value.auditInfoForm.assignedAnalystEmail]);
          this.notificationService.sendNotification(notificationRequest).subscribe((res) => {
            const notificationHistoryRequest: NotificationHistoryRequestModel = {
              notificationRequest,
              notificationEvent: 'Returned Audit Resubmitted',
              recordId: this.auditDetail.recordId,
              notificationType: 'Real-time',
              currentUserEmail: sessionStorage.getItem('email')
            };
            this.notificationService.saveNotificationHistory(notificationHistoryRequest).subscribe((res) => {
                console.log('Notification sent/saved');
              },
              (notificationHistoryErr) => {
                console.error('Error saving notification history for resubmit audit', notificationHistoryErr);
              });
          }, (notificationErr) => {
            console.error('Error sending notification for resubmit audit', notificationErr);
          });
        },
        (err) => {
          console.error('Error getting audit details', err);
        });
  }

  /**
   * Send email notification when Submit audit
   */
  sendSubmitNotification() {
    this.auditDetails$.subscribe((auditDetail) => {
      const headerText = 'CVS Audit ' + auditDetail.recordId + ' has been submitted in the Audit Management System';
      const emailBody = this.notificationService.createEmailBodyForSubmitAudit(
        auditDetail, this.auditRoleResponsibility, this.timeLineListSaved);

      const emailAddresses = [
        auditDetail?.auditInformation?.createdById,
        auditDetail?.auditInformation?.assignedPrimaryContactEmail,
        auditDetail?.auditInformation?.generalContact].filter(email => email);

      const notificationRequest = this.notificationService.createNotificationRequest(headerText,emailBody,emailAddresses);
      this.notificationService.sendNotification(notificationRequest).subscribe((res) => {
        const notificationHistoryRequest: NotificationHistoryRequestModel = {
          notificationRequest,
          notificationEvent: 'Audit Submitted',
          recordId: this.auditDetail.recordId,
          notificationType: 'Real-time',
          currentUserEmail: sessionStorage.getItem('email')
        };
        this.notificationService.saveNotificationHistory(notificationHistoryRequest).subscribe((res) => {
            console.log('Notification sent/saved');
          },
          (notificationHistoryErr) => {
            console.error('Error saving notification history for audit submit', notificationHistoryErr);
          });
      }, (notificationErr) => {
        console.error('Error sending notification for audit submit', notificationErr);
      });
    });
  }

  protected readonly TIMELINES = TIMELINES;
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {debounceTime, Observable, of} from 'rxjs';
import {CVSBannerComponentData, CVSBannerType, PDSSelectionOption} from 'angular-component-library';
import {FormGroup, Validators} from '@angular/forms';
import {SearchOptionsData} from 'angular-component-library/completed/autocomplete-select/autocomplete-select.interface';
import {DatePipe} from '@angular/common';
import {ListValueModel} from '../../models/list-value.model';
import {BaseComponent} from '../../shared/components/base/base.component';
import {BannerService} from '../../shared/services/banner.service';
import * as AUDIT_INFO from './audit-information.constants';
import {AuditInformationService} from './audit-information.service';
import {AuditRequest} from '../audit-request.model';
import {AuditInformation} from './audit-information.model';
import {ClientDetailsModel} from '../../models/clientDetails.model';
import * as _ from 'underscore';
import * as CryptoJS from 'crypto-js';
import {Constants} from '../../constants/constants';
import {MatDialog} from '@angular/material/dialog';
import {TimeLine} from '../../models/time-line.model';
import {TimeLineService} from '../time-line/time-line.service';

@Component({
  selector: 'app-audit-information',
  templateUrl: './audit-information.component.html',
  styleUrls: ['./audit-information.component.scss']
})
export class AuditInformationComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() auditRequestMode!: string;
  @Input() auditInfoForm!: FormGroup;
  @Input() auditContactForm!: FormGroup;
  @Input() auditTimelineForm!: FormGroup;
  @Input() showBanner!: boolean;
  @Input() amsRecordId!: any;
  @Input() auditDetail!: any;
  @Input() firmList!: any;
  @Output() savedAuditInfoForm = new EventEmitter<any>();
  @Output() completedAuditInfoForm = new EventEmitter<number>();
  @Output() infoWarning = new EventEmitter<boolean>();
  @ViewChild('customSaveBannerTemplate') customSaveBannerTemplate: TemplateRef<any>;
  @ViewChild('projectedTimelineWarningTemplate') projectedTimelineWarningTemplate: TemplateRef<any>;

  protected readonly AUDIT_INFO = AUDIT_INFO;
  inputAppearance = 'standard';
  auditLobOneList: Observable<SearchOptionsData[]> = of([]);
  reviewDropDownList: Observable<SearchOptionsData[]> = of([]);
  auditLobOneListApi: SearchOptionsData[] = [];
  auditLobTwoList: PDSSelectionOption[] = [];
  isShow = false;
  showError = false;
  futureDate: Date = new Date();
  maxAuditStartDate: Date;
  maxAuditEndDate: Date;
  savedFormInfo: any;
  savedInfo: any;
  labelName = '';
  chsError ='';
  chsData: ClientDetailsModel[];
  mockSuperClientName = '';
  isCarrierIdSelected: boolean;
  isLoading = '';
  canCreateForPrimaryFirm = false;
  firmDropDownList: Observable<SearchOptionsData[]> = of([]);
  isFirmUser: boolean;
  userFirm = '';
  bannerData!: CVSBannerComponentData;
  userAuditData: any;
  showLoader = false;

  constructor(
    public auditInfoService: AuditInformationService,
    private datePipe: DatePipe,
    public bs: BannerService,
    public viewContainerRef: ViewContainerRef,
    private matDialog: MatDialog,
    private timelineService: TimeLineService
  ) {
    super(bs);
    this.futureDate.setDate(this.futureDate.getDate() - 1);
    this.maxAuditStartDate = this.futureDate;
    this.maxAuditEndDate = this.futureDate;
    this.isFirmUser = sessionStorage.getItem('isFirmUser') === 'true';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.firmList && (this.isFirmUser || this.auditRequestMode === 'review')) {
      this.userFirm = this.getFirmName(Number(sessionStorage.getItem('firmId')));
      const foundFirm =
        this.auditRequestMode === 'review' ?
          this.firmList.find((firm) => firm.firmId === (this.auditDetail?.auditInformation?.createdForPrimaryFirm ?
            this.auditDetail?.auditInformation?.createdByFirmId :
            this.auditDetail?.auditInformation?.firmId)) :
          this.firmList.find((firm) => firm.firmId.toString() === sessionStorage.getItem('firmId'));
      if(foundFirm) {
        this.canCreateForPrimaryFirm = foundFirm?.createForPrimaryFirm === 1;
        this.getPrimaryAuditFirmList();
      }
    }
    if(this.amsRecordId &&
      (this.auditRequestMode === 'edit' || this.auditRequestMode === 'read' || this.auditRequestMode === 'review')) {
      this.getAuditRequest();
    } else {
      this.initialFirmSetup();
    }
  }

  ngOnInit(): void {
    this.getLobOneList();
    this.getLobTwoList();
    this.auditInfoForm.valueChanges.subscribe(change => {
      if(this.savedFormInfo !== undefined && JSON.stringify(this.savedFormInfo) !== JSON.stringify(change)) {
        this.infoWarning.emit(true);
      }
    });
    if(this.auditRequestMode === AUDIT_INFO.modes.review) {
      this.getReviewDropdownLabel();
      this.auditInfoForm.get('reviewDropDown').valueChanges.pipe(debounceTime(500)).subscribe( value => {
        this.getChsDetails(value);
      });
      this.chsError = AUDIT_INFO.formValidationMessages.reviewDropDown.valueNotInList;
    }
  }

  getAuditRequest() {
    this.auditInfoService.getAuditRequest(this.amsRecordId).subscribe({
      next: (auditRequest: AuditRequest) => {
        this.auditDetail = auditRequest;
        this.setAuditInfoForm(this.auditDetail?.auditInformation);
        this.setFirmValidator(this.auditDetail?.auditInformation?.auditInitiationType);
        if(this.auditRequestMode === AUDIT_INFO.modes.read){
          this.auditInfoForm.disable();
        }
      }
    });
  }

  /** Get audit firm from session storage**/
  initialFirmSetup() {
      const firmData = this.isFirmUser ? 'Firm' : '';
      const initiationType = sessionStorage.getItem('isClientUser') === 'true' ? 'Client' : firmData;

      if(initiationType === AUDIT_INFO.auditInitiationType.firm) {
          const firm = sessionStorage.getItem('firmId') ?? null;
          const firmId = firm ? Number(firm) : null;
          this.auditInfoForm.get('firmId')?.setValue(firmId);
      } else if(initiationType === AUDIT_INFO.auditInitiationType.client) {
          const clientDetail = sessionStorage.getItem('clientDetail') ?? null;
          const clientData = clientDetail
            ? JSON.parse(CryptoJS.AES.decrypt(clientDetail, 'clientDetail').toString(CryptoJS.enc.Utf8))
            : null;
          this.setClientDetail(clientData);
      }
      this.setFirmValidator(initiationType);
  }

  setFirmValidator(initiationType: string) {
    this.auditInfoForm.get('auditInitiationType')?.setValue(initiationType);
    if (initiationType === AUDIT_INFO.auditInitiationType.firm) {
      this.auditInfoForm.get('firmId')?.setValidators(Validators.required);
      if(this.canCreateForPrimaryFirm) {
        this.auditInfoForm.get('createdForPrimaryFirm')?.setValidators(Validators.required);
      }
    }
    if (initiationType === AUDIT_INFO.auditInitiationType.client) {
      this.auditInfoForm.get('clientName')?.setValidators(Validators.required);
      this.auditInfoForm.get('clientId')?.setValidators(Validators.required);
      if(this.auditRequestMode !== AUDIT_INFO.modes.review) {
        this.auditInfoForm?.get('commonRequest')?.disable();
      }
    }
  }

  setClientDetail(clientData: any) {
    if(clientData) {
      this.auditInfoForm.get('clientId')?.setValue(clientData?.superClientCode);
      this.auditInfoForm.get('clientName')?.setValue(clientData?.superClientName);
      this.auditInfoForm.get('originalClientName')?.setValue(clientData?.superClientName);
      this.auditInfoForm.get('carrierId')?.setValue(clientData?.carrierId); // superClient and clientCode flow should be null
      this.auditInfoForm.get('carrierName')?.setValue(clientData?.carrierName);  // superClient and clientCode flow should be null
      this.auditInfoForm.get('accountId')?.setValue(clientData?.accountId);
      this.auditInfoForm.get('accountName')?.setValue(clientData?.accountName);
      this.auditInfoForm?.get('commonRequest')?.setValue('single');
      this.auditInfoForm?.get('coalition')?.setValue('false');
      this.auditInfoForm?.get('multipleCarrier')?.setValue(clientData?.carrierId ? 'false' : 'true');
    } else {
      // setTimeout(() =>
        this.showErrorNotification(
          'Client Details are not found!',
          'Error',
          '#audit-info-alert-div',
          20000);
        // 3000);
    }
  }

  getPrimaryAuditFirmList() {
    const firmListValue: SearchOptionsData[] = [];
    const userFirmId = this.auditRequestMode === AUDIT_INFO.modes.review ?
      this.auditDetail.auditInformation.createdByFirmId?.toString() : sessionStorage.getItem('firmId');
    this.firmList.forEach((map) => {
      const firmIdString = map.firmId?.toString();
      if(firmIdString !== userFirmId) {
        firmListValue.push({formattedSearchResult: map.firmId?.toString(), altSearchParam: map.firmName});
      }
    });
    this.firmDropDownList = of(firmListValue);
  }

  setPrimaryAuditFirmValue(value: any) {
    let primaryAuditFirmFilterValue = [];
    if(this.firmDropDownList) {
      this.firmDropDownList.subscribe(firmList => {
        primaryAuditFirmFilterValue = firmList.filter(f => f.altSearchParam === value);
        if(primaryAuditFirmFilterValue?.length > 0) {
          this.auditInfoForm.get('primaryAuditFirm')?.setValue(primaryAuditFirmFilterValue[0]);
        } else {
          this.auditInfoForm.get('primaryAuditFirm')?.setValue(primaryAuditFirmFilterValue);
        }
      });
    }
  }

  /** Get LOB1 list from Json**/
  getLobOneList() {
    const lobOneListValue: SearchOptionsData[] = [];
    this.auditInfoService.getLobOneListFromApi().subscribe((listOfLobOne: ListValueModel[]) => {
      const sortedListOfLobOne =_.sortBy(listOfLobOne, 'value');
      sortedListOfLobOne.forEach((map: ListValueModel) => {
        lobOneListValue.push({formattedSearchResult: map.id?.toString(), altSearchParam: map.value});
      });
      this.auditLobOneListApi = lobOneListValue;
      this.setAuditLobOneValue(this.auditInfoForm.get('auditLobOneId')?.value);
      this.auditLobOneList = of(lobOneListValue);
    });
  }

  setAuditLobOneValue(value: any) {
    value = value && !value.hasOwnProperty('disabled') ? value.toString() : value;
    const lobOneFilterValue = this.auditLobOneListApi?.filter( (f: any) => f.formattedSearchResult === value);
    const lobOneId = lobOneFilterValue && lobOneFilterValue?.length === 1 ? lobOneFilterValue[0] : value;
    this.auditInfoForm.get('auditLobOneId')?.setValue(lobOneId || '');
  }

  /** Get LOB2 list from Json**/
  getLobTwoList() {
    this.auditInfoService.getLobTwoListFromApi().subscribe({
      next: (listOfLobTwo: ListValueModel[]) => {
        const sortedListOfLobTwo =_.sortBy(listOfLobTwo, 'value');
        sortedListOfLobTwo.forEach((map: ListValueModel) => {
          this.auditLobTwoList.push({value: map.id?.toString(), displayText: map.value});
        });
        this.isShow = true;
      },
      error: () => {
        this.showErrorNotification(
          'Please try again',
          'Unable to establish server connection',
          '#audit-info-alert-div',
          20000);
      }
    });

  }

  setAuditInfoForm(auditInfo: AuditInformation) {
    const coalition = auditInfo?.coalition === true ? AUDIT_INFO.commonRequest.coalition
      : AUDIT_INFO.commonRequest.single;
    const commonRequest = (this.auditRequestMode === AUDIT_INFO.modes.review && auditInfo?.multipleCarrier === true)
      ? AUDIT_INFO.commonRequest.multiple : coalition;
    if(auditInfo?.createdForPrimaryFirm !== null) {
      this.auditInfoForm?.get('createdForPrimaryFirm')?.setValue(auditInfo?.createdForPrimaryFirm === true ? 'true' : 'false');
      if(auditInfo?.createdForPrimaryFirm) {
        this.auditInfoForm.get('primaryAuditFirm')?.setValidators(Validators.required);
      }
    }
      this.auditInfoForm?.get('createdByFirmId')?.setValue(this.getNullCheckValue(auditInfo?.createdByFirmId));
      this.auditInfoForm?.get('commonRequest')?.setValue(commonRequest);
      this.auditInfoForm?.get('coalition')?.setValue(
        (auditInfo?.coalition === true || auditInfo?.coalition === false) ? auditInfo?.coalition?.toString() : '');
      this.auditInfoForm?.get('multipleCarrier')?.setValue(auditInfo?.multipleCarrier === true ? 'true' : 'false');
      this.auditInfoForm?.get('originalClientName')?.setValue(this.getNullCheckValue(auditInfo?.originalClientName));
      this.auditInfoForm?.get('clientId')?.setValue(this.getNullCheckValue(auditInfo?.clientId));
      this.auditInfoForm?.get('clientName')?.setValue(this.getNullCheckValue(auditInfo?.clientName));
      this.auditInfoForm?.get('accountId')?.setValue(this.getNullCheckValue(auditInfo?.accountId));
      this.auditInfoForm?.get('accountName')?.setValue(this.getNullCheckValue(auditInfo?.accountName));
      this.auditInfoForm?.get('carrierId')?.setValue(this.getNullCheckValue(auditInfo?.carrierId));
      this.auditInfoForm?.get('carrierName')?.setValue(this.getNullCheckValue(auditInfo?.carrierName));
      this.auditInfoForm?.get('firmId')?.setValue(this.getNullCheckValue(auditInfo?.firmId));
      this.auditInfoForm?.get('firmName')?.setValue(this.getNullCheckValue(auditInfo?.firmName));
      this.auditInfoForm?.get('auditInitiationType')?.setValue(this.getNullCheckValue(auditInfo?.auditInitiationType));
      this.auditInfoForm?.get('auditLobTwoList')?.setValue(
          auditInfo?.auditLobTwoList?.length
              ? auditInfo?.auditLobTwoList.map((m: any) => {return m.toString();})
              : null);
      this.auditInfoForm?.get('auditScopeStartDate')?.setValue(
          auditInfo?.auditScopeStartDate ? new Date(auditInfo?.auditScopeStartDate) : null);
      this.auditInfoForm?.get('auditScopeEndDate')?.setValue(auditInfo?.auditScopeEndDate ? new Date(auditInfo?.auditScopeEndDate) : null);
      this.auditInfoForm?.get('assignedAnalystEmail')?.setValue(this.getNullCheckValue(auditInfo?.assignedAnalystEmail));
      this.setAuditLobOneValue(auditInfo?.auditLobOneId);
      this.setPrimaryAuditFirmValue(auditInfo?.firmName);
      if(this.auditRequestMode === AUDIT_INFO.modes.review){
       this.setReviewDropDown(auditInfo, commonRequest);
      }
      if(this.auditInfoForm.status === 'VALID' || this.auditInfoForm.status === 'DISABLED') {
        this.completedAuditInfoForm.emit(0);
        this.infoWarning.emit(false);
        this.savedFormInfo = this.auditInfoForm.value;
      }
    this.savedInfo = auditInfo;
  }

  getNullCheckValue(data: any) {
    return data ?? null;
  }


  /** Reset coalition/client name field**/
  resetClientName() {
    if(this.auditInfoForm.get('auditInitiationType')?.value === AUDIT_INFO.auditInitiationType.firm
    && this.auditRequestMode !== AUDIT_INFO.modes.review) {
      this.auditInfoForm.get('originalClientName')?.reset();
    }
   this.auditRequestSelection();
 }

 setPrimaryAuditFirmValidator(event) {
   if(event.value === 'true') {
     this.auditInfoForm.get('primaryAuditFirm')?.setValidators(Validators.required);
   } else {
     this.auditInfoForm.get('primaryAuditFirm')?.removeValidators(Validators.required);
     this.auditInfoForm.get('primaryAuditFirm')?.reset();
     this.auditInfoForm.updateValueAndValidity();
   }
 }

  saveOrUpdateAuditRequest() {
    this.showError = true;
    this.userAuditData = this.auditInfoForm.getRawValue();
    this.userAuditData = this.auditLobOneValidation(this.userAuditData);
    if(this.auditInfoForm.get('createdForPrimaryFirm').value === 'true') {
      this.userAuditData = this.primaryAuditFirmValidation(this.userAuditData);
    }
    if(this.auditRequestMode === AUDIT_INFO.modes.review) {
     this.updateReviewClientDetails(this.userAuditData);
    }
    if(this.auditInfoForm.valid) {
      if (this.savedInfo && (this.auditRequestMode === AUDIT_INFO.modes.edit || this.auditRequestMode === AUDIT_INFO.modes.review)) {
        const userEnteredLobTwoList = this.userAuditData.auditLobTwoList;
        const savedLobTwoList = this.savedInfo.auditLobTwoList;

        const userLobTwoSorted = userEnteredLobTwoList.slice().sort();
        const noLOBChange = savedLobTwoList.length === userEnteredLobTwoList.length &&
          savedLobTwoList.slice().sort().every(function(lobTwo, i) {
            return lobTwo === +userLobTwoSorted[i];
          });
        const noScopeDateChange =
          (this.datePipe.transform(this.userAuditData.auditScopeStartDate, 'MM/dd/yyyy') === this.savedInfo.auditScopeStartDate &&
          this.datePipe.transform(this.userAuditData.auditScopeEndDate, 'MM/dd/yyyy') === this.savedInfo.auditScopeEndDate);
        if (this.auditTimelineForm?.value.tableRows.length > 0 && (!noLOBChange || !noScopeDateChange)) {
          this.matDialog.open(this.projectedTimelineWarningTemplate, {
            disableClose: true
          });
        } else {
          this.setupUserAuditDataForSave();
          this.auditRequestApiCall(this.userAuditData);
        }
      } else {
        this.setupUserAuditDataForSave();
        this.auditRequestApiCall(this.userAuditData);
      }
    } else {
      this.showErrorNotification(
        'Resolve the missing data and press Save in order to proceed',
        AUDIT_INFO.errorMessages.validationTitle,
        '#audit-info-alert-div',
        20000);
      this.auditInfoForm.get('coalition')?.markAsTouched();
    }
  }

  /**
   * Gets userAuditData and sets necessary properties for the auditRequestApiCall()
   */
  setupUserAuditDataForSave() {
    if (this.isFirmUser || this.userAuditData.createdForPrimaryFirm !== null) {
      this.userAuditData.createdForPrimaryFirm = this.userAuditData.createdForPrimaryFirm !== null ?
        this.userAuditData.createdForPrimaryFirm === 'true' : null;
      if (this.userAuditData.createdForPrimaryFirm) {
        this.userAuditData.createdByFirmId = Number(sessionStorage.getItem('firmId'));
        this.userAuditData.firmId = Number(this.userAuditData.primaryAuditFirm.formattedSearchResult);
        this.userAuditData.firmName = this.userAuditData.primaryAuditFirm.altSearchParam;
      } else {
        if (this.userAuditData.createdByFirmId !== null) {
          this.userAuditData.firmId = this.userAuditData.createdByFirmId;
        }
        this.userAuditData.createdByFirmId = null;
        this.userAuditData.firmName = this.getFirmName(this.userAuditData.firmId);
      }
    }
    this.userAuditData.coalition = (this.userAuditData.coalition === 'true');
    this.userAuditData.auditScopeStartDate = this.datePipe.transform(this.userAuditData.auditScopeStartDate, 'MM/dd/yyyy');
    this.userAuditData.auditScopeEndDate = this.datePipe.transform(this.userAuditData.auditScopeEndDate, 'MM/dd/yyyy');
    this.userAuditData.multipleCarrier = (this.userAuditData.multipleCarrier === 'true');
  }

  /**
   * Function for cancelling timeline warning modal
   */
  cancelTimelineWarning() {
    this.matDialog.closeAll();
  }

  /**
   * Function to change current audit general timeline durations and reset the values so the
   * user has to enter them again. Will also save the audit information page when complete.
   *
   * @param timelines - timeline form array of current audit's timelines
   */
  changeTimelineDurations(timelines: TimeLine[]) {
    this.showLoader = true;
    const timelineDurations = this.timelineService.getTimelineDurations(this.auditInfoForm.value);
    timelines.forEach((timeline, i) => {
      timeline.date = null;
      timeline.notApplicable = false;
      timeline.duration = timelineDurations[i];
    });
    this.timelineService.saveUpdateAuditTimelineRequest(timelines, 'PROJECTED_TIMELINE').subscribe(
      res => {
        this.setupUserAuditDataForSave();
        this.auditRequestApiCall(this.userAuditData, true);
      }, (err) => {
        console.error('Error saving timelines', err);
        this.showLoader = false;
        this.matDialog.closeAll();
      }
    );
  }

  /**
   * Function for confirming timeline warning modal on Audit Review
   */
  closeModalAndSaveInfo() {
    this.showLoader = true;
    this.setupUserAuditDataForSave();
    this.auditRequestApiCall(this.userAuditData);
  }

  auditLobOneValidation(auditInfoData: any) {
    if(auditInfoData.auditLobOneId?.hasOwnProperty('disabled') || !auditInfoData.auditLobOneId?.hasOwnProperty('formattedSearchResult') ) {
   const filteredLobOne = this.auditLobOneListApi.filter(data => data?.altSearchParam?.toLowerCase()
        === auditInfoData?.auditLobOneId?.toString()?.toLowerCase()).map(map => {
           return Number(map?.formattedSearchResult);
          });
    if(filteredLobOne?.length) {
      auditInfoData.auditLobOneId = filteredLobOne[0];
    } else {
      this.auditInfoForm.get('auditLobOneId')?.setValue('');
    }
    } else if(auditInfoData.auditLobOneId?.hasOwnProperty('formattedSearchResult')) {
      auditInfoData.auditLobOneId = auditInfoData.auditLobOneId?.formattedSearchResult
        ? Number(auditInfoData.auditLobOneId?.formattedSearchResult) : null;
    }
    return auditInfoData;
  }

  primaryAuditFirmValidation(auditInfoData: any) {
    if(auditInfoData.primaryAuditFirm?.hasOwnProperty('disabled') ||
      !auditInfoData.primaryAuditFirm?.hasOwnProperty('formattedSearchResult')) {
      this.firmDropDownList.subscribe(firmList => {
        const findPrimaryAuditFirm = firmList.filter(data =>
          data?.altSearchParam.toLowerCase() === auditInfoData?.primaryAuditFirm.toLowerCase());
        if(findPrimaryAuditFirm.length > 0) {
          auditInfoData.primaryAuditFirm = findPrimaryAuditFirm[0];
        } else {
          this.auditInfoForm.get('primaryAuditFirm')?.setValue(null);
        }
      });
    }
    return auditInfoData;
  }

  auditRequestApiCall(auditInfoData: AuditInformation, changeTimelines?: boolean) {
    const auditRequestData: AuditRequest =  {
      auditId: this.auditDetail?.auditId,
      recordId: this.auditDetail?.recordId,
      status: this.auditDetail?.status,
      mode: this.auditRequestMode,
      auditInformation: auditInfoData
    };
    this.auditInfoService.saveAuditRequestDetails(auditRequestData, this.auditDetail?.recordId).subscribe({
      next: (auditRequest: AuditRequest) => {
        if(auditRequest?.recordId) {
          setTimeout(()=> {
            this.bannerData = {
              bannerType: CVSBannerType.Success,
              outletId: '#audit-info-alert-div',
              template: this.customSaveBannerTemplate,
              viewContainerRef: this.viewContainerRef,
              removedAfterMilliseconds: 15000
            };
            this.showCustomNotification(this.bannerData);
          }, 500);
          this.savedAuditInfoForm.emit({auditDetail: auditRequest, changeTimelines});
          this.infoWarning.emit(false);
          this.savedInfo = auditInfoData;
          this.matDialog.closeAll();
          this.showLoader = false;
        }
      }
    });
  }

  getFirmName(firmId: any) {
    const firm = this.firmList.filter( (f: any) => f.firmId === firmId);
    return firm?.length > 0 ? firm[0].firmName : '';
  }

  onInput(event: any, formControlName: any) {
    const inputValue = event.target.value;
    const regex = /^[0-9/]*$/;
    if(!regex.test(inputValue)){
      formControlName.setErrors({alpha: true});
    }
  }

   auditRequestSelection() {
    switch (this.auditInfoForm.get('commonRequest')?.value){
      case AUDIT_INFO.commonRequest.coalition:
        this.auditInfoForm?.get('coalition')?.setValue('true');
        this.auditInfoForm?.get('multipleCarrier')?.setValue('false');
        break;
      case AUDIT_INFO.commonRequest.single:
        this.auditInfoForm?.get('coalition')?.setValue('false');
        this.auditInfoForm?.get('multipleCarrier')?.setValue('false');
        break;
      case AUDIT_INFO.commonRequest.multiple:
            this.auditInfoForm?.get('coalition')?.setValue('false');
        return this.auditInfoForm?.get('multipleCarrier')?.setValue('true');

      default:
        return;
    }
  }
   getReviewDropdownLabel() {
    this.auditInfoForm.get('commonRequest').valueChanges.subscribe(value => {
      this.reviewDropDownList = of([]);
      this.mockSuperClientName = this.auditInfoForm?.get('reviewDropDown')?.value
        ? this.mockSuperClientName : null;
      this.auditInfoForm.get('reviewDropDown').reset('');
      switch (value) {
        case AUDIT_INFO.commonRequest.coalition:
          this.labelName = AUDIT_INFO.label.coalitionNameLabel;
          break;
        case AUDIT_INFO.commonRequest.single:
          this.labelName = AUDIT_INFO.label.platformID;
          break;
        case AUDIT_INFO.commonRequest.multiple:
          this.labelName = AUDIT_INFO.label.multipleCarrierLabel;
          break;
        default:
          this.labelName = '';
          break;
      }
    });
  }

   getChsDetails(value: any) {
      if( value?.length >= 3) {
        this.auditInfoService.getClientDetails(value, this.auditInfoForm?.get('commonRequest')?.value).subscribe({
          next: (data: ClientDetailsModel[]) => {
            this.chsData = data;
            this.mockSuperClientName = '';
            this.chsError = '';
            this.isLoading = '';
            const reviewDropDownListValue: SearchOptionsData[] = [];
            data.forEach((map) => {
              let selectedDropDown: any;
              if(this.auditInfoForm?.get('commonRequest')?.value !== AUDIT_INFO.commonRequest.single) {
                selectedDropDown = {
                  formattedSearchResult:map?.superClientCode,
                  altSearchParam: map?.superClientCode?.concat(' - ')?.concat(map?.superClientName)
                };
              } else if(this.auditInfoForm?.get('commonRequest')?.value === AUDIT_INFO.commonRequest.single){
                selectedDropDown = {
                  formattedSearchResult: map?.carrierId,
                  altSearchParam: map?.carrierId?.concat(' - ')?.concat(map?.accountName)
                };
                this.mockSuperClientName = map?.superClientCode?.concat(' - ')?.concat(map?.superClientName);
              }
              if(selectedDropDown){
                reviewDropDownListValue.push(selectedDropDown);
              }
            });
            this.reviewDropDownList = of(reviewDropDownListValue);
          },
          error: (error: any) => {
            this.chsError = error.error.message;
            this.mockSuperClientName = '';
            this.isLoading = '';
          }
        });
      }
  }

  updateReviewClientDetails(auditInfoData: any) {
    if(auditInfoData.reviewDropDown?.hasOwnProperty('disabled')
      || (auditInfoData.reviewDropDown?.hasOwnProperty('formattedSearchResult')
      && !auditInfoData.reviewDropDown.formattedSearchResult)) {
      this.resetClientDetails(auditInfoData);
    } else if(auditInfoData.reviewDropDown?.hasOwnProperty('formattedSearchResult')
      && auditInfoData.reviewDropDown.formattedSearchResult) {
      this.setReviewClientDetails(auditInfoData);
    }
    return auditInfoData;
  }

  resetClientDetails(auditInfoData: any){
    this.auditInfoForm?.get('reviewDropDown')?.setValue('');
    if(auditInfoData?.commonRequest !== AUDIT_INFO.commonRequest.single) {
      this.auditInfoForm?.get('clientId')?.setValue('');
      this.auditInfoForm?.get('clientName')?.setValue('');
    } else {
      this.auditInfoForm?.get('carrierId')?.setValue('');
      this.auditInfoForm?.get('carrierName')?.setValue('');
    }
  }

  setReviewClientDetails(auditInfoData: any) {
    this.setAccountDetails(auditInfoData);
    if(auditInfoData?.commonRequest !== AUDIT_INFO.commonRequest.single) {
      auditInfoData.clientId = auditInfoData?.reviewDropDown?.formattedSearchResult
        ? auditInfoData?.reviewDropDown.formattedSearchResult : null;
      auditInfoData.clientName = auditInfoData?.reviewDropDown?.altSearchParam
        ? this.getConvertedSuperClient(auditInfoData?.reviewDropDown?.altSearchParam) : null;
    } else {
      auditInfoData.carrierId = auditInfoData?.reviewDropDown?.formattedSearchResult
        ? auditInfoData?.reviewDropDown?.formattedSearchResult : null;
      auditInfoData.carrierName = auditInfoData?.reviewDropDown?.altSearchParam
        ?  this.getConvertedCarrier(auditInfoData?.reviewDropDown?.altSearchParam) : null;
    }
  }


  getConvertedSuperClient(altSearchParam: string) {
   return this.chsData.filter(data => data.superClientCode === altSearchParam.split(' - ')[0])
     .map(data => data.superClientName)[0];
  }
  getConvertedCarrier(altSearchParam: string) {
    return this.chsData.filter(data => data.carrierId === altSearchParam.split(' - ')[0])
      .map(data => data.carrierName)[0];
  }

   setReviewDropDown(auditInfo: AuditInformation, commonRequest: string) {
    const savedRequest = {formattedSearchResult:'',altSearchParam:''};
    if(this.auditRequestMode === AUDIT_INFO.modes.review && commonRequest !== AUDIT_INFO.commonRequest.single){
      savedRequest.formattedSearchResult = auditInfo?.clientId;
      savedRequest.altSearchParam = auditInfo?.clientId?.concat(' - ')?.concat(auditInfo?.clientName);
      this.auditInfoForm?.get('reviewDropDown')?.setValue(savedRequest);
      this.reviewDropDownList = of([savedRequest]);
    } else {
      savedRequest.formattedSearchResult = auditInfo?.carrierId;
      savedRequest.altSearchParam = auditInfo?.carrierId?.concat(' - ')?.concat(auditInfo?.accountName);
      this.auditInfoForm?.get('reviewDropDown')?.setValue(savedRequest);
      this.mockSuperClientName = auditInfo?.clientId?.concat(' - ')?.concat(auditInfo?.clientName);
      this.isCarrierIdSelected = !!this.mockSuperClientName;
      this.reviewDropDownList = of([savedRequest]);
    }

    this.chsData = [{
      accountId: auditInfo?.accountId,
      accountName: auditInfo.accountName,
      carrierId: auditInfo?.carrierId,
      carrierName: auditInfo?.carrierName,
      superClientCode: auditInfo?.clientId,
      superClientName: auditInfo?.clientName,
      formattedClientName: null,
    }];
  }


  getClientName() {
    return this.auditInfoForm.get('carrierName')?.value
        ? this.auditInfoForm.get('accountName')?.value
        : this.auditInfoForm.get('clientName')?.value;
  }


  setAccountDetails(auditInfoData: any) {
    if(auditInfoData?.commonRequest !== AUDIT_INFO.commonRequest.single){
      const result = this.chsData.filter(data => data.superClientCode
        === auditInfoData?.reviewDropDown?.formattedSearchResult);
       auditInfoData.accountId = result[0].accountId;
       auditInfoData.accountName = result[0].accountName;
       auditInfoData.carrierId = null;
       auditInfoData.carrierName = null;
    } else {
      const result = this.chsData.filter(data => data.carrierId
        === auditInfoData?.reviewDropDown?.formattedSearchResult);
      auditInfoData.clientId = result[0].superClientCode;
      auditInfoData.clientName = result[0].superClientName;
      auditInfoData.accountId = result[0].accountId;
      auditInfoData.accountName = result[0].accountName;
    }
    return auditInfoData;
  }

  resetDropDown() {
    this.reviewDropDownList = of([]);
  }

  onCarrierSelection(event: any){
   this.isCarrierIdSelected =  !!event?.option?.value;
  }

  getPrimaryAuditFirmValue() {
    if(this.auditInfoForm?.get('createdByFirmId')?.value !== null &&
      this.auditInfoForm?.get('createdForPrimaryFirm').value === 'true' &&
      this.auditInfoForm?.get('firmId').value !== this.auditInfoForm?.get('createdByFirmId').value) {
      return {
        formattedSearchResult: this.auditInfoForm?.get('firmId')?.value,
        altSearchParam: this.auditInfoForm?.get('firmName')?.value
      };
    }
    return null;
  }

  isFirmValid() {
    const auditFirmValue = this.auditInfoForm.get('primaryAuditFirm').value;
    if(auditFirmValue) {
      if(typeof auditFirmValue === 'object' && auditFirmValue !== null) {
        return true;
      } else {
        this.firmDropDownList.subscribe(firmList => {
          const firm = firmList.filter(f => f.altSearchParam.toLowerCase() === auditFirmValue.toLowerCase());
          if(firm.length > 0) {
            this.auditInfoForm.controls['primaryAuditFirm'].setValue(firm[0]);
            return true;
          } else {
            return false;
          }
        });
      }
    }
  }

  setLoading() {
    this.isLoading = 'Loading...';
  }

  checkSubcontractorAudit() {
    if(!this.canCreateForPrimaryFirm ||
      (this.canCreateForPrimaryFirm &&
      ((this.auditInfoForm?.get('createdForPrimaryFirm')?.value === 'true' && this.isFirmValid()) ||
      (this.auditInfoForm?.get('createdForPrimaryFirm')?.value === 'false')))) {
      return true;
    }
    else {
      return false;
    }
  }

  checkAuditInformation() {
    if(this.auditInfoForm?.get('commonRequest')?.value !== null ||
      this.auditInfoForm?.get('originalClientName')?.value !== null ||
      this.auditInfoForm?.get('auditLobOneId')?.value.disabled !== false ||
      this.auditInfoForm?.get('auditLobTwoList')?.value.length !== 0 ||
      this.auditInfoForm?.get('auditScopeStartDate')?.value !== null ||
      this.auditInfoForm?.get('auditScopeEndDate')?.value !== null) {
      return true;
    }
    else {
      return false;
    }
  }

  disableSubcontractorFields() {
    if (this.auditContactForm.get('primaryAuditContact').value.length > 0 ||
      this.auditContactForm.get('auditSubcontractorOne').value.length > 0) {
      return true;
    }
    return false;
  }

  /**
   * Resets the user-interactable Audit Information form fields back to their
   * blank values.
   */
  resetAuditInfo() {
    this.auditInfoForm.get('coalition')?.setValue(null);
    this.auditInfoForm.get('commonRequest')?.setValue(null);
    this.auditInfoForm.get('multipleCarrier')?.setValue(null);
    this.auditInfoForm.get('originalClientName')?.setValue(null);
    this.auditInfoForm.get('auditLobOneId')?.setValue({disabled: false});
    this.auditInfoForm.get('auditLobTwoList')?.setValue([]);
    this.auditInfoForm.get('auditScopeStartDate')?.setValue(null);
    this.auditInfoForm.get('auditScopeEndDate')?.setValue(null);
    this.auditInfoForm.get('createdForPrimaryFirm')?.setValue(null);
    this.auditInfoForm.get('primaryAuditFirm')?.setValue(null);
    this.auditInfoForm.get('reviewDropDown')?.setValue({
      formattedSearchResult: null
    });
  }

  // TODO find a way to set reviewDropDown without an error showing
  /**
   * Handles the Cancel button functionality for the Audit Information form.
   * Resets the values of all the user-interactable form fields, then sets
   * the form back to what was in the savedInfo variable,
   * then checks the validity of the form.
   */
  cancelAuditInfo() {
    window.location.reload();
    // this.resetAuditInfo();
    // if (this.savedInfo === undefined) {
    // } else {
    //   this.setAuditInfoForm(this.savedInfo);
    // }
    // if (this.auditInfoForm.valid) {
    //   this.auditInfoForm.markAsPristine();
    //   this.completedAuditInfoForm.emit(0);
    //   this.infoWarning.emit(false);
    // }
  }
}

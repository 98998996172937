import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {TimeLine} from '../../../models/time-line.model';
import {TimeLineService} from '../time-line.service';
import {MatTableDataSource} from '@angular/material/table';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {BannerService} from '../../../shared/services/banner.service';
import {AuditRequest} from '../../audit-request.model';
import {
  CVSBannerComponentData,
  CVSBannerType,
  CVSConfirmationDialogContentComponent,
  CVSConfirmationDialogData
} from 'angular-component-library';
import * as AUDIT_TIMELINE from '../time-line.constants';
import {Router} from '@angular/router';
import {addBusinessDays, differenceInBusinessDays, differenceInCalendarDays} from 'date-fns';
import {isHoliday} from 'date-fns-holiday-us';
import {AuditSubmitModel} from '../AuditSubmitModel';
import {UserService} from 'src/app/services/user.service';
import {Constants} from 'src/app/constants/constants';
import * as AUDIT_INFO from '../../audit-information/audit-information.constants';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, take} from 'rxjs';
import {clone} from 'ramda';
import {isEqual} from 'underscore';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {AuditRequestUtil} from '../../../utilities/audit-request.util';
import {AuditInformationService} from '../../audit-information/audit-information.service';
import {AuditContactDetailModel} from '../../audit-contact/audit-contact.model';
import {NotesManagementComponent} from '../../../shared/components/notes-management/notes-management.component';
import {DatePipe} from '@angular/common';
import {NotesCategory, NotesType} from '../../../shared/components/notes-management/notes-management.constants';
import {NotesManagementModel} from '../../../shared/components/notes-management/notes-management.model';
import {NotesManagementService} from '../../../shared/components/notes-management/notes-management.service';
import {NotificationService} from '../../../services/notification.service';
import {NotificationHistoryRequestModel} from '../../../models/notification-history-request.model';

enum TIMELINES {
  KICK_OFF_DATE = 'Timeline External kick Off Date',
  DELIVERABLE_DUE_DATE = 'Timeline Initial Deliverable Due Date',
  REPORTING_DUE_DATE = 'Timeline GER Reporting Due Date',
  QUESTIONS_DUE_DATE = 'Timeline Follow Up Questions Due Date',
  QUESTIONS_RESPONSE_DATE = 'Timeline Follow Up Questions Response Due Date',
  START_DATE = 'Timeline Onsite Start Date',
  RECEIVED_DUE_DATE = 'Timeline Sample Received Due Date',
  RESPONSE_DUE_DATE = 'Timeline Sample Response Due Date',
  RESOLVED_DUE_DATE = 'Timeline All Sample Follow Up Resolved Due Date',
  DRAFT_RECEIVED_DUE_DATE = 'Timeline Draft Report Received Due Date',
  DRAFT_RESPONSE_DUE_DATE = 'Timeline Draft Report Response Due Date',
  FINAL_RECEIVED_DUE_DATE = 'Timeline Final Report Received Due Date',
  FINAL_RESPONSE_DUE_DATE = 'Timeline Final Report Response Due Date',
  COMPLETION_DATE = 'Timeline Expected Audit Completion Date'
}
@Component({
  selector: 'app-review-time-line',
  templateUrl: './review-time-line.component.html',
  styleUrls: ['./review-time-line.component.scss']
})
export class ReviewTimeLineComponent  extends BaseComponent implements OnInit, OnChanges {
  @Input() auditTimelineForm!: FormGroup;
  @Input() showBanner!: boolean;
  @Input() getTimelines!: boolean;
  @Input() auditDetail!: AuditRequest;
  @Input() isAuditTypePricing = false;
  @Input() amsRecId: string;
  @Input() auditContactDetail!: AuditContactDetailModel;
  @Output() savedAuditTimelineForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() completedAuditTimelineForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() timelineWarning = new EventEmitter<boolean>();
  @Output()  dateInput: EventEmitter<MatDatepickerInputEvent<any>>;
  @Output() clientAuditCaseUpdate = new EventEmitter<any>();
  @Output() auditStatusUpdate = new EventEmitter<any>();

  @Input() auditForm!: FormGroup;
  @ViewChild('overallSaveTemplate') overallSaveTemplate: TemplateRef<any>;
  @ViewChild('submitApiTemplate') submitApiTemplate: TemplateRef<any>;
  @ViewChild('approveAuditModal') approveAuditModalRef!: TemplateRef<any>;
  protected readonly AUDIT_TIMELINE = AUDIT_TIMELINE;
  protected readonly Constants = Constants;
  protected readonly AUDIT_INFO = AUDIT_INFO;
  timeLineListSaved: TimeLine[] = [];
  timeLineDifferences: number[] = [];
  timeLineReview: TimeLine[] = [];
  timeLineDataSource!: MatTableDataSource<any>;
  enabledIndexes = [];
  isFormSetupComplete = false;
  savedArray: any[] = [];
  bannerData!: CVSBannerComponentData;
  submitResponse: AuditSubmitModel;
  disableApproveSubmit = true;
  wasSaveSuccessful = false;
  externalUser!: boolean;
  confirmationDialog: any;
  auditReviewForm!: FormGroup;
  timelineDateInvalid = false;
  isAuditApproved = false;
  reviewType = '';
  gerTimeLime!: TimeLine;
  isAnalystOrAdmin = false;
  deleteNotesExtUser!: boolean;
  externalAdmin!: boolean;
  isClientAuditCase: boolean;
  clientOrCoalitionLabelName!: string;
  originalClientName!: string;
  clientName!: string;
  showLoader = false;
  viewerEmailList: string[] = [];
  disableForCancelAuditor: boolean;
  disableForReturnAuditor: boolean;
  menuColumns=['projectedTimeline', 'notApplicable', 'generalTimeline', 'responsibility', 'notes'];
  lastSubmitDate: Date;
  initSubmitDate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  enableNotes = true;

  constructor(
    private fb: FormBuilder,
    private timeLineService: TimeLineService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    public bs: BannerService,
    private cdr: ChangeDetectorRef,
    public userService: UserService,
    private matDialog: MatDialog,
    private auditInfoService: AuditInformationService,
    private datePipe: DatePipe,
    private notesManagementService: NotesManagementService,
    private notificationService: NotificationService
  ) {
    super(bs);
    this.auditTimelineForm = this.fb.group({
      tableRows: this.fb.array([]),
    });

  }

  get tableRows() {
    return this.auditTimelineForm.controls['tableRows'] as FormArray;
  }

  ngOnInit(): void {
    this.auditReviewForm = this.fb.group({
      auditReviewNotes: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(500)]],
    });
    this.externalUser = this.userService.isExternalUser();
    this.externalAdmin = this.userService.isExternalAdmin();
    this.isAnalystOrAdmin = this.userService.isAuditAnalyst() || this.userService.isAuditAdmin();
    this.deleteNotesExtUser = !this.userService.isExternalAdmin() && !this.userService.isAuditAnalyst() && !this.userService.isAuditAdmin();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isClientAuditCase = !!this.auditDetail?.clientAuditRecordId;
    this.disableForCancelAuditor = AuditRequestUtil.getStatusOfAuditForCancelAuditor(this.auditDetail?.status);
    this.disableForReturnAuditor = AuditRequestUtil.getStatusOfAuditForReturnAuditor(this.auditDetail?.status);
    if(this.isFormSetupComplete === false &&
      (changes.getTimelines?.currentValue === true || this.auditDetail !== null) &&
      changes.isAuditTypePricing?.currentValue !== undefined) {
      this.initTimelines();
    }

    if (changes.auditTimelineForm?.currentValue) {
      this.auditTimelineForm = changes.auditTimelineForm.currentValue;
    }

    if (changes.isAuditTypePricing?.currentValue !== undefined) {
        if (!changes.isAuditTypePricing?.currentValue) {
          this.tableRows.at(2)?.patchValue({...this.gerTimeLime, duration: 0}, { emitEvent: false });
          this.tableRows.at(2)?.get('notApplicable').enable({ emitEvent: false });
          this.tableRows.at(2)?.get('date').enable({ emitEvent: false });
        } else {
          this.tableRows.at(2)?.get('notApplicable').disable({ emitEvent: false });
        }
        this.patchFormOnPricingChange();
    }
  }

  initTimelines(): void {
    if(this.auditDetail !== undefined) {
      this.timeLineService.getAll(this.auditDetail?.auditId).subscribe(timelines => {
        this.timeLineListSaved = timelines;
        this.timelineSetup(this.timeLineListSaved);
        this.createTimeLineFormControls(this.timeLineListSaved);
        this.calculateTimelineDifferences();
        this.timeLineDataSource = new MatTableDataSource(this.tableRows.controls);
        this.cdr.detectChanges();
        this.isFormSetupComplete = true;
        this.initSubmitDate$.subscribe(submitDateInit => {
          if(submitDateInit) {
            this.tableRows.value.forEach((item: any, index: number) => {
              const getFormControl = (idx = index) => this.tableRows.at(idx);
              this.updateMinDate(getFormControl(), this.calculateMinDate(index));
            });
          }
        });
      }, error => {});
    }
  }

  /**
   * Calculates the differences between the timelines and puts them in the timelineDifferences[]
   * component variable.
   */
  calculateTimelineDifferences() {
    this.timeLineDifferences = [];
    this.timeLineListSaved?.forEach((timeLine: TimeLine, index: number) => {
      if(timeLine.responsibility === 'CVS Health') {
        this.timeLineDifferences[index] = timeLine.duration;
      } else {
        if(!this.timeLineListSaved[timeLine.dependentTimelineId-1].notApplicable &&
          this.timeLineListSaved[timeLine.dependentTimelineId-1].date !== undefined &&
          this.timeLineListSaved[timeLine.dependentTimelineId-1].date !== '') {
          this.timeLineDifferences[index] = this.getDifferenceInBusinessDays(
            moment(this.timeLineListSaved[timeLine.dependentTimelineId-1].date),
            moment(timeLine.date));
        }else if (timeLine.dependentTimelineId > 0 && this.timeLineListSaved[timeLine.dependentTimelineId-1].notApplicable) {
          this.timeLineDifferences[index] = this.getDifferenceInBusinessDays(
            moment(this.timeLineListSaved[this.findDependentIndex(index)].date),
            moment(timeLine.date));
        }
        else if(this.timeLineListSaved[timeLine.dependentTimelineId-1].date === undefined ||
          this.timeLineListSaved[timeLine.dependentTimelineId-1].date === '') {
          this.timeLineDifferences[index] = 0;
        }
      }
    });
  }

  /**
   * Event function for when a date is picked or a checkbox is clicked.
   * Loops through subsequent timelines and alters the minDate and date values.
   *
   * @param event - Event object passed in from listener
   * @param dateInputIndex - Index of the timeline row that was changed
   */
  handleValueChange(event: any, dateInputIndex) {
    let checkboxFlag = event.checked;
    if (event.checked !== undefined) {
      checkboxFlag = event.checked;
    }
    if (this.isFormSetupComplete) {
      this.disableApproveSubmit = true;
      this.timelineWarning.emit(true);
    }
    const depIndex = this.findDependentIndex(dateInputIndex);
    const depTimeline = this.tableRows.at(depIndex);
    // When timeline changed is GER or Onsite, don't calculate any changes
    if(dateInputIndex === 2 || dateInputIndex === 5) {
      if(checkboxFlag !== undefined && checkboxFlag === false) {
        const getFormControl = (idx = dateInputIndex) => this.tableRows.at(idx);
        this.updateDate(getFormControl(), null);
      }
      return;
    }
    let useLastSubmitDate = false;
    if (depTimeline.get('notApplicable').value === true &&
      depTimeline.get('timelineId').value === 1) {
      useLastSubmitDate = true;
    }
    for (let index = dateInputIndex; index < this.tableRows.value.length; index++) {
      const timeLine: TimeLine = this.tableRows.value[index];
      const getFormControl = (idx = index) => this.tableRows.at(idx);
      this.setDurationOnNAchange(timeLine, getFormControl, index);
      this.computeDateLogic(index, getFormControl, dateInputIndex, checkboxFlag, useLastSubmitDate);
    }
    this.cdr.detectChanges();
  }

  onKeyDown(event: any) {
    if (event.key === 'Enter') {
      this.enableNotes = false;
    }
  }

  private setDurationOnNAchange(item: any, getFormControl: (idx?: any) => AbstractControl<any, any>, index: any) {
    if (item.notApplicable) {
      getFormControl().get('duration')?.patchValue(0, { emitEvent: false });
      getFormControl().get('date')?.disable({ emitEvent: false });
    } else {
      const auditPricing = this.isAuditTypePricing && index === 2;
      getFormControl().get('duration')?.patchValue(
        auditPricing ? 0 : this.timeLineReview[index].duration,
        { emitEvent: false }
      );
      getFormControl().get('date')?.enable({ emitEvent: false });
    }
  }

  private patchFormOnPricingChange() {
    this.tableRows?.value.forEach((timeLine: TimeLine, index: number, timeLines: TimeLine[]) => {
      const auditPricing = this.isAuditTypePricing && index === 2;
      if (index === 2) {
        this.timeLineReview[index].duration = auditPricing ? 0 : timeLine.duration;
      }

      this.tableRows.at(index).patchValue(
        {
          date: auditPricing ? undefined : timeLine.date,
          notApplicable: timeLine.notApplicable,
          duration: auditPricing ? 0 : timeLine.duration,
          minDate: index === 2 && this.isAuditTypePricing ?
            moment(timeLines[1].date).add(1, 'days').toDate() : this.calculateMinDate(index),
        },
        { emitEvent: false }
      );
    });
  }

  private updateDate(getFormControl: any, date: Date) {
    getFormControl?.patchValue(
      { date },
      { emitEvent: false }
    );
  }

  private updateMinDate(getFormControl: any, minDate: Date) {
    getFormControl?.patchValue(
      { minDate },
      { emitEvent: false }
    );
  }

  private timelineSetup(timelines: TimeLine[]) {
    this.timeLineListSaved = timelines;
    this.timeLineReview = clone(timelines);
    this.timeLineReview[2].duration = this.isAuditTypePricing ? 0 : timelines[2].duration;
    timelines.forEach((timeline) => {
      if (timeline?.date && timeline.date !== '') {
        timeline.date = new Date(timeline.date as string);
      }
    });

    timelines.forEach((timeline, index) => {
      for (const key in TIMELINES) {
        if (TIMELINES[key] === timeline.title) {
          this.enabledIndexes.push(index);
        }
      }
    });

    this.notesManagementService.getDetailNotes(this.auditDetail?.auditId).subscribe(detailNotes => {
      const submitNotes = detailNotes.filter(note => note.keyAction === 'Submit');
      this.lastSubmitDate = submitNotes.reduce((prev, curr) =>
        (prev && prev.detailNotesId > curr.detailNotesId) ? prev : curr).createdDatetime;
      this.initSubmitDate$.next(true);
    });
  }

  /**
   * Finds the dependent index of the provided index.
   * Goes through the timeline of each dependent index until it finds a
   * !notApplicable timeline, then returns the index of that timeline
   *
   * @param index - initial index we use to parse the dependency tree
   */
  findDependentIndex(index: number) {
    const timeline: TimeLine = this.tableRows.value[index];
    let dependentIndex = timeline.dependentTimelineId - 1;
    let dependentFound = false;
    while(!dependentFound && dependentIndex > 0) {
      const dependentTimeline: TimeLine = this.tableRows.value[dependentIndex];
      if(dependentTimeline.notApplicable) {
        dependentIndex = dependentTimeline.dependentTimelineId - 1;
      } else if(dependentIndex === 0) {
        dependentFound = true;
      } else {
        dependentFound = true;
      }
    }
    return dependentIndex;
  }

  private computeDateLogic(
    index: number,
    getFormControl: (idx?: number) => AbstractControl<any, any>,
    dateInputIndex: number,
    checkboxFlag: boolean,
    useLastSubmitDate: boolean
  ) {
    // When timeline is GER or Onsite, make no changes
    if (index === 2 || index === 5) { return; }
    // When index is changed timeline and checkbox is unchecked
    if(index === dateInputIndex && checkboxFlag !== undefined && checkboxFlag === false) {
      this.updateDate(getFormControl(), null);
    }
    // When index is after changed timeline
    else if (index > dateInputIndex &&
      (checkboxFlag === undefined || (checkboxFlag !== undefined && checkboxFlag === true))) {
      const depDate = this.findDependentIndex(index);
      this.updateDate(
        getFormControl(),
        useLastSubmitDate && this.isFirstTimeline(index) ?
          this.addBusinessDays(moment(this.lastSubmitDate), 10 + this.timeLineDifferences[index]).toDate() :
          this.addBusinessDays(moment(this.tableRows.at(depDate)?.get('date').value),
            this.timeLineDifferences[index]).toDate());
    }
  }

  /**
   * Function to determine if timeline at this index is the first timeline
   * (nothing it depends on because it is N/A or because it is just the first timeline).
   *
   * @param index - index of the timeline to check
   */
  isFirstTimeline(index: number): boolean {
    let isFirst = false;
    for (let i = 0; i < this.tableRows.value.length; i++) {
      const timeline: TimeLine = this.tableRows.value[i];
      if(!timeline.notApplicable && timeline.date !== '') {
        if(index === i) {
          isFirst = true;
        }
        break;
      }
    }
    return isFirst;
  }

  private createTimeLineFormControls(timelines: TimeLine[]) {
    this.auditTimelineForm.controls['tableRows'] = this.fb.array([]);
    timelines.forEach((timeLine, index, timeLines) => {
      const auditPricing = this.isAuditTypePricing && index === 2;
      if (index === 2) {
        this.gerTimeLime = timeLine;
      }

      const timeLineForm = {
        auditId: new FormControl({ value: timeLine.auditId, disabled: false }),
        title: new FormControl({ value: timeLine.title, disabled: false }),
        date: auditPricing || timeLine.notApplicable ? new FormControl({ value: undefined, disabled: false }) :
          new FormControl({ value: moment(timeLine.date).toDate(), disabled: false }, [Validators.required]),
        notApplicable: new FormControl({
          value: auditPricing ? true : timeLine.notApplicable,
          disabled: auditPricing ? true : !this.enabledIndexes.includes(index)
        }),
        duration: new FormControl({ value: auditPricing || timeLine.notApplicable ? 0 : timeLine.duration, disabled: false }),
        responsibility: new FormControl({ value: timeLine.responsibility, disabled: false }),
        notesExists: new FormControl ({value:timeLine.notesExists , disabled: false}),
        timelineDetailId: new FormControl({ value: timeLine.timelineDetailId, disabled: false }),
        timelineId: new FormControl({ value: timeLine.timelineId, disabled: false }),
        minDate: new FormControl({ value: this.getMinDateForForm(index, timeLines), disabled: false }),
        dependentTimelineId: new FormControl({ value: timeLine.dependentTimelineId, disabled: false })
      } as unknown as FormGroup;
      this.tableRows.push(this.fb.group(timeLineForm), {emitEvent: false});
    });

    this.auditTimelineForm.updateValueAndValidity();
    if(this.auditTimelineForm.valid) {
      this.completedAuditTimelineForm.emit(6);
      this.timelineWarning.emit(false);
    }
  }

  private getMinDateForForm(index: number, timeLines: TimeLine[]) {
    if (index === 2 && this.isAuditTypePricing) {
      return moment(timeLines[1].date).add(1, 'days').toDate();
    } else if (index === 0) {
      return new Date();
    } else {
      return this.calculateMinDate(index);
    }
  }

  private calculateMinDate(index: number) {
    if (index > 0) {
      let tempDate;
      const minDaysToAdd =
        (this.isAuditTypePricing && index === 2) ||
        this.tableRows.at(index)?.get('notApplicable').value ||
        (!this.tableRows.at(index)?.get('notApplicable').value && this.tableRows.at(index)?.get('duration').value) ? 0 : 1;
      const depIndex = this.tableRows.at(index)?.get('dependentTimelineId').value-1;
      if (this.tableRows.at(depIndex)?.get('date').value instanceof Date) {
        tempDate = addBusinessDays(new Date(this.tableRows.at(depIndex)?.get('date').value), minDaysToAdd);
      } else {
        tempDate = addBusinessDays(new Date(this.tableRows.at(depIndex)?.get('minDate').value), minDaysToAdd);
      }
      return this.tableRows.at(index)?.get('notApplicable').value ?
        tempDate : addBusinessDays(tempDate, this.tableRows.at(index)?.get('duration').value);
    } else {
      return this.addBusinessDays(moment(this.lastSubmitDate), 10).toDate();
    }
  }

  /**
   * Function to calculate minDate for index after a date change event occurs.
   *
   * @param index - Index of timeline row to calculate minDate for
   */
  minDateChange(index: number) {
    if (index > 0) {
      const depIndex = this.findDependentIndex(index);
      const depDate = this.tableRows.at(depIndex).get('date').value;
      const depTimeline = this.tableRows.at(depIndex);
      if(depTimeline.get('notApplicable').value === true && depTimeline.get('timelineId').value === 1) {
        return this.addBusinessDays(moment(this.lastSubmitDate), 10 + this.tableRows.at(index).get('duration').value).toDate();
      }
      if (this.tableRows.at(index).get('duration').value > 0) {
        return this.addBusinessDays(moment(depDate), this.tableRows.at(index).get('duration').value).toDate();
      } else {
        if (this.tableRows.at(index).get('notApplicable').value) {
          return this.addBusinessDays(moment(depDate), 0).toDate();
        } else {
          return this.addBusinessDays(moment(depDate), 1).toDate();
        }
      }
    } else {
      return this.addBusinessDays(moment(this.lastSubmitDate), 10).toDate();
    }
  }

  saveAuditTimeLines() {
    let hasError = false;
    this.auditTimelineForm.updateValueAndValidity();
    if (!this.auditTimelineForm.valid) {
      this.auditTimelineForm.markAllAsTouched();
    } else {
      for (let i = 0; i < this.tableRows.length; i++) {
        const tl = this.auditTimelineForm.get(['tableRows', i]) as FormGroup;
        if ((tl.controls['date'].value === null || tl.controls['date'].value === '' || tl.controls['date'].value === undefined) &&
          (!tl.controls['notApplicable'].disabled && tl.controls['notApplicable'].value === false)) {
          tl.controls['date'].setErrors({ invalid: true });
          tl.controls['date'].markAsTouched();
          hasError = true;
        }
      }
      if (!hasError) {
        let timelineForm: Array<TimeLine> = this.auditTimelineForm.value.tableRows;
        if (this.isAuditTypePricing) {
          timelineForm[2].date = '';
          timelineForm[2].notApplicable = true;
        }
        timelineForm = timelineForm.map( (timeline: TimeLine) => {
          timeline.date = timeline?.date ? this.datePipe.transform((timeline?.date as Date), 'MM/dd/yyyy') : '';
          timeline.revisedDate = timeline?.revisedDate ? this.datePipe.transform((timeline?.revisedDate as Date), 'MM/dd/yyyy') : '';
          timeline.actualDate = timeline?.actualDate ? this.datePipe.transform((timeline?.actualDate as Date), 'MM/dd/yyyy') : '';
          return timeline;
        });
        this.timeLineService.saveUpdateAuditTimelineRequest(timelineForm, 'PROJECTED_TIMELINE')
          .subscribe({
            next: (timelineResponse: TimeLine[]) => {
              this.timeLineListSaved = timelineForm;
              this.calculateTimelineDifferences();
              this.updateTimelineForm(timelineResponse);
              this.timelineWarning.emit(false);
              this.savedAuditTimelineForm.emit();
              this.disableApproveSubmit = false;
              this.wasSaveSuccessful = true;
                this.showSuccessNotification(
                  'Your Audit Timelines were successfully saved!',
                  'Successful Save',
                  '#audit-timeline-alert-div',
                  15000);
            },
            error: (error) => {
              this.showErrorNotification(
                'Please try again',
                'Unable to establish server connection',
                '#audit-timeline-alert-div',
                20000);
            }
          });
      }
    }
  }

  updateTimelineForm(timelineList: TimeLine[]) {
    this.tableRows?.controls?.forEach( (timeline: any) => {
      const filteredTimeline: TimeLine[] = timelineList?.filter( data => data.timelineId === timeline.get('timelineId')?.value);
      timeline?.get('notesExists')?.setValue(filteredTimeline?.length ? filteredTimeline[0].notesExists : false);
    });
  }

  /**
   * Adds/subtracts business days based on the start date (can have negative business days)
   *
   * @param startDate - Date to make the change to
   * @param businessDaysToAdd - Business days needed to be added/subtracted from startDate
   */
  addBusinessDays(startDate: moment.Moment, businessDaysToAdd: number): moment.Moment {
    let currentDate = startDate.clone();
    let addedBusinessDays = 0;
    if (businessDaysToAdd > 0) {
      while (addedBusinessDays < businessDaysToAdd) {
        currentDate = currentDate.add(1, 'day');
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          addedBusinessDays++;
        }
      }
    } else {
      while (addedBusinessDays > businessDaysToAdd) {
        currentDate = currentDate.subtract(1, 'day');
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          addedBusinessDays--;
        }
      }
    }
    return currentDate;
  }

  /**
   * Get business day difference between two dates, can return negative days if startDate
   * is after endDate.
   *
   * @param startDate - date value to start from
   * @param endDate - date value to end on
   */
  getDifferenceInBusinessDays(startDate: moment.Moment, endDate: moment.Moment) {
    let currentDate = startDate.clone();
    let addedBusinessDays = 0;
    if (startDate.isBefore(endDate)) {
      while (endDate.diff(currentDate, 'days') > 0) {
       if (currentDate.day() !== 0 && currentDate.day() !== 6) {
         addedBusinessDays++;
       }
       currentDate = currentDate.add(1, 'day');
      }
    } else {
      while (currentDate.diff(endDate, 'days') > 0) {
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          addedBusinessDays--;
        }
        currentDate = currentDate.subtract(1, 'day');
      }
    }
    return addedBusinessDays;
  }

  cancelAuditTimeLines() {
    this.bs.close();
    const timeLinesEdited: Array<TimeLine> = this.auditTimelineForm.value.tableRows;
    this.auditTimelineForm.controls['tableRows'] = this.fb.array([]);
    this.createTimeLineFormControls(this.timeLineListSaved);
    timeLinesEdited.forEach((timeLine, index) => {
      this.tableRows.at(index).get('notesExists').setValue(timeLine.notesExists, {emitEvent: false});
    });
    this.timeLineDataSource = new MatTableDataSource(this.tableRows.controls);
    this.timelineWarning.emit(false);
    this.completedAuditTimelineForm.emit(6);
    if (this.wasSaveSuccessful) {
      this.disableApproveSubmit = false;
    }
  }

  get documentRows() {
    return this.auditForm.get('auditDocumentForm').get('documentRows') as FormArray;
  }

  notesModal(element: any, action: string): void {
    if (!this.enableNotes) {
      this.enableNotes = true;
      return;
    }
    const dialogData: NotesManagementModel = {
      auditId: element.value.auditId,
      notesType: NotesType.AUDIT_TIMELINE,
      category: NotesCategory.PROJECTED_TIMELINE,
      title: element?.value?.title,
      timelineDetails: element.value,
      isActualTimeline: false,
      modalType: action,
      isNotesExists: element.value.notesExists,
    };
    this.matDialog.open(NotesManagementComponent,
      {
        disableClose: true,
        data: dialogData
      }).afterClosed().subscribe((result: any) => {
      if(result?.isNotesExist){
        element.value.notesExists = true;
        this.tableRows.controls?.forEach( control => {
          if(control?.get('timelineId')?.value === result?.data?.timelineId) {
            control?.get('timelineDetailId')?.setValue(result?.data?.timelineDetailId);
            control?.get('notesExists')?.setValue(true);
          }
        });
        this.cdr.detectChanges();
      }
    });
  }

  validateAuditRequest() {
    if (this.auditForm.invalid) {
      this.bannerData = {
        bannerType: CVSBannerType.Error,
        outletId: AUDIT_TIMELINE.banner.alertDiv,
        template: this.overallSaveTemplate,
        viewContainerRef: this.viewContainerRef,
        removedAfterMilliseconds: 0
      };
      this.showCustomNotification(this.bannerData);
    } else {
      this.timeLineService.auditFormSubmit(this.auditDetail.recordId).subscribe(response => {
        if (response?.status?.length) {
          sessionStorage.setItem('submitSuccess', 'true');
          sessionStorage.setItem('recordId',this.auditDetail.recordId);
          this.router.navigate(['navigate-to-external']).then();
        } else {
          this.submitResponse = response;
          this.bannerData = {
            bannerType: CVSBannerType.Error,
            outletId: AUDIT_TIMELINE.banner.alertDiv,
            template: this.submitApiTemplate,
            viewContainerRef: this.viewContainerRef,
            removedAfterMilliseconds: 0
          };
          this.showCustomNotification(this.bannerData);
        }
      });
    }
  }

  auditReviewAction(event) {
    this.matDialog.closeAll();
    if(event?.mode !== 'cancel') {
      this.auditInfoService.getAuditRequest(this.auditDetail.recordId).subscribe({
        next: (auditRequest: AuditRequest) => {
          this.auditDetail = auditRequest;
          let emailAddresses = [];
          let headerText;
          let emailBody;
          let notifEvent;
          if(event.mode === 'CANCELAUDIT') {
            emailAddresses = this.notificationService.getAllEmailContacts(
              this.auditContactDetail,
              this.auditDetail.auditInformation.createdById);
            headerText = 'CVS Audit ' + this.auditDetail.recordId + ' has been cancelled in the Audit Management System';
            emailBody = this.notificationService.createEmailBodyForCancelledAudit(
              this.auditForm.value, this.auditDetail, event.notes);
            notifEvent = 'Audit Cancelled';
          } else if ( event.mode === 'RETURNAUDIT'){
            emailAddresses.push(this.auditDetail.auditInformation.createdById);
            const primaryAuditContact = this.auditContactDetail.primaryAuditContact.filter(contact => contact.isPrimaryContact === true)[0];
            if(emailAddresses.indexOf(primaryAuditContact.contactEmail) === -1) {
              emailAddresses.push(primaryAuditContact.contactEmail);
            }
            const firmEmail = this.auditContactDetail.generalMail;
            if(firmEmail !== null && emailAddresses.indexOf(firmEmail) === -1) {
              emailAddresses.push(firmEmail);
            }
            headerText = 'CVS Audit ' + this.auditDetail.recordId + ' has been returned in the CVS Audit Management System';
            emailBody = this.notificationService.createEmailBodyForReturnAudit(
              this.auditForm.value, this.auditDetail, event.notes);
            notifEvent = 'Audit Returned';
          }
          const notificationRequest = this.notificationService.createNotificationRequest(
            headerText, emailBody, emailAddresses);
          this.notificationService.sendNotification(notificationRequest).subscribe((res) => {
            const notificationHistoryRequest: NotificationHistoryRequestModel = {
              notificationRequest,
              notificationEvent: notifEvent,
              recordId: this.auditDetail.recordId,
              notificationType: 'Real-time',
              currentUserEmail: sessionStorage.getItem('email')
            };
            this.notificationService.saveNotificationHistory(notificationHistoryRequest).subscribe((res) => {
                console.log('Notification sent/saved');
              },
              (notificationHistoryErr) => {
                console.error('Error saving notification history', notificationHistoryErr);
              });
          }, (notificationErr) => {
            console.error('Error sending notification', notificationErr);
          });
        }
      });

      this.router.navigate(['/internal']).then();
    }
  }

  auditReviewModalOpen(templateRef: TemplateRef<any>, reviewType: string) {
    this.reviewType = reviewType;
    this.viewerEmailList = AuditRequestUtil.getNotesViewerEmailList(this.auditDetail, this.auditContactDetail);
    this.matDialog.open(templateRef, {
      disableClose: true
    });
  }

  approveAudit() {
    this.auditReviewForm = this.fb.group({
      auditReviewNotes: [{ value: '', disabled: false }, [ Validators.maxLength(500)]],
    });
    const dialogData = {
      headline: 'Approve Audit',
      templatePortalContent: this.approveAuditModalRef,
    } as CVSConfirmationDialogData;

    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      disableClose: true,
      data: dialogData
    });
  }

  get firmsVerificationNDAForm() {
    return (this.auditForm?.get('startUpProcessForm').get('firmsVerificationNDA') as FormGroup);
  }

  checkDateValidity(){
    const tableRows = this.auditForm?.get('auditTimelineForm').get('tableRows') as FormArray;
    for (let i = 0; i < tableRows.length; i++) {
      const tl = this.auditForm?.get('auditTimelineForm').get(['tableRows', i]) as FormGroup;
      if (this.timelineDate(tl)) {
        this.timelineDateInvalid = true;
      }
    }
  }

  timelineDate(tl: FormGroup) {
    return ((tl.controls['date'].value === null || tl.controls['date'].value === '')
      && (!tl.controls['notApplicable'].disabled && tl.controls['notApplicable'].value === false));
  }
  approveAuditOnSave() {
    if(this.auditForm.invalid) {
      this.confirmationDialog.close();
      this.checkDateValidity();
      this.bannerData = {
        bannerType: CVSBannerType.Error,
        outletId: AUDIT_TIMELINE.banner.alertDiv,
        template: this.overallSaveTemplate,
        viewContainerRef: this.viewContainerRef,
        removedAfterMilliseconds: 0
      };
      this.showCustomNotification(this.bannerData);
    } else {
      this.showLoader = true;
      this.timeLineService.approveAudit(this.auditDetail.recordId, this.auditReviewForm?.value?.auditReviewNotes)
        .pipe(take(1))
        .subscribe({
          next: (response: AuditSubmitModel) => {
            if (response?.status?.length) {
              this.confirmationDialog.close();
              this.showSuccessNotification(AUDIT_TIMELINE.successMessages.approve,'Success',AUDIT_TIMELINE.banner.alertDiv);
              this.isAuditApproved = true;
              this.isClientAuditCase = !!response?.clientAuditRecordId;
              this.clientAuditCaseUpdate.emit({isClientAuditCase: this.isClientAuditCase});
              this.auditStatusUpdate.emit({status: response?.status});
              this.auditInfoService.getAuditRequest(this.auditDetail.recordId).subscribe({
                next: (auditRequest: AuditRequest) => {
                  this.auditDetail = auditRequest;
                  const emailAddresses = this.notificationService.getAllEmailContacts(
                    this.auditContactDetail,
                    this.auditDetail.auditInformation.createdById);
                  const headerText = 'CVS Audit ' + this.auditDetail.recordId + ' has been approved in the Audit Management System';
                  const emailBody = this.notificationService.createEmailBodyForApprovedAudit(
                    this.auditForm.value,
                    this.auditDetail,
                    this.auditReviewForm?.value?.auditReviewNotes
                  );
                  const notificationRequest = this.notificationService.createNotificationRequest(
                    headerText, emailBody, emailAddresses);
                  this.notificationService.sendNotification(notificationRequest).subscribe((res) => {
                    const notificationHistoryRequest: NotificationHistoryRequestModel = {
                      notificationRequest,
                      notificationEvent: 'Audit Approved',
                      recordId: this.auditDetail.recordId,
                      notificationType: 'Real-time',
                      currentUserEmail: sessionStorage.getItem('email')
                    };
                    this.notificationService.saveNotificationHistory(notificationHistoryRequest).subscribe((res) => {
                        console.log('Notification sent/saved');
                      },
                      (notificationHistoryErr) => {
                        console.error('Error saving notification history', notificationHistoryErr);
                      });
                  }, (notificationErr) => {
                    console.error('Error sending notification', notificationErr);
                  });
                }
              });
            } else {
              this.confirmationDialog.close();
              this.submitResponse = response;
              this.bannerData = {
                bannerType: CVSBannerType.Error,
                outletId: AUDIT_TIMELINE.banner.alertDiv,
                template: this.submitApiTemplate,
                viewContainerRef: this.viewContainerRef,
                removedAfterMilliseconds: 0
              };
              this.showCustomNotification(this.bannerData);
            }
            this.showLoader = false;
          },
          error: (error) => {
            // TODO Change this to some error state
            this.confirmationDialog.close();
            this.showErrorNotification(
              'Please try again',
              'Unable to establish server connection',
              '#audit-timeline-alert-div',
              20000);
            this.showLoader = false;
          }
        });
    }
  }

  getAuditRequestDetails() {
    if(this.amsRecId) {
      this.auditInfoService.getAuditRequest(this.amsRecId).subscribe({
        next: (auditRequest: AuditRequest) => {
          this.auditDetail = auditRequest;
          this.clientOrCoalitionLabelName = AuditRequestUtil.getClientOrCoalitionLabel(this.auditDetail?.auditInformation?.coalition);
          this.clientName = AuditRequestUtil.getClientOrAccountName(this.auditDetail);
          this.originalClientName = this.auditDetail?.auditInformation?.originalClientName;
        },
        error: (err: any) => {
          if (err?.error?.code === '403') {
            this.auditInfoService.backClicked();
          }
        }
      });
    }
  }

  /**
   * Function to determine if the date entered is before the dependent timeline date.
   * If there is no dependent timeline date (it is the first timeline), we return false.
   *
   * @param formObj - form object of row
   */
  isDateBeforeDependentDate(formObj): boolean {
    const depIndex = this.findDependentIndex(formObj?.timelineId - 1);
    const depTimeline = this.tableRows.at(depIndex);
    const depDate = depTimeline.get('date').value;
    if((depTimeline.get('notApplicable').value === true &&
      depTimeline.get('timelineId').value === 1) ||
      formObj?.timelineId === 1) {
      return false;
    }
    if(moment(formObj?.date).diff(depDate, 'days') <= 0) {
      return true;
    }
    return false;
  }

  protected readonly TIMELINES = TIMELINES;
}

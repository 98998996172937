import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {BannerService} from '../../shared/services/banner.service';
import {AuditRequest} from '../audit-request.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as START_UP_PROCESS from '../start-up-process/start-up-process.constants';
import {DocumentService} from '../../services/document.service';
import {forkJoin, Observable} from 'rxjs';
import {LddVerification, NdaVerification, StartUpSaveRequest} from '../../models/start-up-file-save-request.model';
import {TimeLineService} from '../time-line/time-line.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AuditContactDetailModel} from '../audit-contact/audit-contact.model';
import {AuditRequestUtil} from '../../utilities/audit-request.util';
import {AuditTypeModel} from '../audit-types/audit-types.model';
import {NotificationService} from '../../services/notification.service';
import {NotificationHistoryRequestModel} from '../../models/notification-history-request.model';
import {AuditInformationService} from '../audit-information/audit-information.service';


@Component({
  selector: 'app-start-up-process',
  templateUrl: './start-up-process.component.html',
  styleUrls: ['./start-up-process.component.scss']
})
export class StartUpProcessComponent extends BaseComponent implements OnChanges, OnInit {

  @Input() auditDetail!: AuditRequest;
  @Input() startUpProcessForm!: FormGroup;
  @Input() showBanner!: boolean;
  @Input() auditRequestMode!: any;
  @Input() isClientAuditCase!: boolean;
  @Input() auditContactDetail!: AuditContactDetailModel;
  @Input() auditTypeDetail!: AuditTypeModel[];
  @Input() auditTypes!: AuditTypeModel[];
  @Input() auditForm!: FormGroup;
  @Output() inProgressStartUpProcessForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() completedStartUpProcessForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() processWarning = new EventEmitter<boolean>();
  @ViewChild('ndaInfoTemplateRef') ndaInfoTemplateRef!: TemplateRef<any>;

  uploadedStartUpFilesLength: number;
  uploadedStartUpFiles$!: Observable<any>;
  ndaVerificationList$!: Observable<any>;
  lddVerificationList: LddVerification[] = [];
  auditorGroupTypeMap = new Map();
  initialNdaValues: any;
  auditReviewForm!: FormGroup;
  reviewType = '';
  savedNdaResponse: any;
  disableForCancelAuditor: boolean;
  disableForReturnAuditor: boolean;
  viewerEmailList: string[] = [];

  get firmsVerificationNDAForm() {
    return (this.startUpProcessForm.get('firmsVerificationNDA') as FormGroup);
  }

  constructor(
    private documentService: DocumentService,
    public bs: BannerService,
    private fb: FormBuilder,
    private timeLineService: TimeLineService,
    private router: Router,
    private matDialog: MatDialog,
    private notificationService: NotificationService,
    private auditInfoService: AuditInformationService) {
    super(bs);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.auditDetail?.currentValue) {
      this.uploadedStartUpFiles$ = this.documentService.getStartUpFiles(this.auditDetail.auditId);
      this.ndaVerificationList$ = this.documentService.getNdaVerificationList(this.auditDetail.auditId);
      this.disableForCancelAuditor = AuditRequestUtil.getStatusOfAuditForCancelAuditor(this.auditDetail.status);
      this.disableForReturnAuditor = AuditRequestUtil.getStatusOfAuditForReturnAuditor(this.auditDetail.status);
      this.setupFormData();
    }

    if(changes.auditTypeDetail?.currentValue) {
      const lddVerificationIds = this.auditTypeDetail?.filter( type => type?.lddVerificationId)?.map(m => m?.lddVerificationId);
      this.documentService.getLddVerificationList(lddVerificationIds).subscribe(ldd => {
        this.lddVerificationList = ldd;
        this.updateLDDFormValue();
      });
    }
  }

  checkFormValidity() {
    this.startUpProcessForm.updateValueAndValidity();
    if (this.startUpProcessForm.valid) {
      this.completedStartUpProcessForm.emit(5);
      this.processWarning.emit(false);
    } else {
      this.inProgressStartUpProcessForm.emit();
    }
  }

  setupFormData(){
    forkJoin([this.uploadedStartUpFiles$, this.ndaVerificationList$]).subscribe(results => {
      this.uploadedStartUpFilesLength = results[0].length;
      this.initialNdaValues = results[1];

      this.createFormGroup(this.initialNdaValues);
      this.checkFormValidity();
    });
  }

  filesAddedOrRemoved() {
    this.uploadedStartUpFiles$.subscribe(files => {
      this.uploadedStartUpFilesLength = files.length;
      if (this.uploadedStartUpFilesLength > 0) {
        this.firmsVerificationNDAForm.get('isFileUploaded').setValue(true);
        this.firmsVerificationNDAForm.updateValueAndValidity();
        this.startUpProcessForm.updateValueAndValidity();
      } else {
        this.firmsVerificationNDAForm.get('isFileUploaded').setValue(null);
        this.firmsVerificationNDAForm.updateValueAndValidity();
        this.startUpProcessForm.updateValueAndValidity();
        this.inProgressStartUpProcessForm.emit();
      }
      this.checkFormValidity();
    });
  }

  createFormGroup(ndaVerificationViewList: any) {
    this.firmsVerificationNDAForm.reset();

    ndaVerificationViewList.forEach((listItem: any) => {
      if (!this.auditorGroupTypeMap.has(listItem.auditorGroupTypeDesc)) {
        this.auditorGroupTypeMap.set(listItem.auditorGroupTypeDesc, [listItem]);
      } else {
        this.auditorGroupTypeMap.get(listItem.auditorGroupTypeDesc).push(listItem);
      }
    });

    this.auditorGroupTypeMap.forEach(auditorGroupType => {
      const controlName = auditorGroupType[0].auditorGroupTypeDesc;

      this.firmsVerificationNDAForm.addControl(controlName, new FormControl({value: null, disabled: false}, [Validators.required]));
      this.firmsVerificationNDAForm.addControl(controlName + ' checkbox', new FormControl(null));

      if (auditorGroupType[0].selectedNdaOptionId === 1) {
        this.firmsVerificationNDAForm.get(controlName).setValue('1');
        this.firmsVerificationNDAForm.get(controlName + ' checkbox').setValue(true);
      }

      if (auditorGroupType[0].selectedNdaOptionId === 2 && auditorGroupType[0].ndaVerified) {
        this.firmsVerificationNDAForm.get(controlName).setValue('2');
        this.firmsVerificationNDAForm.get(controlName + ' checkbox').setValue(true);
      }

      if (auditorGroupType[0].selectedNdaOptionId === 2 && !auditorGroupType[0].ndaVerified) {
        this.firmsVerificationNDAForm.get(controlName).setValue('2');
        this.firmsVerificationNDAForm.get(controlName + ' checkbox').setValue(false);
      }
    });
  }

  saveNdaVerification() {
    if (this.firmsVerificationNDAForm.valid) {
      this.documentService.saveNdaOptions(this.auditDetail.auditId, this.buildSaveRequestData()).subscribe({
        next: (res: StartUpSaveRequest) => {
          this.showSuccessNotification(
              'Start Up Process was successfully saved!',
              'Successful Save',
              '#start-up-process-alert-div',
              15000);
          this.firmsVerificationNDAForm.markAsPristine();
          this.completedStartUpProcessForm.emit(5);
          this.processWarning.emit(false);
          this.savedNdaResponse = res?.ndaVerificationSelection;
          this.lddVerificationList = res?.lddVerificationSelection;
        },
        error: () => {
          this.showErrorNotification(
              'You have failed to Save Start Up Process.',
              'Unsuccessful save',
              '#start-up-process-alert-div',
              20000);
        }
      });
    } else {
      const errorDetail = 'You must select an appropriate NDA option for each firm in order to proceed to the next step! ';
      this.showErrorNotification(
          errorDetail,
          'Error',
          '#start-up-process-alert-div',
          20000);
    }
  }

  buildSaveRequestData() {
    const saveArray: StartUpSaveRequest = {
      ndaVerificationSelection: [],
      lddVerificationSelection: []
    };

    Object.keys(this.firmsVerificationNDAForm.controls).forEach(key => {
      if (this.auditorGroupTypeMap.has(key)) {

        const saveObj: NdaVerification = {
          auditorGroupTypeId: this.auditorGroupTypeMap.get(key)[0].auditorGroupTypeId,
          ndaOptionId: this.firmsVerificationNDAForm.get(key).value,
          ndaVerified: false
        };

        if (saveObj.ndaOptionId === '1') {
          saveObj.ndaVerified = true;
        } else if (saveObj.ndaOptionId === '2') {
          if (this.firmsVerificationNDAForm.get(key + ' checkbox').value === true) {
            saveObj.ndaVerified = true;
          }
        }
        saveArray.ndaVerificationSelection.push(saveObj);
      }
    });
    if(this.isLDDExcludesSelection('Pricing')) {
      const pricingId = this.auditTypes?.find(f => f.offeringName === 'Pricing').offeringId;
      const lddSaveObj: LddVerification = {
        lddExcluded: this.firmsVerificationNDAForm.get('isPricingLDD')?.value
          ? this.firmsVerificationNDAForm.get('isPricingLDD')?.value === 'true'
          : null,
        lddList: this.firmsVerificationNDAForm.get('pricingLDDList')?.value,
        lddOfferingId: pricingId,
        lddVerificationId: this.getLDDVerificationId(pricingId)
      };
      saveArray.lddVerificationSelection.push(lddSaveObj);
    }
    if(this.isLDDExcludesSelection('Rebate Guarantee')) {
      const rebateGuaranteeId = this.auditTypes?.find(f => f.offeringName === 'Rebate Guarantee').offeringId;
      const lddSaveObj: LddVerification = {
        lddExcluded: this.firmsVerificationNDAForm.get('isRebateGuaranteeLDD')?.value
          ? this.firmsVerificationNDAForm.get('isRebateGuaranteeLDD')?.value === 'true'
          : null,
        lddList: this.firmsVerificationNDAForm.get('rebateGuaranteeLDDList')?.value,
        lddOfferingId: rebateGuaranteeId,
        lddVerificationId: this.getLDDVerificationId(rebateGuaranteeId)
      };
      saveArray.lddVerificationSelection.push(lddSaveObj);
    }
    return saveArray;
  }

  getLDDVerificationId(offeringId: number) {
    return this.lddVerificationList?.find(f => f.lddOfferingId === offeringId)?.lddVerificationId ?? null;
  }
  ngOnInit(): void {
    this.auditReviewForm = this.fb.group({
      auditReviewNotes: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(500)]],
    });

    this.startUpProcessForm.valueChanges.subscribe( ()=> {
      if(this.firmsVerificationNDAForm.dirty){
        this.processWarning.emit(true);
      }
    });
  }

  auditReviewAction(event) {
    this.matDialog.closeAll();
    if(event?.mode !== 'cancel') {
      this.auditInfoService.getAuditRequest(this.auditDetail.recordId).subscribe({
        next: (auditRequest: AuditRequest) => {
          this.auditDetail = auditRequest;
          let emailAddresses = [];
          let headerText;
          let emailBody;
          let notifEvent;
          if(event.mode === 'CANCELAUDIT') {
            emailAddresses = this.notificationService.getAllEmailContacts(
              this.auditContactDetail,
              this.auditDetail.auditInformation.createdById);
            headerText = 'CVS Audit ' + this.auditDetail.recordId + ' has been cancelled in the Audit Management System';
            emailBody = this.notificationService.createEmailBodyForCancelledAudit(
              this.auditForm.value, this.auditDetail, event.notes);
            notifEvent = 'Audit Cancelled';
          } else if ( event.mode === 'RETURNAUDIT'){
            emailAddresses.push(this.auditDetail.auditInformation.createdById);
            const primaryAuditContact = this.auditContactDetail.primaryAuditContact.filter(contact => contact.isPrimaryContact === true)[0];
            if(emailAddresses.indexOf(primaryAuditContact.contactEmail) === -1) {
              emailAddresses.push(primaryAuditContact.contactEmail);
            }
            const firmEmail = this.auditContactDetail.generalMail;
            if(firmEmail !== null && emailAddresses.indexOf(firmEmail) === -1) {
              emailAddresses.push(firmEmail);
            }
            headerText = 'CVS Audit ' + this.auditDetail.recordId + ' has been returned in the CVS Audit Management System';
            emailBody = this.notificationService.createEmailBodyForReturnAudit(
              this.auditForm.value, this.auditDetail, event.notes);
            notifEvent = 'Audit Returned';
          }
          const notificationRequest = this.notificationService.createNotificationRequest(
            headerText, emailBody, emailAddresses);
          this.notificationService.sendNotification(notificationRequest).subscribe((res) => {
            const notificationHistoryRequest: NotificationHistoryRequestModel = {
              notificationRequest,
              notificationEvent: notifEvent,
              recordId: this.auditDetail.recordId,
              notificationType: 'Real-time',
              currentUserEmail: sessionStorage.getItem('email')
            };
            this.notificationService.saveNotificationHistory(notificationHistoryRequest).subscribe((res) => {
                console.log('Notification sent/saved');
              },
              (notificationHistoryErr) => {
                console.error('Error saving notification history', notificationHistoryErr);
              });
          }, (notificationErr) => {
            console.error('Error sending notification', notificationErr);
          });
        }
      });

      this.router.navigate(['/internal']).then();
    }
  }

  auditReviewModalOpen(templateRef: TemplateRef<any>, reviewType: string) {
    this.reviewType = reviewType;
    this.viewerEmailList = AuditRequestUtil.getNotesViewerEmailList(this.auditDetail, this.auditContactDetail);
    this.matDialog.open(templateRef, {
      disableClose: true
    });
  }

  ndaInfoDefDialog() {
    this.matDialog.open(this.ndaInfoTemplateRef, {
      disableClose: true,
      panelClass: 'ndaInfoDialog'
    });
  }

  /**
   * Handles the Cancel button functionality for the Start Up Process form.
   * Sets the Start Up Process form back to what was in the savedNdaResponse or
   * initialNdaValues variable (based on if the user saved the form),
   * then checks the validity of the form.
   */
  cancelStartUpProcess() {
    this.bs.close();
    if(this.savedNdaResponse === undefined) {
      this.createFormGroup(this.initialNdaValues);
      this.filesAddedOrRemoved();
    } else {
      this.setNdaFormValuesToSavedValues();
    }
    this.updateLDDFormValue();
  }

  /**
   * Takes the savedNdaResponse object from the documentService.saveNdaOptions() call
   * and sets the firmsVerificationNDAForm to what those values were.
   */
  setNdaFormValuesToSavedValues() {
    this.savedNdaResponse.forEach((ndaVerification, i) => {
      switch(i) {
        case 0:
          this.firmsVerificationNDAForm.get('Firm Auditor').setValue(ndaVerification.ndaOptionId);
          this.firmsVerificationNDAForm.get('Firm Auditor checkbox').setValue(ndaVerification.ndaVerified);
          break;
        case 1:
          this.firmsVerificationNDAForm.get('Subcontractor 1').setValue(ndaVerification.ndaOptionId);
          this.firmsVerificationNDAForm.get('Subcontractor 1 checkbox').setValue(ndaVerification.ndaVerified);
          break;
        case 2:
          this.firmsVerificationNDAForm.get('Subcontractor 2').setValue(ndaVerification.ndaOptionId);
          this.firmsVerificationNDAForm.get('Subcontractor 2 checkbox').setValue(ndaVerification.ndaVerified);
          break;
        default:
          break;
      }
    });
  }

  /**
   * @description fetch LDD form values and validation
   */
  updateLDDFormValue() {
    this.resetLDDForm();
    if(this.isLDDVerificationPresent) {
      this.fetchLDDForm();
      if(this.isLDDExcludesSelection('Pricing')) {
        this.setFormControlValidator('isPricingLDD');
      }
      if(this.isLDDExcludesSelection('Rebate Guarantee')) {
        this.setFormControlValidator('isRebateGuaranteeLDD');
      }
    }
    this.selectionOfLDD();
    this.checkFormValidity();
  }

  fetchLDDForm() {
    this.lddVerificationList?.forEach(list => {
      const pricingId = this.auditTypes.find(f => f?.offeringName === 'Pricing')?.offeringId;
      const rebateGuaranteeId = this.auditTypes.find(f => f?.offeringName === 'Rebate Guarantee')?.offeringId;
      const lddExcluded = list.lddExcluded ? 'true' : list.lddExcluded === false ? 'false' : null;
      if(list.lddOfferingId === pricingId) {
        this.firmsVerificationNDAForm.get('isPricingLDD')?.setValue(lddExcluded);
        this.firmsVerificationNDAForm.get('pricingLDDList')?.setValue(list.lddList);
      }
      if(list.lddOfferingId === rebateGuaranteeId) {
        this.firmsVerificationNDAForm.get('isRebateGuaranteeLDD')?.setValue(lddExcluded);
        this.firmsVerificationNDAForm.get('rebateGuaranteeLDDList')?.setValue(list.lddList);
      }
    });
  }

  resetLDDForm() {
    this.firmsVerificationNDAForm.get('isPricingLDD')?.reset();
    this.firmsVerificationNDAForm.get('pricingLDDList')?.reset();
    this.firmsVerificationNDAForm.get('isRebateGuaranteeLDD')?.reset();
    this.firmsVerificationNDAForm.get('rebateGuaranteeLDDList')?.reset();
    this.removeFormControlValidator('isPricingLDD');
    this.removeFormControlValidator('isRebateGuaranteeLDD');
    this.firmsVerificationNDAForm.updateValueAndValidity();
  }

  /**
   * @description select LDD list field validation based on LDD selection
   */
  selectionOfLDD() {
      if(this.firmsVerificationNDAForm.get('isRebateGuaranteeLDD')?.value === 'true') {
        this.setFormControlValidator('rebateGuaranteeLDDList');
      } else  {
        this.firmsVerificationNDAForm.get('rebateGuaranteeLDDList')?.reset();
        this.removeFormControlValidator('rebateGuaranteeLDDList');
      }
      if(this.firmsVerificationNDAForm.get('isPricingLDD')?.value === 'true') {
        this.setFormControlValidator('pricingLDDList');
      } else {
        this.firmsVerificationNDAForm.get('pricingLDDList')?.reset();
        this.removeFormControlValidator('pricingLDDList');
      }
  }

  /**
   * @description LDD excludes for Pricing/Rebate Guarantee fields is show or not based on audit type selection
   * @param auditType auditType is Pricing/Rebate Guarantee
   */
  isLDDExcludesSelection(auditType: string) {
    if(auditType) {
      return this.auditTypes?.filter(type => this.auditTypeIds?.includes(type.offeringId)
        && type.offeringName === auditType)?.length > 0;
    }
    return false;
  }

  setFormControlValidator(formControl: string) {
    this.firmsVerificationNDAForm?.get(formControl)?.setValidators([Validators.required]);
    this.firmsVerificationNDAForm?.get(formControl)?.updateValueAndValidity();
  }

  removeFormControlValidator(formControl: string) {
    this.firmsVerificationNDAForm?.get(formControl)?.setErrors(null);
    this.firmsVerificationNDAForm?.get(formControl)?.removeValidators([Validators.required]);
    this.firmsVerificationNDAForm?.get(formControl)?.updateValueAndValidity();
  }

  get auditTypeIds() {
    return this.auditTypeDetail?.filter(type => type.offeringId)?.map(map => map.offeringId);
  }

  get isLDDVerificationPresent() {
    return this.auditTypes?.filter(type => this.auditTypeIds.includes(type.offeringId)
      && ['Pricing', 'Rebate Guarantee'].includes(type.offeringName) && type.infoName === 'Limited Distribution List')?.length > 0;
  }

  protected readonly START_UP_PROCESS = START_UP_PROCESS;

}

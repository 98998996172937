import * as AUDIT_TYPES from '../audit-request/audit-types/audit-types.contants';
import {AuditRequest} from '../audit-request/audit-request.model';
import {AuditContactDetailModel} from '../audit-request/audit-contact/audit-contact.model';
import {Constants} from '../constants/constants';

export class AuditRequestUtil {

  private static dateTimeIntl = new Intl.DateTimeFormat('en-US', Constants.DATE_TIME_OPTIONS);

  static getClientOrCoalitionLabel(coalition: boolean) {
    return coalition === true ? AUDIT_TYPES.label.coalitionNameLabel : AUDIT_TYPES.label.clientNameLabel;
  }

  static getClientOrAccountName(auditDetail: AuditRequest) {
    const isSingleClient = auditDetail?.auditInformation?.coalition === false
      && auditDetail?.auditInformation?.multipleCarrier === false;
    return isSingleClient ? auditDetail?.auditInformation?.accountName : auditDetail?.auditInformation?.clientName;
  }

  static getNotesViewerEmailList(auditDetail: AuditRequest, auditContactDetail: AuditContactDetailModel) {
    const viewerEmailList = [];
    const auditRequesterEmail = auditDetail?.auditInformation?.createdById;

    if(auditDetail?.status === 'Staging') { // before approval
      viewerEmailList.push(auditRequesterEmail);

      const primaryContactEmail = auditContactDetail?.primaryAuditContact?.find(
              contact => contact?.isPrimaryContact)?.contactEmail ?? null;
      if(primaryContactEmail && primaryContactEmail !== auditRequesterEmail) {
        viewerEmailList.push(primaryContactEmail);
      }

    } else if(auditDetail?.status === 'In Progress') { // after approval

      let requesterExisting = false;
      auditContactDetail?.primaryAuditContact?.forEach( primary => {
        requesterExisting = !requesterExisting ? primary?.contactEmail === auditRequesterEmail : requesterExisting;
        viewerEmailList.push(primary?.contactEmail);
      });

      auditContactDetail.auditSubcontractorOne?.forEach( subOne => {
        requesterExisting = !requesterExisting ? subOne?.contactEmail === auditRequesterEmail : requesterExisting;
        viewerEmailList.push(subOne?.contactEmail);
      });

      auditContactDetail.auditSubcontractorTwo?.forEach( subTwo => {
        requesterExisting = !requesterExisting ? subTwo?.contactEmail === auditRequesterEmail : requesterExisting;
        viewerEmailList.push(subTwo?.contactEmail);
      });

      if(!requesterExisting) {
        viewerEmailList.push(auditRequesterEmail);
      }
    }
    return viewerEmailList;
  }


  static getStatusOfAuditForCancelAuditor(status: string): boolean {
    return status === 'Cancelled' || status === 'Closed';
  }

  static getStatusOfAuditForReturnAuditor(status: string): boolean {
    return status === 'Cancelled' || status === 'Closed' || status === 'In Progress';
  }

  static convertCtTimeZone(dateTime: string, timeZoneSuffix: string) {
    return dateTime ? this.dateTimeIntl.format(new Date(dateTime))?.replace(',', '') + ' ' + timeZoneSuffix : null;
  }
}

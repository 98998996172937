export const overAllSaveValidation = Object.freeze({
  auditInfo: 'You have failed to complete the required information on the Audit Information Step. Navigate to Audit Information ' +
    'in the progress menu,  complete the required fields indicated by an asterisk and select Save.',
  auditInfoReview: 'You have failed to enter the coalition or client on the Audit Information page, select the Audit Information' +
    ' step in the progress menu, make the necessary correction and save.  After all issues have been resolved, you can select to' +
    ' Approve the Audit.',
  auditTypes: 'You have have failed to complete the required information on the Audit Type and Information Request Step.  Navigate to ' +
    'the Audit Type and Information Request step in the progress menu, select at least one Audit Type you want covered for your Audit ' +
    'and select Save.',
  auditContact: {
    primaryAuditor: 'You have failed to select at least one Primary Auditor Contact for the Primary Firm in the Audit Contacts Step.  ' +
      'Navigate to the Audit Contacts step in the progress menu, edit the primary contact and select the Assign Primary Auditor toggle ' +
      'and select Save.  If you have not entered at least one contact for the Primary Firm, select Add Contact, complete the required ' +
      'fields, Assign Primary  Auditor for the Primary Firm.',
    subContractor: 'Navigate to the Audit Contacts step in the progress menu, select Add Subcontractor Contact, complete the required ' +
      'field and select Save.'
  },
    auditRole: {
      subConRole: 'Navigate to the Auditor Roles step in the progress menu, under subcontractors, select at least one information data ' +
        'type for each audit type covered for your audit and Save',
      primaryAuditorRole: 'Navigate to the Auditor Roles step in the progress menu, select at least one information data type for ' +
        'each audit type covered in your audit and Save',
      dividedRolePrimary: 'You have failed to assign roles to the Primary Firm for this audit.   Navigate to the Auditor Roles step ' +
        'in the progress menu, under the primary firm, select at least one information data type for each audit type covered for your ' +
        'audit and Save',
      divideRoleSubCon: 'You have failed to assign roles to the  Audit Subcontractor(s) for this audit.   Navigate to the Auditor Roles' +
        ' step in the progress menu, under the subcontractor firm(s), select at least one information data type for each audit type ' +
        'covered for your audit and Save',
      responsibilityMiss: 'You have failed to assign audit responsibilities on the Audit Roles Step.     Navigate to the Audit Roles' +
        ' step in the progress menu, select a choice for the Assign Audit Responsibilities and select Save'
    },
    uploadDocs: {
      coalition: 'Your Coalition List with Line of Business(LOB) is missing from your Upload Documents.  Navigate to the Upload Documents' +
        ' step in the progress menu, Select file and upload the document in the Coalition List section of the page.',
      scopeLetter: 'Your Scope Letter is missing from Upload Documents.  Navigate to the Upload Documents step in the progress menu, ' +
        'Select file and upload the document in the Scope Letter section of the page. ',
      clientAuth: 'Your Client Authorization document is missing from Upload Documents.  Navigate to the Upload Documents step in the' +
        ' progress menu, Select file and upload the document in the Client Authorization section of the page.',
      apiFail: 'Failed to load data from server'
    },
    timeline: 'You have failed to enter all of the projected timelines or set them as not applicable that are the auditor responsibility ' +
      'and saved them.  Make the needed corrections and press Save on the Projected Timelines Step',
    timelineDate: 'You have failed to enter all of the required projected timelines or select not applicable as appropriate.' +
    ' Make the necessary corrections in order to approve this request!',
    startUp: {
    oneDoc: 'You have failed to upload the startup form document, select the Complete Start Up Process Step in the progress menu, ' +
      'upload the startup process form.  Complete the NDA verification section for each firm and press save.  After all issues have' +
      ' been resolved, you can select to Approve the Audit.',
    ndaVerify: 'You have failed to fully complete the NDA Verification for each firm on the Complete Start Up Process Step.  ' +
      'Select the Complete Start Up Process Step in the progress menu and select an NDA verification option appropriate for each firm, ' +
      'then press save.  After all issues have been resolved, you can select to Approve the Audit.'


    }
});

export const errorMessage = Object.freeze({
  multiError: 'Resolve the following criteria in order to proceed',
  errorTitle: 'Unsuccessful Save',
});

export const successMessages = Object.freeze({
  successTitle: 'Audit Request Submitted',
  add: 'Your Audit Request was Submitted saved!',
  approve: 'Success - Audit Approved'
});

export const banner = Object.freeze({
  alertDiv: '#audit-timeline-alert-div'
});

export const hint = Object.freeze({
  approve: 'You have elected to approve this audit, enter any notes or reminders you would like to relay to the auditor ' +
    'related to this audit and press Approve Audit!'
});

export const claimAuditTimelineDurations = Object.freeze({
  oneYearOneLOB2: [0, 30, 30, 0, 10, 0, 0, 30, 0, 0, 30, 0, 30, 0],
  oneYearTwoLOB2: [0, 50, 50, 0, 15, 0, 0, 45, 0, 0, 45, 0, 45, 0],
  oneYearThreeLOB2: [0, 60, 60, 0, 15, 0, 0, 60, 0, 0, 60, 0, 60, 0],
  oneYearFourLOB2: [0, 60, 60, 0, 15, 0, 0, 75, 0, 0, 60, 0, 60, 0],
  twoYearOneLOB2: [0, 45, 45, 0, 15, 0, 0, 30, 0, 0, 30, 0, 30, 0],
  twoYearTwoLOB2: [0, 60, 60, 0, 20, 0, 0, 45, 0, 0, 60, 0, 60, 0],
  twoYearThreeLOB2: [0, 70, 70, 0, 20, 0, 0, 60, 0, 0, 70, 0, 70, 0],
  twoYearFourLOB2: [0, 75, 75, 0, 20, 0, 0, 75, 0, 0, 75, 0, 75, 0]
});

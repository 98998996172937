<form class="audit-timeline-form pt-10" [formGroup]="auditTimelineForm" autocomplete="off">
  <div id="audit-timeline-alert-div">
  </div>
  <ng-container>
    <h2 class="pt-24">Projected Timeline</h2>
    <p class="paragraph-style projected-timeline-paragraph">
      If you have finished entering all the information in this audit request, you can proceed by entering your
      projected timeline dates that are available for your input on this page. If the timeline is not applicable
      to this audit, please check the Not Applicable N/A next to the timeline date and that timeline will be excluded
      from the date calculations. Dates are subject to change based on the complexity of the scope letter, receipt of
      documentation, and deliverables related to the audit. Dates could be adjusted during the kickoff discussion with
      the CVS Team. Any external audit parties agree to the timelines entered here.
    </p>
    <p class="paragraph-style projected-timeline-paragraph">
      Please Note: The external kickoff date is pre-populated based on today's date with a two-week duration. If you
      are not ready to submit your audit request today, you should hold off on entering any values in timelines to avoid
      repeating your entries on this page as the dates shift. You will be able to come back to edit your timelines once
      you have completed the other sections of this audit.
    </p>
  </ng-container>

  <ng-container>
    <mat-table [dataSource]="timeLineDataSource" class="table-styling pds-table pds-card pds-table--dense">
      <mat-header-row *matHeaderRowDef="menuColumns"></mat-header-row>
      <mat-row class="table-row-style" *matRowDef="let row; let i = index; columns: menuColumns;"></mat-row>

      <!-- Projected Timeline Column -->
      <ng-container matColumnDef="projectedTimeline">
        <mat-header-cell class="mat-header-cell-style" *matHeaderCellDef>
          Projected Timeline</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element">
          <div>
            <div *ngIf="element.value.notApplicable || (isAuditTypePricing && i === 2)">
              <mat-form-field class="projected-timeline-datepicker-form pds-read-only-field cvs-compact-form-field">
                <mat-label>{{element.value?.title}}</mat-label>
                <input matInput disabled [value]="'N/A'">
              </mat-form-field>
            </div>
            <div *ngIf="!element.value.notApplicable">
              <ng-container *ngIf="!(isAuditTypePricing && i === 2) && (enabledIndexes.includes(i))">
                <mat-form-field *ngIf="auditRequestMode === AUDIT_INFO.modes.edit" class="projected-timeline-datepicker-form cvs-compact-form-field" appearance="outline">
                  <mat-label>{{element.value?.title}}</mat-label>
                  <input (keydown)="onKeyDown($event)" (dateChange)="handleValueChange(i)" matInput [matDatepicker]="pickerClosedFilled" formControlName="date" [min]="element.value?.minDate">
                  <mat-icon class="icon" aria-hidden="false" svgIcon="calendar--s" matSuffix tabindex="0" aria-label="Open Date Picker" (click)="pickerClosedFilled.open()" (keydown.enter)="pickerClosedFilled.open()"></mat-icon>
                  <mat-error *ngIf="element.controls.date.errors?.invalid || element.controls.date.errors?.required">This field is required.</mat-error>
                  <mat-error *ngIf="isDateBeforeMinDate(element.value)">Date entered cannot be less than {{element.value.minDate | date : 'MM/dd/yyyy'}}.</mat-error>
                  <mat-datepicker #pickerClosedFilled></mat-datepicker>
                </mat-form-field>
                <mat-form-field *ngIf="auditRequestMode === AUDIT_INFO.modes.read" class="projected-timeline-datepicker-form pds-read-only-field cvs-compact-form-field">
                  <mat-label>{{element.value?.title}}</mat-label>
                  <input disabled matInput [value]="element.value?.date | formatDate: true">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="(!enabledIndexes.includes(i) && !(isAuditTypePricing && i === 2))">
                <mat-form-field class="projected-timeline-datepicker-form pds-read-only-field cvs-compact-form-field">
                  <mat-label>{{element.value?.title}}</mat-label>
                  <input matInput disabled [value]="element.value?.date | formatDate">
                </mat-form-field>
              </ng-container>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- N/A Column -->
      <ng-container matColumnDef="notApplicable">
        <mat-header-cell class="mat-header-cell-style" *matHeaderCellDef>N/A</mat-header-cell>
        <mat-cell class="table-text-style" *matCellDef="let element; let i = index;" [formGroup]="element">
          <mat-checkbox formControlName="notApplicable" (change)="handleValueChange(i, true)">N/A</mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- General Timeline Column -->
      <ng-container matColumnDef="generalTimeline">
        <mat-header-cell class="mat-header-cell-style" *matHeaderCellDef>General Timeline</mat-header-cell>
        <mat-cell class="table-text-style" *matCellDef="let element">
          <ng-container *ngIf="element.value?.duration > 0">
            {{ element.value?.duration }} business days
          </ng-container>
          <ng-container *ngIf="element.value?.duration === 0">
            -
          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- Responsibility Column -->
      <ng-container matColumnDef="responsibility">
        <mat-header-cell class="mat-header-cell-style" *matHeaderCellDef>Responsibility</mat-header-cell>
        <mat-cell class="table-text-style" *matCellDef="let element"> {{ element.value?.responsibility }} </mat-cell>
      </ng-container>

      <!-- Actual Date Column -->

      <!-- Notes Column -->
      <ng-container matColumnDef="notes">
        <mat-header-cell class="mat-header-cell-style" *matHeaderCellDef>Notes</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
          <div>
            <div *ngIf="element.value?.notesExists === false">
              <button class="pds-btn-text-only"
                      [disabled]="element.value.notApplicable ||
                        (i === 2 && isAuditTypePricing) ||
                        (element.value.date === '' && auditRequestMode === AUDIT_INFO.modes.read) ||
                        (auditRequestMode === AUDIT_INFO.modes.read)"
                      (click)="notesModal(element, 'add')">
                <mat-icon class="icon" svgIcon="pencil--s"> </mat-icon>
                Add Notes
              </button>
            </div>
            <div *ngIf="element.value?.notesExists === true">
              <button  class="pds-btn-text-only"
                       [disabled]="element.value.notApplicable ||
                        (i === 2 && isAuditTypePricing) ||
                        (element.value.date === '' && auditRequestMode === AUDIT_INFO.modes.read)"
                       (click)="notesModal(element, auditRequestMode === AUDIT_INFO.modes.read ? 'read' : 'view')">

                <mat-icon class="icon" svgIcon="file-check--s"> </mat-icon>
                {{auditRequestMode === AUDIT_INFO.modes.read ? 'View ' : 'View/Add '}}Notes
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>
    </mat-table>
  </ng-container>
  <div *ngIf="auditRequestMode !== AUDIT_INFO.modes.read" class="mt-10" style="display: flex; justify-content: space-between; width: 58vw;">
    <div>
      <button type="button" class="pds-btn-ghost" (click)="saveAuditTimeLines()"  *ngIf="!userService.isReadOnlyUser()">Save</button>
      <button type="button" class="pds-btn-ghost" (click)="cancelAuditTimeLines()" *ngIf="!userService.isReadOnlyUser()">Cancel</button>
      <button type="button" class="pds-btn-primary" [disabled]="disableApproveSubmit" (click)="validateAuditRequest()" *ngIf="auditRequestMode === AUDIT_INFO.modes.edit">Submit Audit Request</button>
    </div>
  </div>
</form>

<ng-template #overallSaveTemplate>
  <cvs-banner [data]="bannerData">
    <h2>{{AUDIT_TIMELINE.errorMessage.errorTitle}}</h2>
    <p class="pt-15">{{AUDIT_TIMELINE.errorMessage.multiError}}</p>
    <ul>
      <li *ngIf="auditForm?.get('auditInfoForm')?.invalid || auditForm?.get('auditInfoForm')?.hasError('required')">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditInfo}}</li>

      <li *ngIf="auditForm?.get('auditTypeForm')?.invalid || auditForm?.get('auditTypeForm')?.hasError('hasAuditType')">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditTypes}}</li>

      <li *ngIf="auditForm?.get('auditContactForm')?.hasError('primaryAuditContact')
      || auditForm?.get('auditContactForm')?.get('primaryAuditContact')?.hasError('required')
       || auditForm?.get('auditContactForm')?.invalid">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditContact.primaryAuditor}}</li>
      <li *ngIf="auditForm?.get('auditContactForm')?.hasError('hasAuditSubcontractor')
        || auditForm?.get('auditContactForm')?.invalid">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditContact.subContractor}}</li>

      <li *ngIf="auditForm?.get('auditRoleForm')?.errors?.offeringId?.auditRoleSelection
      && auditForm?.get('auditRoleForm')?.get('divideAuditResponsibility')?.value === null
      && auditForm?.get('auditContactForm')?.get('hasAuditSubcontractor')?.value === 'true'">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.responsibilityMiss}}</li>

      <li *ngIf="auditForm?.get('auditRoleForm')?.errors?.offeringId?.auditRoleSelection
      && auditForm?.get('auditRoleForm')?.get('divideAuditResponsibility')?.value === 'true'
      && !(auditForm.get('auditRoleForm')?.get('subcontractorOneAuditRole')?.get('offeringId')?.value?.length
      || auditForm.get('auditRoleForm')?.get('subcontractorTwoAuditRole')?.get('offeringId')?.value?.length)
      && auditForm?.get('auditContactForm')?.get('hasAuditSubcontractor')?.value === 'true'">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.divideRoleSubCon}}</li>

      <li *ngIf="auditForm?.get('auditRoleForm')?.errors?.offeringId?.auditRoleSelection
      && auditForm?.get('auditRoleForm')?.get('divideAuditResponsibility')?.value === 'true'
      && (!auditForm.get('auditRoleForm')?.get('primaryAuditRole')?.get('offeringId')?.value?.length)
      && auditForm?.get('auditContactForm')?.get('hasAuditSubcontractor')?.value === 'true'">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.dividedRolePrimary}}</li>

      <li *ngIf="auditForm?.get('auditRoleForm')?.errors?.offeringId?.auditRoleSelection
      && auditForm?.get('auditContactForm')?.get('hasAuditSubcontractor')?.value === 'false'
      && auditForm?.get('auditRoleForm')?.get('divideAuditResponsibility')?.value === null">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.primaryAuditorRole}}</li>

      <li *ngIf="auditForm?.get('auditRoleForm')?.errors?.offeringId?.auditRoleSelection
      && auditForm?.get('auditContactForm')?.get('hasAuditSubcontractor')?.value === 'true'
      && auditForm?.get('auditRoleForm')?.get('divideAuditResponsibility')?.value === 'false'">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.subConRole}}</li>

      <li *ngIf="documentRows?.hasError('required') || auditForm?.get('auditDocumentForm')?.invalid ">
        {{AUDIT_TIMELINE.overAllSaveValidation.uploadDocs.apiFail}}</li>
      <li *ngIf="documentRows.controls.at(0)?.get('docGroup')?.value === 2
      && documentRows.controls.at(0)?.get('hasFiles')?.hasError('required')">
        {{AUDIT_TIMELINE.overAllSaveValidation.uploadDocs.scopeLetter}}</li>
      <li *ngIf="documentRows.controls.at(1)?.get('docGroup')?.value === 3
       && documentRows.controls.at(1)?.get('hasFiles')?.hasError('required')">
        {{AUDIT_TIMELINE.overAllSaveValidation.uploadDocs.clientAuth}}</li>
      <li *ngIf="documentRows.controls.at(2)?.get('docGroup')?.value === 5
      && documentRows.controls.at(2)?.get('hasFiles')?.hasError('required')">
        {{AUDIT_TIMELINE.overAllSaveValidation.uploadDocs.coalition}}</li>

      <li *ngIf="auditForm?.get('auditTimelineForm')?.invalid">
        {{AUDIT_TIMELINE.overAllSaveValidation.timeline}}
      </li>
    </ul>
  </cvs-banner>
</ng-template>

<ng-template #submitApiTemplate>
  <cvs-banner [data]="bannerData">
    <h2>{{AUDIT_TIMELINE.errorMessage.errorTitle}}</h2>
    <p class="pt-15">{{AUDIT_TIMELINE.errorMessage.multiError}}</p>
    <ul>
      <li *ngIf="submitResponse?.auditInformationRequiredCheck === false">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditInfo}}</li>
      <li *ngIf="submitResponse?.auditTypesCheck === false">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditTypes}}</li>
      <li *ngIf="submitResponse?.auditContact?.primaryContactCheck === false">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditContact.primaryAuditor}}</li>
      <li *ngIf="submitResponse?.auditContact?.subContractorCheck === false">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditContact.subContractor}}</li>
      <li *ngIf="submitResponse?.auditRoles?.divideResponsibilityCheck === false">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.responsibilityMiss}}</li>
      <li *ngIf="submitResponse?.auditRoles?.divideResponsibilityCheck === false">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.dividedRolePrimary}}</li>
      <li *ngIf="submitResponse?.auditRoles?.divideResponsibilityCheck === false">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.divideRoleSubCon}}</li>
      <li *ngIf="submitResponse?.auditRoles?.soleAuditorCheck === false">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.primaryAuditorRole}}</li>
      <li *ngIf="submitResponse?.auditRoles?.allSubConResponsibilityCheck === false">
        {{AUDIT_TIMELINE.overAllSaveValidation.auditRole.subConRole}}</li>
      <li *ngIf="submitResponse?.auditUploadDocs?.scopeLetterCheck === false">
        {{AUDIT_TIMELINE.overAllSaveValidation.uploadDocs.scopeLetter}}</li>
      <li *ngIf="submitResponse?.auditUploadDocs?.clientAuthCheck === false">
        {{AUDIT_TIMELINE.overAllSaveValidation.uploadDocs.clientAuth}}</li>
      <li *ngIf="submitResponse?.auditUploadDocs?.coalitionCheck === false">
        {{AUDIT_TIMELINE.overAllSaveValidation.uploadDocs.coalition}}</li>
      <li *ngIf="submitResponse?.auditTimeLine === false">
        {{AUDIT_TIMELINE.overAllSaveValidation.timeline}}</li>
    </ul>
  </cvs-banner>
</ng-template>

<div *ngIf="isShow" class="audit-info-container pt-10">
  <div id="audit-info-alert-div"></div>
  <h2 class="pt-24">Audit Information</h2>
  <div class="pt-24 pageHint" *ngIf="auditRequestMode === AUDIT_INFO.modes.edit || auditRequestMode ===  AUDIT_INFO.modes.request
  || auditRequestMode === AUDIT_INFO.modes.read">
    <div class="hint-text">{{AUDIT_INFO.auditInfoHint.pageHintLine}}</div>
  </div>
  <div class="pt-24 pageHint" *ngIf="auditRequestMode === AUDIT_INFO.modes.review">
    <div class="hint-text">{{AUDIT_INFO.auditInfoHint.reviewPageHint}}</div>
  </div>
  <form [formGroup]="auditInfoForm" (submit)="saveOrUpdateAuditRequest()" (keydown.enter)="$event.preventDefault()">
    <div *ngIf="isFirmUser || (auditRequestMode === AUDIT_INFO.modes.review && auditInfoForm?.get('firmId')?.value !== null)" class="pt-24 subcontractor-firm-row">
      <div *ngIf="canCreateForPrimaryFirm" class="subcontractor-firm">
        <label>Are you a subcontractor creating this audit on behalf of the Primary Firm?*</label>
        <mat-radio-group aria-label="select if audit is created on behalf of a primary firm"
                         formControlName="createdForPrimaryFirm"
                         (change)="setPrimaryAuditFirmValidator($event)">
          <mat-radio-button value="true" [disabled]="disableSubcontractorFields() || auditRequestMode === AUDIT_INFO.modes.review" tabindex="0">Yes</mat-radio-button>
          <mat-radio-button value="false" [disabled]="disableSubcontractorFields() || auditRequestMode === AUDIT_INFO.modes.review" tabindex="0">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <div *ngIf="auditInfoForm?.get('createdForPrimaryFirm')?.value === 'true'" class="primary-audit-firm">
        <pds-autocomplete-select
          [label]="'Primary Audit Firm'"
          (input)="setLoading()"
          [readOnly]="disableSubcontractorFields() || auditRequestMode === AUDIT_INFO.modes.review"
          formControlName="primaryAuditFirm" displayNameKey="altSearchParam"
          [value]="getPrimaryAuditFirmValue()"
          [errorMsgs]="{required: 'Primary Firm is required!',valueNotInList: 'Audit Firm selected not in the list'}"
          [dropDownOptions]="firmDropDownList"
          [inputAppearance]="inputAppearance"></pds-autocomplete-select>
      </div>
      <div *ngIf="auditInfoForm?.get('createdForPrimaryFirm')?.value !== null || !canCreateForPrimaryFirm"
           class="requesting-firm">
        <label
          *ngIf="auditInfoForm?.get('createdForPrimaryFirm')?.value === 'true'"
          class="requesting-firm-label">Requesting Firm</label>
        <label
          *ngIf="auditInfoForm?.get('createdForPrimaryFirm')?.value === 'false' ||
          (auditInfoForm?.get('createdForPrimaryFirm')?.value === null && auditInfoForm?.get('firmId')?.value !== null)"
          class="requesting-firm-label">Primary Audit Firm</label>
        <p class="requesting-firm-text">{{auditDetail?.auditInformation?.createdByFirmId !== null ?
          getFirmName(auditDetail?.auditInformation?.createdByFirmId) :
          (auditRequestMode === AUDIT_INFO.modes.review ? getFirmName(auditDetail?.auditInformation?.firmId) : userFirm)}}</p>
      </div>
    </div>
    <div *ngIf="(checkSubcontractorAudit() || checkAuditInformation()) &&
    (isFirmUser || (auditRequestMode === AUDIT_INFO.modes.review && auditInfoForm?.get('firmId')?.value !== null))"
         class="page-divider"></div>
    <div *ngIf="checkSubcontractorAudit() || checkAuditInformation()">
      <!--select type of audit request and firm-->
      <div class="audit-info-content field-padding">
        <div class="audit-request-type">
          <label>Audit Request for *</label>
          <mat-radio-group aria-label="select type of audit request coalition or single" formControlName="commonRequest"
                           (change)="resetClientName()">
            <mat-radio-button value="coalition" tabindex="0">Coalition</mat-radio-button>
            <mat-radio-button value="single" tabindex="0">Single Client</mat-radio-button>
            <mat-radio-button value="multiple" tabindex="0" *ngIf="auditRequestMode === AUDIT_INFO.modes.review">
              Single Client Multiple Carriers
            </mat-radio-button>
          </mat-radio-group>
          <div class="pds-help-text--error text-error">
            <mat-error *ngIf="showError && auditInfoForm.get('coalition')?.invalid" aria-atomic="true"
                       aria-label="error"
                       class="custom-mat-error" aria-live="polite">
              <mat-icon svgIcon="error-f--xs" aria-hidden="false" class="custom-error-icon"></mat-icon>
              {{showFieldErrors(auditInfoForm.get('coalition'), AUDIT_INFO.formValidationMessages.coalition)}}
            </mat-error>
          </div>
        </div>
        <div *ngIf="!canCreateForPrimaryFirm && !isFirmUser && auditRequestMode !== AUDIT_INFO.modes.review" class="audit-firm">
          <label>{{auditInfoForm.get('firmId')!.value ? AUDIT_INFO.label.auditFirm : AUDIT_INFO.label.auditClient}}</label>
          <p>{{auditInfoForm.get('firmId')!.value ? getFirmName(auditInfoForm.get('firmId')!.value) : getClientName()}}</p>
        </div>
      </div>
      <!--audit request drop-down section for coalition/client name, LOB1 and LOB2-->
      <div class="audit-info-content client-padding pt-15 pb-24" *ngIf="auditRequestMode !== AUDIT_INFO.modes.review">
        <mat-form-field class="audit-info-field client-name-input"
                        [ngClass]="auditRequestMode === 'read' ? 'pds-read-only-field' : ''">
          <mat-label>{{auditInfoForm.get('coalition')!.value == 'true' ? AUDIT_INFO.label.coalitionNameLabel : AUDIT_INFO.label.clientNameLabel}}</mat-label>
          <input matInput formControlName="originalClientName"
                 [readonly]="auditInfoForm.get('auditInitiationType')!.value === AUDIT_INFO.auditInitiationType.client">
          <mat-error
            *ngIf="(showError || auditInfoForm.get('originalClientName')?.touched) && auditInfoForm.get('originalClientName')?.invalid">
            <mat-icon svgIcon="error-f--xs" aria-hidden="false" class="custom-error-icon"></mat-icon>
            {{auditInfoForm.get('coalition')!.value == 'true' && auditInfoForm.get('auditInitiationType')!.value === AUDIT_INFO.auditInitiationType.firm ? showFieldErrors(auditInfoForm.get('originalClientName'), AUDIT_INFO.formValidationMessages.originalCoalitionName) : showFieldErrors(auditInfoForm.get('originalClientName'), AUDIT_INFO.formValidationMessages.originalClientName)}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="pt-15 pb-10" *ngIf="auditRequestMode === AUDIT_INFO.modes.review">
        <p>Please select the correct coalition name</p>
        <div class="info-review">
          <div class="review-original-client-name ">
            <label>Auditor Requested Client(s)</label>
            <p class="mtb-12">{{auditInfoForm?.get('originalClientName')?.value}}</p>
          </div>
          <div class="review-dropdown audit-info-field">
            <pds-autocomplete-select
              [label]="labelName"
              (input)="setLoading()"
              formControlName="reviewDropDown" displayNameKey="altSearchParam"
              [value]="auditInfoForm?.get('reviewDropDown')?.value"
              (onClosed)="resetDropDown()"
              (onSelection)="onCarrierSelection($event)"
              [errorMsgs]="{required: labelName+' is required',valueNotInList: chsError}"
              [dropDownOptions]="reviewDropDownList"
              [inputAppearance]="inputAppearance"></pds-autocomplete-select>
          </div>
          <div class="w-28" *ngIf="labelName === AUDIT_INFO.label.platformID">
            <label id="super-client-detail-label">Super Client Detail</label>
            <ng-container *ngIf="!isCarrierIdSelected">
              <input matInput readonly [value]="isLoading" class="pt-5" aria-labelledby="super-client-detail-label">
            </ng-container>
            <ng-container *ngIf="isCarrierIdSelected">
              <input matInput readonly [value]="mockSuperClientName" class="pt-5" aria-labelledby="super-client-detail-label">
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="auditInfoForm?.get('firmId')?.value === null" class="page-divider"></div>
      <div class="audit-info-content client-padding pt-10">
        <div class="audit-info-field">
          <pds-autocomplete-select
            [label]="'Line of Business 1'" formControlName="auditLobOneId" displayNameKey="altSearchParam"
            [value]="auditInfoForm!.get('auditLobOneId')!.value"
            [readOnly]="auditRequestMode === 'read'"
            [errorMsgs]="{required: AUDIT_INFO.formValidationMessages.auditLobOneId.required,valueNotInList: AUDIT_INFO.formValidationMessages.auditLobOneId.valueNotInList}"
            [dropDownOptions]="auditLobOneList" [inputAppearance]="inputAppearance">
          </pds-autocomplete-select>
        </div>
        <div class="audit-info-field">
          <pds-autocomplete-multiselect [label]="'Line of Business 2'" [appearance]="inputAppearance"
                                        formControlName='auditLobTwoList'
                                        [readOnly]="auditRequestMode === 'read'"
                                        [customErrorMsg]="AUDIT_INFO.formValidationMessages.auditLobTwoList.required"
                                        [listOfOptions]="auditLobTwoList" class="field-width">
          </pds-autocomplete-multiselect>
        </div>
      </div>
      <div class="pt-24 pageHint">
        <div class="hint-text">{{AUDIT_INFO.auditInfoHint.scopeDateHint}}</div>
      </div>
      <!--date picker for audit scope start/end date-->
      <div class="audit-info-content field-padding">
        <mat-form-field class="audit-info-field" (click)="auditStartDatepicker.open()"
                        (keydown.space)="$event.stopPropagation()"
                        [ngClass]="auditRequestMode === 'read' ? 'pds-read-only-field' : ''">
          <mat-label>Audit Scope Start Date (MM/DD/YY)</mat-label>
          <input matInput [matDatepicker]="auditStartDatepicker" formControlName="auditScopeStartDate"
                 (input)="onInput($event, auditInfoForm.get('auditScopeStartDate'))"
                 [max]="maxAuditStartDate">
          <mat-icon class="icon" svgIcon="calendar--s" matSuffix tabindex="0" role="button" aria-hidden="false"
                    aria-label="Open Date Picker" (click)="auditStartDatepicker.open()"
                    *ngIf="auditRequestMode !== 'read'"
                    (keydown.enter)="auditStartDatepicker.open()"></mat-icon>
          <mat-error
            *ngIf="(showError || auditInfoForm.get('auditScopeStartDate')?.touched) && auditInfoForm.get('auditScopeStartDate')?.invalid">
            <mat-icon svgIcon="error-f--xs" aria-hidden="false" class="custom-error-icon"></mat-icon>
            {{showFieldErrors(auditInfoForm.get('auditScopeStartDate'), AUDIT_INFO.formValidationMessages.auditScopeStartDate)}}
          </mat-error>
          <mat-datepicker #auditStartDatepicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="audit-info-field audit-end-date" (click)="auditEndDatePicker.open()"
                        (keydown.space)="$event.stopPropagation()"
                        [ngClass]="auditRequestMode === 'read' ? 'pds-read-only-field' : ''">
          <mat-label>Audit Scope End Date (MM/DD/YY)</mat-label>
          <input matInput [matDatepicker]="auditEndDatePicker" formControlName="auditScopeEndDate"
                 (input)="onInput($event,auditInfoForm.get('auditScopeEndDate'))"
                 [max]="maxAuditEndDate">
          <mat-icon class="icon" svgIcon="calendar--s" matSuffix tabindex="0" role="button" aria-hidden="false"
                    aria-label="Open Date Picker" (click)="auditEndDatePicker.open()"
                    *ngIf="auditRequestMode !== 'read'"
                    (keydown.enter)="auditEndDatePicker.open()"></mat-icon>
          <mat-error
            *ngIf="(showError || auditInfoForm.get('auditScopeEndDate')?.touched) && auditInfoForm.get('auditScopeEndDate')?.invalid">
            <mat-icon svgIcon="error-f--xs" aria-hidden="false" class="custom-error-icon"></mat-icon>
            {{showFieldErrors(auditInfoForm.get('auditScopeEndDate'), AUDIT_INFO.formValidationMessages.auditScopeEndDate)}}
          </mat-error>
          <mat-datepicker #auditEndDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="btn-field" [hidden]="auditRequestMode === AUDIT_INFO.modes.read">
        <button type="button" class="pds-btn-ghost" (click)="cancelAuditInfo()">Cancel</button>
        <button type="submit" class="pds-btn-primary" (keydown.enter)="saveOrUpdateAuditRequest()">Save</button>
      </div>
    </div>
  </form>
</div>

<ng-template #customSaveBannerTemplate>
  <cvs-banner [data]="bannerData">
    <h2>{{AUDIT_INFO.successMessages.successTitle}}</h2>
    <div class="pt-15">
      <p>{{AUDIT_INFO.successMessages.add}}</p>
      <p *ngIf="auditInfoForm?.get('createdForPrimaryFirm')?.value === 'true' &&
      (auditRequestMode === AUDIT_INFO.modes.request || auditRequestMode === AUDIT_INFO.modes.edit)">
        {{AUDIT_INFO.successMessages.subcontractorAuditNote}}</p>
    </div>
  </cvs-banner>
</ng-template>

<ng-template #projectedTimelineWarningTemplate>
  <div *ngIf="showLoader" class="timeline-warning-container">
    <pds-spinner [loadingText]="(auditRequestMode === AUDIT_INFO.modes.edit) ? 'Changing timelines...' : 'Saving Audit Information...'" [mode]="'indeterminate'"></pds-spinner>
  </div>
  <div *ngIf="!showLoader" class="timeline-warning-container">
    <div class="template-title">
      <h2>Warning</h2>
      <button class="pds-icon-only-btn center-close-x" mat-icon-button mat-dialog-close
              aria-label="Close Warning dialog" id="timeline-warning-close">
        <mat-icon svgIcon="close-btn--s" aria-labelledby="timeline-warning-close"></mat-icon>
      </button>
    </div>
    <div class="timeline-warning-body" *ngIf="auditRequestMode === AUDIT_INFO.modes.edit">
      You have entered one or more values in the projected timeline and have altered the scope of the Audit.
      You will need to re-enter your projected timelines as the audit scope change could have affected the turn around time of the audit.
    </div>
    <div class="timeline-warning-body" *ngIf="auditRequestMode === AUDIT_INFO.modes.review">
      You have altered the scope of the audit or have adjusted the LOB2 values since the audit was submitted by the auditor.
      Please be sure to make any needed adjustments to the projected timelines if your changes increase the turn around times on the audit.
    </div>
    <div class="content-align-right">
      <button class="pds-btn-ghost" (click)="cancelTimelineWarning()">Cancel</button>
      <button class="pds-btn-primary" (click)="(auditRequestMode === AUDIT_INFO.modes.edit) ?
      changeTimelineDurations(auditTimelineForm.value.tableRows) :
      closeModalAndSaveInfo()
      ">Ok</button>
    </div>
  </div>
</ng-template>

export enum TIMELINE_TITLE {
    EXTERNAL_KICK_OFF_DATE = 'Timeline External kick Off Date',
    INITIAL_DELIVERABLE_DUE_DATE = 'Timeline Initial Deliverable Due Date',
    GER_REPORTING_DUE_DATE = 'Timeline GER Reporting Due Date',
    FU_QUESTIONS_DUE_DATE = 'Timeline Follow Up Questions Due Date',
    FU_QUESTIONS_RESPONSE_DUE_DATE = 'Timeline Follow Up Questions Response Due Date',
    ONSITE_START_DATE = 'Timeline Onsite Start Date',
    SAMPLE_RECEIVED_DUE_DATE = 'Timeline Sample Received Due Date',
    SAMPLE_RESPONSE_DUE_DATE = 'Timeline Sample Response Due Date',
    ALL_SAMPLE_FU_RESOLVED_DUE_DATE = 'Timeline All Sample Follow Up Resolved Due Date',
    DRAFT_REPORT_RECEIVED_DUE_DATE = 'Timeline Draft Report Received Due Date',
    DRAFT_REPORT_RESPONSE_DUE_DATE = 'Timeline Draft Report Response Due Date',
    FINAL_REPORT_RECEIVED_DUE_DATE = 'Timeline Final Report Received Due Date',
    FINAL_REPORT_RESPONSE_DUE_DATE = 'Timeline Final Report Response Due Date',
    EXPECTED_AUDIT_COMPLETION_DATE = 'Timeline Expected Audit Completion Date',
  }

<div class="pds-table pds-table--tall" [class.thick-border]="checkThickBorder(header)">
  <div class="pds-table__header section-content">
    <h3 *ngIf="this.claimAuditInfoSection" style="font-weight: 400;"
        class="bold-header">{{header}}</h3>
    <h2 *ngIf="!this.claimAuditInfoSection" style="font-weight: 400;"
        class="bold-header">{{header}}</h2>
    <div class="notes-icon ml-10" *ngIf="!this.claimAuditInfoSection">
        <span>
          <button id="notes-action-btn" class="pds-btn-text-only" (click)="callAddViewNotes()" aria-label="View/Add notes button">
            <mat-icon [svgIcon]="sectionNotesExists ? 'file-check--s' : 'pencil--s'" class="envelope-icon" aria-hidden='false' aria-labelledby="notes-action-btn"></mat-icon>
            {{sectionNotesExists ? "View/" : ""}}Add Notes
          </button>
        </span>
    </div>
  </div>
  <div *ngIf="auditRequestMode !== 'read'"
       class="upload-file-row upload-file-row-wide-gap">
    <span>Select a file to begin.</span>
    <div *ngIf="!REUSABLE_FILE.includes(header) || (REUSABLE_FILE.includes(header) && !userService.isAuditAdmin())">
      <button
              (click)="uploadFile()"
              class="pds-btn-primary button-search"
              [disabled]="auditRequestMode === 'read'"
              [attr.aria-label]="'Upload file for ' + header"
      >
        <mat-icon class="upload-button-icon" svgIcon="upload-arrow--s"></mat-icon>
        Upload
      </button>
      <input id="{{inputIdString}}"
             style="display: none"
             type="file" accept=".pdf, .doc, .xls, .docx, .xlsx, .txt, .csv, .zip, .pptx, .eml, .oft, .xlsb, .mdb, .accdb, .html, .tif"
             (change)="onFileSelected($event)">
    </div>
    <div *ngIf="REUSABLE_FILE.includes(header) && userService.isAuditAdmin()">
      <button
              (click)="openReusableFileModal()"
              class="pds-btn-primary button-search"
              [disabled]="auditRequestMode === 'read'"
              [attr.aria-label]="'Upload file for ' + header"
      >
        <mat-icon class="upload-button-icon" svgIcon="upload-arrow--s"></mat-icon>
        Upload
      </button>
    </div>
  </div>
  <mat-table [dataSource]="dataSource" matSort>
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; let i = index; columns: columns;"></mat-row>

    <!-- File Name Column -->
    <ng-container matColumnDef="fileName">
      <mat-header-cell
        *matHeaderCellDef
        [style.fontWeight]="'300'">
        File name
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a (click)="downloadFile(element)" (keydown.enter)="downloadFile(element)" tabindex="0">{{element.fileName}}</a>
      </mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <mat-header-cell
        *matHeaderCellDef
        [style.fontWeight]="'300'">
        Status
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.fileStatus}}</mat-cell>
    </ng-container>

    <!-- Share With Column -->
    <ng-container matColumnDef="shareWith">
      <mat-header-cell
        *matHeaderCellDef
        [style.fontWeight]="'300'">
        Shared With
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element">
        <div
          [tabindex]="0"
          [cvsTooltip]="setTooltipText(element.detailFileId)"
          [showArrow]="true"
          [placement]="'bottom'"
          class="share-with-cell"
          #shareWithCell>
          <mat-icon svgIcon="user--s"></mat-icon>
          {{setMemberAmount(element.detailFileId)}}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Upload date Column -->
    <ng-container matColumnDef="uploadDate">
      <mat-header-cell
        *matHeaderCellDef
        [style.fontWeight]="'300'">
        Upload date
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.createdDatetime | appDateFormat}}</mat-cell>
    </ng-container>

    <!-- Uploaded by Column -->
    <ng-container matColumnDef="uploadedBy">
      <mat-header-cell
        *matHeaderCellDef
        [style.fontWeight]="'300'">
        Upload by
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.createdBy}}</mat-cell>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <mat-header-cell
        *matHeaderCellDef
        [style.fontWeight]="'300'">
        Actions
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="(element.fileStatus !== 'File Not Selected' &&
             (userService.getUserEmail() === element.createdBy || userService.isAuditAdmin() || userService.isAuditAnalyst()))">
          <div *ngIf="!claimAuditInfoSection && (userService.isAuditAdmin() || userService.isAuditAnalyst())">
            <button type="button" class="pds-btn-text-only"
                    (click)="openShareFileModal(shareWithTemplateRef, element)">
              <mat-icon svgIcon="user-add--s"></mat-icon>
              Share With
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="element.fileStatus !== 'File Not Selected' &&
          (userService.getUserEmail() === element.createdBy || userService.isAuditAdmin() || userService.isAuditAnalyst())">
          <button mat-icon-button [matMenuTriggerFor]="more" [cvsTooltip]="'More'" [showArrow]="true" [smallTooltip]="true" [placement]="'top'" >
            <mat-icon svgIcon="more-horz-f--s" aria-hidden="false" aria-label="more actions"></mat-icon>

            <mat-menu #more="matMenu">
              <button mat-menu-item class="table-action-button"
                      *ngIf="userService.isAuditAnalyst() && isShowApproveFile">Approve</button>
              <button mat-menu-item id="delete-menu-button" class="table-action-button"
                      (click)="deleteFile(element)">Delete</button>
              <button mat-menu-item class="table-action-button"
                      *ngIf="userService.isAuditAnalyst() && header === 'Non-disclosure Agreement'">Fully Executed</button>
            </mat-menu>
          </button>
        </ng-container>
      </mat-cell>
    </ng-container>
  </mat-table>
</div>

<ng-template #shareWithTemplateRef>
  <div [ngClass]="showLoader ? 'loader' : 'share-with-modal'">
    <div *ngIf="showLoader">
      <pds-spinner [loadingText]="'Loading Data...'" [mode]="'indeterminate'"></pds-spinner>
    </div>
    <div *ngIf="!showLoader">
      <div class="share-with-title">
        <h2>Share File</h2>
        <button class="content-align-right" mat-icon-button mat-dialog-close (click)="cancelAction()"
                [aria-label]="'Close Share File dialog'" id="share-file-close">
          <mat-icon svgIcon="close-btn--s" aria-hidden="false" aria-labelledby="share-file-close"></mat-icon>
        </button>
      </div>
      <div class="share-with-content">
        <span class="share-with-text">Please select from the list of contacts for this audit to share this file with.</span>
        <form [formGroup]="shareWithForm">
          <mat-form-field appearance="outline" class="share-with-dropdown cvs-compact-form-field">
            <mat-select [formControl]="selectedOptions"
                        (selectionChange)="changedSelection($event)"
                        multiple>
              <mat-option *ngFor="let contact of firmContactDropdownList; let i = index;"
                          [disabled]="contact.value.contactEmail === createdBy ||
                          (contact.firm === true && disableFirmNameSelector(i)) ||
                          checkNdaVerification(contact)"
                          [value]="i"
                          [ngClass]="contact.firm ? '' : 'indent-selection'">
                {{contact.firm ? contact.value : contact.value.firstName + ' ' + contact.value.lastName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="share-with-buttons">
        <button class="pds-btn-white" type="button" (click)="cancelAction()">Cancel</button>
        <button class="pds-btn-primary" type="button" [disabled]="selectedOptions.value.length === 0" (click)="shareFile()">Share</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #reusableFileTemplateRef>
  <div [ngClass]="showReusableFileLoader ? 'loader' : 'reusable-file-modal'">
    <div *ngIf="showReusableFileLoader">
      <pds-spinner [loadingText]="'Uploading File...'" [mode]="'indeterminate'"></pds-spinner>
    </div>
    <div *ngIf="!showReusableFileLoader">
      <div class="contact-title">
        <h2>Upload Reusable File</h2>
        <button class="content-align-right title-icon" mat-icon-button mat-dialog-close (click)="closeModal()" (keydown.enter)="closeModal()"
                [aria-label]="'Close Upload Reusable File Modal'" id="upload-reusable-close">
          <mat-icon svgIcon="close-btn--s" aria-hidden="false" aria-labelledby="upload-reusable-close"></mat-icon>
        </button>
      </div>
      <h3 class="mb-16">{{header}}</h3>
      <div class="mb-16 reusable-file-modal-body">
        By selecting effective and end dates,
        <span *ngIf="isLDDList()">LDD List,</span>
        and selecting continue,
        this file will be flagged as a reusable document for multiple audits.
      </div>
      <form [formGroup]="reusableFileForm" class="mb-24">
        <div class="mb-16">
          <p class="mb-16 reusable-file-modal-body">If you do not want to make this file reusable, please check “Bypass Reusable File” and select continue.</p>
          <mat-checkbox [attr.aria-checked]="reusableFileForm?.get('isBypassFile')?.value" (change)="bypassFileChange($event)"
                        [attr.aria-label]="'Bypass Reusable File'" formControlName="isBypassFile">Bypass Reusable File</mat-checkbox>
        </div>
        <div class="field-padding mb-16" *ngIf="isEffectiveDates">
          <mat-form-field class="file-date-field" (click)="effectiveStartDatepicker.open()"
                          (keydown.space)="$event.stopPropagation()">
            <mat-label>Effective Date (MM/DD/YYYY)</mat-label>
            <input matInput [matDatepicker]="effectiveStartDatepicker" formControlName="effectiveStartDate" [max]="'9999-12-31'">
            <mat-icon class="icon" svgIcon="calendar--s" matSuffix tabindex="0" aria-hidden="false"
                      aria-label="Open Date Picker" (click)="effectiveStartDatepicker.open()"
                      (keydown.enter)="effectiveStartDatepicker.open()"></mat-icon>
            <mat-error *ngIf="(reusableFileForm.get('effectiveStartDate')?.touched) && reusableFileForm.get('effectiveStartDate')?.invalid">
              <mat-icon svgIcon="error-f--xs" aria-hidden="false" class="custom-error-icon"></mat-icon>
              {{showFieldErrors(reusableFileForm.get('effectiveStartDate'), DOC_MANAGEMENT.formValidationMessages.effectiveStartDate)}}
            </mat-error>
            <mat-datepicker #effectiveStartDatepicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="file-date-field end-date-padding" (click)="effectiveEndDatePicker.open()"
                          (keydown.space)="$event.stopPropagation()">
            <mat-label>End Date (MM/DD/YYYY)</mat-label>
            <input matInput [matDatepicker]="effectiveEndDatePicker" formControlName="effectiveEndDate" [max]="'9999-12-31'">
            <mat-icon class="icon" svgIcon="calendar--s" matSuffix tabindex="0" aria-hidden="false"
                      aria-label="Open Date Picker" (click)="effectiveEndDatePicker.open()"
                      (keydown.enter)="effectiveEndDatePicker.open()"></mat-icon>
            <mat-error *ngIf="reusableFileForm.get('effectiveEndDate')?.touched && reusableFileForm.get('effectiveEndDate')?.invalid">
              <mat-icon svgIcon="error-f--xs" aria-hidden="false" class="custom-error-icon"></mat-icon>
              {{showFieldErrors(reusableFileForm.get('effectiveEndDate'), DOC_MANAGEMENT.formValidationMessages.effectiveEndDate)}}
            </mat-error>
            <mat-datepicker #effectiveEndDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <ng-container *ngIf="this.header === INFO_REQUEST.PREVENTATIVE_DRUG_LIST">
          <div class="field-padding">
            <mat-form-field class="file-date-field" (click)="asOfDatepicker.open()"
                            (keydown.space)="$event.stopPropagation()">
              <mat-label>As of Date (MM/DD/YYYY)</mat-label>
              <input matInput [matDatepicker]="asOfDatepicker" formControlName="effectiveStartDate" [max]="'9999-12-31'">
              <mat-icon class="icon" svgIcon="calendar--s" matSuffix tabindex="0" aria-hidden="false"
                        aria-label="Open Date Picker" (click)="asOfDatepicker.open()"
                        (keydown.enter)="asOfDatepicker.open()"></mat-icon>
              <mat-error *ngIf="(reusableFileForm.get('effectiveStartDate')?.touched) && reusableFileForm.get('effectiveStartDate')?.invalid">
                <mat-icon svgIcon="error-f--xs" aria-hidden="false" class="custom-error-icon"></mat-icon>
                {{showFieldErrors(reusableFileForm.get('effectiveStartDate'), DOC_MANAGEMENT.formValidationMessages.asOfDate)}}
              </mat-error>
              <mat-datepicker #asOfDatepicker></mat-datepicker>
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngIf="header === INFO_REQUEST.SOC_1_REPORT">
          <div>
            <div class="label-gray">Report Type</div>
            <mat-radio-group aria-label="Report Type" formControlName="reportType">
              <mat-radio-button value="SOC 1 Report" tabindex="0">SOC 1 Report</mat-radio-button>
              <mat-radio-button value="Bridge Letter" tabindex="0">Bridge Letter</mat-radio-button>
            </mat-radio-group>
          </div>
        </ng-container>
        <div *ngIf="this.header === INFO_REQUEST.LIMITED_DISTRIBUTION_LIST">
          <div class="label-gray">Please select LDD list</div>
          <mat-radio-group aria-label="Please select LDD list" name="lddSubCategory" formControlName="lddSubCategory" ngDefaultControl >
            <mat-radio-button [value]="LDDSubCategory.SPECIALTY_ONLY" tabindex="0" checked="false">{{LDDSubCategory.SPECIALTY_ONLY}}</mat-radio-button>
            <mat-radio-button [value]="LDDSubCategory.NON_SPECIALTY_INC" tabindex="0" checked="false" >{{LDDSubCategory.NON_SPECIALTY_INC}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </form>
      <div class="content-align-right">
        <button class="pds-btn-ghost" (click)="closeModal()" (keydown.enter)="closeModal()">Cancel</button>
        <ng-container *ngIf="reusableFileForm?.statusChanges | async as status">
          <button class="pds-btn-primary" (click)="uploadFile()"
                  [disabled]="status === 'INVALID'">Continue</button>
          <input id="{{inputIdString}}"
                 style="display: none"
                 type="file" accept=".pdf, .doc, .xls, .docx, .xlsx, .txt, .csv, .zip, .pptx, .eml, .oft, .xlsb, .mdb, .accdb, .html, .tif"
                 (change)="onFileSelected($event)">
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>


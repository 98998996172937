import {Component, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SharedModule} from '../shared/shared.module';
import {DocumentService} from '../services/document.service';
import {DocumentRequest} from '../models/document-request-model';
import {ScreenType} from '../enums/screen-type-enum';
import {DocumentMetadataResponse} from '../models/document-metadata-response.model';
import {UserService} from '../services/user.service';
import {MatIconModule} from '@angular/material/icon';
import {AuditContactService} from '../audit-request/audit-contact/audit-contact.service';
import {AuditInformationService} from '../audit-request/audit-information/audit-information.service';
import {FirmModel} from '../models/firm.model';
import {AuditContactDetailModel, AuditContactModel} from '../audit-request/audit-contact/audit-contact.model';
import {FileShareViewObject} from '../models/file-share-model';
import {AuditRequest} from '../audit-request/audit-request.model';
import {AuditRequestUtil} from '../utilities/audit-request.util';
import * as AUDIT_INFO from '../audit-request/audit-information/audit-information.constants';
import{NotesManagementComponent} from '../shared/components/notes-management/notes-management.component';
import {MatDialog} from '@angular/material/dialog';
import {
  InitialDeliverablesType,
  NotesCategory,
  NotesType
} from '../shared/components/notes-management/notes-management.constants';
import {NotesManagementModel} from '../shared/components/notes-management/notes-management.model';
import * as _ from 'underscore';
import {forkJoin, Observable} from 'rxjs';
import {NotificationHistoryRequestModel} from '../models/notification-history-request.model';
import {NotificationService} from '../services/notification.service';
import {TimeLineService} from '../audit-request/time-line/time-line.service';

@Component({
  standalone: true,
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  imports: [CommonModule, MatCheckboxModule, SharedModule, MatIconModule],
  selector: 'app-document-management',
  templateUrl: './document-management.component.html',
  styleUrls: ['./document-management.component.scss']
})
export class DocumentManagementComponent implements OnInit {

  recordId: string;
  auditId: number;
  ndaMetadata: any[] = [];
  claimAuditMetadata: any[] = [];
  generalDocMetaData: any[] = [];
  firmList: FirmModel[];
  firmContactDropdownList = [];
  fileSharesList: FileShareViewObject[];
  auditDetail: AuditRequest;
  ndaVerificationMap: any;
  claimDataNotesExists = false;
  headline: string;
  auditContacts$!: Observable<any>;
  auditTimelines$!: Observable<any>;

  constructor(private route: ActivatedRoute,
              private documentService: DocumentService,
              private userService: UserService,
              public matDialog: MatDialog,
              public auditInfoService: AuditInformationService,
              public auditContactService: AuditContactService,
              private notificationService: NotificationService,
              private timelineService: TimeLineService) {
  }

  ngOnInit(): void {
      this.route.params.subscribe(params => {
        this.recordId = params['auditRecordId'];
        this.auditId = params['auditId'];
        this.fetchFirmAndContacts();
        this.getAuditRequestDetails();
        this.fetchMetadata();
        this.fetchFileShareView();
        this.fetchNDAVerification();
      });
  }

  fetchFirmAndContacts() {
    this.auditContactService.getFirmList().subscribe(firms => {
      this.firmList = firms;
      this.auditContactService.getAuditContactDetail(this.auditId).subscribe((contacts: AuditContactDetailModel) => {
        this.setupContactsDropdown(contacts);

      });
    });
  }

  /**
   * Converts the AuditContactDetailModel Object into and array of objects:
   * {
   *   firm: boolean; flag to know if value is a Firm Name,
   *   value: string | AuditContactModel; Firm Name or data for contact
   * }
   * Note: They are put in an order so firm object will be followed by the contacts
   * associated with the firm.
   *
   * @param auditContactDetailModel - response from getAuditContactDetail service call
   */
  setupContactsDropdown(auditContactDetailModel: AuditContactDetailModel) {
    const firmContactList = [];
    if(auditContactDetailModel.primaryAuditContact.length > 0 && auditContactDetailModel.firmId !== null) {
      this.addToFirmContactList(auditContactDetailModel.primaryAuditContact, firmContactList);
    }
    if(auditContactDetailModel.primaryAuditContact.length > 0 && auditContactDetailModel.firmId === null) {
      const clientMap = new Map<string, AuditContactModel[]>();
      const sortedList = auditContactDetailModel.primaryAuditContact.sort((a, b) => a.firstName.localeCompare(b.firstName));
      clientMap.set(auditContactDetailModel.clientName, sortedList);
      firmContactList.push(clientMap);
    }
    if(auditContactDetailModel.auditSubcontractorOne.length > 0) {
      this.addToFirmContactList(auditContactDetailModel.auditSubcontractorOne, firmContactList);
    }
    if(auditContactDetailModel.auditSubcontractorTwo.length > 0) {
      this.addToFirmContactList(auditContactDetailModel.auditSubcontractorTwo, firmContactList);
    }

    const sortedFirmContactList = firmContactList.sort((a, b) => {
      const aKey = Array.from(a.keys())[0];
      const bKey = Array.from(b.keys())[0];
      return aKey < bKey ? -1 : aKey > bKey ? 1 : 0;
    });

    for(let i = 0; i < sortedFirmContactList.length; i++) {
      const mapKey = Array.from(sortedFirmContactList[i].keys())[0];
      this.firmContactDropdownList.push({firm: true, value: mapKey});
      sortedFirmContactList[i].forEach((value: AuditContactModel[]) => {
        value.forEach(contact => {
          this.firmContactDropdownList.push({firm: false, value: contact});
        });
      });
    }
  }

  /**
   * Adds firmMap <String, AuditContactModel[]> to firmContactList.
   * Note: the string key is the firmName of the contacts in the list.
   * We sort the audit contacts in the map by firstName.
   *
   * @param auditContactList - List of contacts in the AuditContactDetailModel lists
   * @param firmContactList - List<Map<String, AuditContactModel[]>> object list
   */
  addToFirmContactList(auditContactList: AuditContactModel[], firmContactList: any[]) {
    const firmMap = new Map<string, AuditContactModel[]>();
    const sortedList = auditContactList.sort((a, b) => a.firstName.localeCompare(b.firstName));
    const firm = this.firmList.filter((f: any) => f.firmId === auditContactList[0].firmId)[0];
    firmMap.set(firm.firmName, sortedList);
    firmContactList.push(firmMap);
  }

  fetchMetadata() {
    const documentRequest: DocumentRequest = {
      screenType: ScreenType.DOCUMENT_MANAGEMENT,
      amsAuditRecordId: this.recordId,
      isInternalUser: this.userService.isInternalUser(),
      emailAddress: this.userService.getUserEmail()
    };
    this.documentService.getDocumentMetadataForAudit(documentRequest).subscribe(response => {
      this.claimAuditMetadata = this.createMetadataList(response.filter(
        category =>
          category.infoRequestId !== null ||
          category.detailAddlInfoRequestId !== null
      ));
      this.generalDocMetaData = this.createMetadataList(response.filter(
        category =>
          category.generalDocId !== null
      ));
      this.ndaMetadata = this.createNdaMetadataList(response.filter(
        category =>
          category.detailAuditorGroupId !== null
      ));
      this.claimDataNotesExists = !!this.claimAuditMetadata?.filter( data => data?.sectionNotesExists)?.length;
    });
  }

  /**
   * Function to get the AuditFileSharesView from the document service and set it in the
   * fileSharesList component variable.
   */
  public fetchFileShareView() {
    this.documentService.getFileSharesByAuditId(this.auditId).subscribe((res) => {
      this.fileSharesList = res;
    });
  }

  private createMetadataList(response: DocumentMetadataResponse[]) {
    const metadataList = [];
    response.forEach(category => {
      const auditCategoryMetadata = {
        fileGroup: []
      };
      auditCategoryMetadata['header'] = category.fileGroupTitle;
      auditCategoryMetadata['infoRequestId'] = category.infoRequestId;
      auditCategoryMetadata['detailAddlInfoRequestId'] = category.detailAddlInfoRequestId;
      auditCategoryMetadata['generalDocId'] = category.generalDocId;
      auditCategoryMetadata['sectionNotesExists'] = category.sectionNotesExists;

      if (category.fileDmsIds && category.fileDmsIds[0]) {
        category.fileDmsIds.forEach((id, index) => {
          auditCategoryMetadata.fileGroup.push({
            fileName: category.fileNames[index],
            detailFileId: category.fileIds[index],
            fileDmsId: category.fileDmsIds[index],
            detailId: category.auditId,
            fileStatus: category.statuses[index],
            createdDatetime: category.uploadedTimes[index],
            createdBy: category.uploadedBys[index],
          });
        });
      }

      metadataList.push(auditCategoryMetadata);
    });

    return metadataList;
  }

  private createNdaMetadataList(response: DocumentMetadataResponse[]) {
    const metadataList = [];
    if(response.length > 0) {
      const auditCategoryMetadata = {
        fileGroup: []
      };

      auditCategoryMetadata['header'] = response[0].fileGroupTitle;
      auditCategoryMetadata['detailAuditorGroupId'] = response[0].detailAuditorGroupId;
      auditCategoryMetadata['sectionNotesExists'] = response[0].sectionNotesExists;

      response.forEach(category => {
        if (category.fileDmsIds && category.fileDmsIds[0]) {
          category.fileDmsIds.forEach((id, index) => {
            auditCategoryMetadata.fileGroup.push({
              fileName: category.fileNames[index],
              detailFileId: category.fileIds[index],
              detailId: category.auditId,
              fileDmsId: category.fileDmsIds[index],
              fileStatus: category.statuses[index],
              createdDatetime: category.uploadedTimes[index],
              createdBy: category.uploadedBys[index],
            });
          });
        }
      });
      metadataList.push(auditCategoryMetadata);
    }

    return metadataList;
  }

  getAuditRequestDetails() {
    if(this.recordId) {
      this.auditInfoService.getAuditRequest(this.recordId).subscribe({
        next: (auditRequest: AuditRequest) => {
          this.auditDetail = auditRequest;
        },
        error: (err: any) => {
          if (err?.error?.code === '403') {
            this.auditInfoService.backClicked();
          }
        }
      });
    }
  }

  /**
   * Sets the ndaVerificationMap variable using the ndaVerificationList from documentService.
   * The ndaVerificationMap has keys that can be either string or numbers, but the value is always boolean.
   */
  fetchNDAVerification() {
    if(this.auditId) {
      this.documentService.getNdaVerificationList(this.auditId).subscribe(ndaVerificationList => {
        const ndaMap = new Map<any, boolean>();
        ndaVerificationList.forEach(nda => {
          if(!ndaMap.has(nda.firmName)) {
            ndaMap.set(nda.firmName, nda.selectedNdaOptionId === 1);
            ndaMap.set(Number(nda.firmId), nda.selectedNdaOptionId === 1);
          }
        });
        this.ndaVerificationMap = ndaMap;
      });
    }
  }

  get clientCoalitionLabel() {
    return AuditRequestUtil.getClientOrCoalitionLabel(this.auditDetail?.auditInformation?.coalition);
  }

  get clientOrAccountName() {
    return AuditRequestUtil.getClientOrAccountName(this.auditDetail);
  }

  addViewNotesModal(event: string, documentManagementData?: any){
    const documentCategory = this.getDocumentCategory(event);
    const isInitialDeliverables = documentCategory === NotesCategory.INITIAL_DELIVERABLES;
    let dropDownValue: any[] = [];
    const documentData: NotesManagementModel = {
      auditId: this.auditDetail?.auditId ?? null,
      notesType: NotesType.DMS,
      title: event,
      tagDropdownList: dropDownValue,
      modalType: 'add',
      category: documentCategory,
      generalDocId: !isInitialDeliverables ? documentManagementData?.generalDocId : null,
      detailAuditorGroupId: !isInitialDeliverables ? documentManagementData?.detailAuditorGroupId : null,
    };
    if(isInitialDeliverables) {
      dropDownValue = documentManagementData?.map( (option: any) => {
        return {
          id: option.infoRequestId ?? option.detailAddlInfoRequestId,
          value: option.header,
          type: option.infoRequestId ? InitialDeliverablesType.INFO_REQUEST : InitialDeliverablesType.ADDITIONAL_INFO_REQUEST,
        };
      });
      documentData['tagDropdownList'] = dropDownValue?.length ? _.sortBy(dropDownValue, 'value') : [];
    }

    this.matDialog.open(NotesManagementComponent,
      {
        disableClose: true,
        data: documentData
      }).afterClosed().subscribe((result: any) => {
      if(result?.isNotesExist) {
        this.setNotesExists(documentCategory);
      }
    });
  }

  getDocumentCategory(category: string) {
    switch (category) {
      case NotesCategory.NDA:
      case NotesCategory.SAMPLE:
      case NotesCategory.REPORT:
      case NotesCategory.AUDIT_CLOSURE:
      case NotesCategory.FOLLOWUP:  return category;
      default: return NotesCategory.INITIAL_DELIVERABLES;
    }
  }

  setNotesExists(category: string) {
    switch (category) {
      case NotesCategory.NDA: {
        if(this.ndaMetadata?.length) {
          this.ndaMetadata[0].sectionNotesExists = true;
        }
        break;
      }
      case NotesCategory.INITIAL_DELIVERABLES: {
        this.claimDataNotesExists = true;
        break;
      }
      case NotesCategory.SAMPLE:
      case NotesCategory.REPORT:
      case NotesCategory.AUDIT_CLOSURE:
      case NotesCategory.FOLLOWUP: {
        const findIndex = this.generalDocMetaData.findIndex(data => data?.header === category);
        if(findIndex !== -1) {
          this.generalDocMetaData[findIndex].sectionNotesExists = true;
        }
        break;
      }
      default: break;
    }
  }

  sendEmailNotification(isFileUploaded: any) {
    if(isFileUploaded?.isFileUploaded && this.userService.isExternalUser() && this.auditDetail?.auditInformation?.assignedAnalystEmail) {
      this.auditContacts$ = this.auditContactService.getAuditContactDetail(this.auditId);
      this.auditTimelines$ = this.timelineService.getAll(this.auditId);
      forkJoin(
        [this.auditContacts$, this.auditTimelines$]
      )
        .subscribe((results) => {
          const headerText = 'A Document has been uploaded in Audit Management';
          const emailBody = this.notificationService.createEmailBodyForFileUploadedByAuditor(
                                                  this.auditDetail, results[0], results[1], isFileUploaded);
          const notificationRequest = this.notificationService.createNotificationRequest(
            headerText, emailBody, [this.auditDetail?.auditInformation?.assignedAnalystEmail]);
          this.notificationService.sendNotification(notificationRequest).subscribe({
          next: () => {
            const notificationHistoryRequest: NotificationHistoryRequestModel = {
              notificationRequest,
              notificationEvent: 'Auditor Uploaded Document',
              recordId: this.recordId,
              notificationType: 'Real-time',
              currentUserEmail: sessionStorage.getItem('email')
            };
            this.notificationService.saveNotificationHistory(notificationHistoryRequest).subscribe( {
              next: (res) => {
                console.log('Notification sent/saved');
              },
              error: (notificationHistoryErr) => {
                console.error('Error saving notification history', notificationHistoryErr);
              }});
          }, error: (notificationErr) => {
            console.error('Error sending notification', notificationErr);
          }});
        });
    }
  }

    protected readonly AUDIT_INFO = AUDIT_INFO;
}

